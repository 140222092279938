import { AutoComplete, Card } from "antd";
import React, { useEffect, useState, useContext } from "react";
// import { withRouter } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UIButton } from "../../../../components/lib";
import "./support.scss";
// import Pic3 from "../../../../../../assets/Episilia Team.png";
// import Pic2 from "../../../../../../assets/Self Hosted.png";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import Footer from "../../../../authentication/views/common/Footer";
import AuthContext from "../../../../authentication/views/components/Auth";
import { connect } from "react-redux";
import AuthenticationActions from "../../../../authentication/redux/AuthenticationActions";
import DashboardActions from "../../../redux/dashboardActions";
import { getAuth } from "firebase/auth";
let auth = getAuth();
let theme = sessionStorage.getItem("theme");
const Support = ({
    getCredentials,
    getSubscriptionPlanList,
    getSubscribedPlans,
    history,
    authentication,
    getUsersList,
    getInvitedList,
    getCompanyInvitedList,
}) => {
    let navigate = useNavigate();
    const { token, emailId, userId } = useContext(AuthContext);
    const [loading, setloading] = useState(true);

    let header = {
        token,
        userId,
        emailId,
    };
    //getSubscriptionPlanList(header), getSubscribedPlans(header)
    useEffect(
        () => (
            getCredentials(header),
            getUsersList(header),
            getInvitedList(header),
            getCompanyInvitedList(header),
            setloading(!loading)
        ),
        [],
    );
    useEffect(() => (setloading(false), (theme = sessionStorage.getItem("theme"))));
    // useEffect(()=>((authentication.authenticated && authentication.registered && sessionStorage.getItem("logout")=="false") ?console.log(""):navigate(-1)),[])
    return (
        <div>
            {!loading && (
                <div
                    className="background-support"
                    style={{
                        backgroundColor: theme == "dark" ? "#282828" : "transparent",
                    }}
                >
                    <div
                        className="support-base-container"
                        style={{ color: theme == "dark" ? "#FFF" : "#000" }}
                    >
                        <Card
                            className="knowledge-base-form"
                            title="Have any query?"
                            headStyle={{ color: theme == "dark" ? "#FFF" : "#000" }}
                            style={{ backgroundColor: theme == "dark" ? "#282828" : "transparent" }}
                        >
                            <h4 style={{ color: theme == "dark" ? "#FFF" : "#000" }}>
                                Refer to our knowledge base
                            </h4>
                            <div>
                                {/*  */}
                                <UIButton className="text-doc" id="Gotobutton">
                                    <a href="https://docs.episilia.com/" target="_blank">
                                        Go to Knowledge base
                                    </a>
                                </UIButton>
                            </div>
                        </Card>

                        <Card
                            title="Contact us"
                            className="form-support-query"
                            headStyle={{ color: theme == "dark" ? "#FFF" : "#000" }}
                            style={{ backgroundColor: theme == "dark" ? "#282828" : "transparent" }}
                        >
                            {/* <img src={Pic3} className='pic3-support'/> */}

                            <p
                                style={{
                                    color: theme == "dark" ? "#FFF" : "#000",
                                    marginBottom: 2,
                                }}
                            >
                                <MailOutlined
                                    style={{
                                        marginRight: 10,
                                        color: theme == "dark" ? "#FFF" : "#000",
                                    }}
                                />
                                Email: &nbsp;&nbsp;&nbsp;support@episilia.com
                            </p>
                            <span style={{ color: theme == "dark" ? "#FFF" : "#000" }}>
                                {" "}
                                <PhoneOutlined
                                    className="phone-icon"
                                    style={{ color: theme == "dark" ? "#FFF" : "#000" }}
                                />
                                Phone:
                                <table className="table-contact-details">
                                    <tbody>
                                        <tr style={{ color: theme == "dark" ? "#FFF" : "#000" }}>
                                            8867574663 (Tech)
                                        </tr>
                                        <tr style={{ color: theme == "dark" ? "#FFF" : "#000" }}>
                                            9972629799 (Sales)
                                        </tr>
                                    </tbody>
                                </table>
                            </span>
                            {/* //8867574663(Tech) <br/><span style={{marginRight:20}}>7795560646 (Sales)</span>  */}
                        </Card>
                    </div>
                </div>
            )}
            <div style={{ marginTop: "72vh", position: "absolute", marginLeft:'40%' }}>
                <Footer />
            </div>
            {/* {loading && <div></div>}
            <div style={{ marginTop: '70vh',position:'absolute' }}>
                <Footer />
            </div> */}
        </div>
    );
};

const mapStateToProps = (state) => ({
    authentication: state.authentication,
    dashboard: state.dashboard,
});

const mapDispatchToProps = (dispatch) => ({
    getSubscriptionPlanList: (header) => {
        dispatch(DashboardActions.getSubscriptionPlanList(header));
    },
    getSubscribedPlans: (header) => {
        dispatch(DashboardActions.getSubscribedPlans(header));
    },
    getCredentials: (header) => {
        dispatch(AuthenticationActions.getCredentials(header));
    },
    getUsersList: (header) => {
        dispatch(DashboardActions.getUsersList(header));
    },

    getInvitedList: (header) => {
        dispatch(DashboardActions.getInvitedList(header));
    },
    getCompanyInvitedList: (header) => {
        dispatch(DashboardActions.getCompanyInvitedList(header));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Support);
