import { Alert, Box, Button, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AlertTargets, { CreateAlertTargetDialog } from "./AlertTargets";
import { useDispatch, useSelector } from "react-redux";
import { alertConfigDataSelector, initialiseConfig, updateConfig } from "./alertsSlice";
import AuthContext from "../../../../../../authentication/views/components/Auth";
import AlertsDashboard, { CreateAlertDialog } from "./AlertsDashboard";
import { connect } from "react-redux";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { selectClusters } from "../explorer/explorerSlice";
import { makeStyles } from "@material-ui/styles";
import background from "../../../../../../../../assets/BG.jpg";
import darkbackground from "../../../../../../../../assets/BGDARK.jpg";
const theme=sessionStorage.getItem("theme");
let flag = 0;
const useStyles = makeStyles({
    tabs: {
        "& .MuiTabs-indicator": {
            //    backgroundColor: "orange",
            //   height: 3,
        },
        "& .MuiTab-root.Mui-selected": {
            color: theme=="dark"?"#FFF":"#3A3A3A",
        },
        "& .MuiTab-root": {
            color: theme=="dark"?"#DCDCDC":"#3A3A3A",
        },
    },
});

const AlertManager = ({ authentication }) => {
    const classes = useStyles();
    const [tabIndex, setTabIndex] = useState("0");
    const dispatch = useDispatch();
    const { token, emailId, userId } = useContext(AuthContext);
    const alertConfig = useSelector(alertConfigDataSelector);
    const userProfile = authentication.profileDetails;
    const [openAlertRuleDialog, isOpenAlertRuleDialog] = useState(false);
    const [openAlertTargetDialog, isOpenAlertTargetDialog] = useState(false);
    const clusters = useSelector(selectClusters);
   

    useEffect(async () => {
        console.log(sessionStorage.getItem("theme"))
        dispatch(initialiseConfig({ "auth-token": token, email: emailId, uid: userId }));
    }, []);

    // useEffect(()=>{window.location.reload()},[sessionStorage.getItem("theme")])
    useEffect(() => {
        
        if (flag < 6) {
            setTimeout(
                console.log("use"),
                dispatch(initialiseConfig({ "auth-token": token, email: emailId, uid: userId })),
                flag++,
                5000,
            );
           
        }
    });
    useEffect(() => {}, [alertConfig]);

    return (
        <>
        {/* height:window.innerHeight<700?'125vh':'108vh' */}
        {  console.log(sessionStorage.getItem("theme"), theme=="dark")}
         <img src={theme=="dark"?darkbackground:background} className={theme=="dark"?"bg-dark-search":"bg-img-search"} style={{marginTop:'-4em', height:window.innerHeight<700?'125vh':'108vh' }}></img>
            
            <Box  className="alert-dashboard">
           
                {/* {initialize()} */}
                <TabContext value={tabIndex}>
                    <TabList
                        TabIndicatorProps={{ style: { backgroundColor: "#DD0C4E", color:theme=="dark"?"#FFF": "#000" } }}
                        className={classes.tabs}
                        onChange={(event, index) => {
                            setTabIndex(index);
                        }}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Rules" value={"0"} />
                        <Tab label="Targets" value={"1"} />
                    </TabList>
                    <TabPanel value={"0"}>
                        {/*  sx={{ width: "90%", color:'#000' }} display={"flex"} justifyContent={"flex-end"} */}
                        <Box className="add-rule-button" >
                            <Button
                            style={{backgroundColor:theme=="dark"?"#DCDCDC": "#FFF",  zIndex:20}}
                                startIcon={<AddSharpIcon />}
                                onClick={() => {
                                    isOpenAlertRuleDialog(true);
                                }}
                            >
                                New Rule
                            </Button>
                        </Box>
                        <CreateAlertDialog
                        
                            clusters={clusters}
                            userProfile={userProfile}
                            open={openAlertRuleDialog}
                            // open={true}

                            onSave={(config) => {
                                console.log("line 88", config);
                                let clusterId = config.clusterId;
                                sessionStorage.setItem("clusterId", config.clusterId);

                                if (clusterId === undefined) {
                                    alert("clusterId cannot be undefined");
                                    return;
                                }
                                // console.log(alertConfig)
                                let updatedConfig = alertConfig.find((configData) => {
                                    // console.log(configData)
                                    return configData.clusterId === clusterId;
                                });
                                console.log("updated config", updateConfig);
                                updatedConfig = { ...updatedConfig };
                                if (
                                    updatedConfig === undefined ||
                                    Object.keys(updatedConfig).length === 0
                                ) {
                                    let cluster = clusters.find((cluster) => {
                                        return cluster.id === clusterId;
                                    });
                                    if (cluster === undefined || !("cluster_name" in cluster)) {
                                        return;
                                    }
                                    updatedConfig = {
                                        companyId: userProfile.companyId,
                                        clusterId: clusterId,
                                        clusterName: cluster.cluster_name,
                                        rules: [],
                                        targets: [],
                                    };
                                }
                                let rules = [...updatedConfig.rules];
                                if (
                                    config.name != "" &&
                                    config.query != "" &&
                                    config.alertOnCount != "" &&
                                    config.alertDuration != "" &&
                                    config.summary != "" &&
                                    config.description != ""
                                ) {
                                    sessionStorage.setItem("alert", "true");
                                    rules.push({
                                        id:
                                            userProfile.companyId +
                                            "_" +
                                            userProfile.userId +
                                            "_" +
                                            Date.now(),
                                        name: config.name,
                                        query: config.query,
                                        keys: config.keys,
                                        kvKeys: config.kvKeys,
                                        kvValues: config.kvValues,
                                        alertOnCount: config.alertOnCount,
                                        alertDuration: config.alertDuration,
                                        summary: config.summary,
                                        description: config.description,
                                    });
                                } else {
                                    sessionStorage.setItem("alert", "false");
                                    alert("Please enter all mandatory fields");
                                }
                                updatedConfig.rules = rules;
                                dispatch(
                                    updateConfig({
                                        auth: { uid: userId, email: emailId, "auth-token": token },
                                        alertConfig: updatedConfig,
                                    }),
                                );
                            }}
                            onClose={() => {
                                isOpenAlertRuleDialog(false);
                            }}
                            ruleId={
                                alertConfig.companyId +
                                "_" +
                                alertConfig.companyId +
                                "_" +
                                Date.now()
                            }
                        />

                        {alertConfig.map((config, index) => {
                            // console.log(config);
                            return (
                                config !== undefined && (
                                    <Box key={index}>
                                        <AlertsDashboard
                                            alertConfig={config}
                                            key={index}
                                            auth={{
                                                uid: userId,
                                                name: name,
                                                email: emailId,
                                                "auth-token": token,
                                            }}
                                        />
                                    </Box>
                                )
                            );
                        })}
                    </TabPanel>
                    <TabPanel value="1">
                        {/* <Box sx={{ width: "100%" }} display={"flex"} justifyContent={"flex-end"}> */}
                        <Box className="add-rule-button">
                            <Button
                                startIcon={<AddSharpIcon />}
                                style={{backgroundColor:theme=="dark"?"#DCDCDC": "#FFF",  zIndex:20}}
                                onClick={() => {
                                    isOpenAlertTargetDialog(true);
                                }}
                            >
                                New Target
                            </Button>
                        </Box>
                        <CreateAlertTargetDialog
                            clusters={clusters}
                            targetId={
                                userProfile.companyId + "_" + userProfile.userId + "_" + Date.now()
                            }
                            open={openAlertTargetDialog}
                            onClose={() => {
                                isOpenAlertTargetDialog(false);
                            }}
                            onSave={(target) => {
                                let clusterId = target.clusterId;
                                if (clusterId === undefined) {
                                    alert("clusterId cannot be undefined");
                                    return;
                                }
                                let updatedConfig = alertConfig.find((configData) => {
                                    return configData.clusterId === clusterId;
                                });

                                if (updatedConfig === undefined) {
                                    let cluster = clusters.find((cluster) => {
                                        return cluster.id === clusterId;
                                    });
                                    if (cluster === undefined || !("cluster_name" in cluster)) {
                                        return;
                                    }
                                    updatedConfig = {
                                        companyId: userProfile.companyId,
                                        clusterId: clusterId,
                                        clusterName: cluster.cluster_name,
                                        rules: [],
                                        targets: [],
                                    };
                                } else {
                                    updatedConfig = { ...updatedConfig };
                                }
                                let targets = [...updatedConfig.targets];
                                if (
                                    target.type != "" &&
                                    target.targetUrl != "" &&
                                    target.title != "" &&
                                    target.description != ""
                                ) {
                                    sessionStorage.setItem("alert", "true");
                                    targets.push({
                                        targetId:
                                            userProfile.companyId +
                                            "_" +
                                            userProfile.userId +
                                            "_" +
                                            Date.now(),
                                        type: target.type,
                                        authKey: target.authKey,
                                        targetUrl: target.targetUrl,
                                        title: target.title,
                                        description: target.description,
                                    });
                                } else {
                                  
                                    sessionStorage.setItem("alert", "false");
                                    alert("Please enter all mandatory fields");
                                }

                                updatedConfig.targets = targets;
                                // console.log(updateConfig)
                                dispatch(
                                    updateConfig({
                                        auth: { uid: userId, email: emailId, "auth-token": token },
                                        alertConfig: updatedConfig,
                                    }),
                                );
                              
                                isOpenAlertTargetDialog(false);
                                
                            }}

                        ></CreateAlertTargetDialog>
                        {alertConfig.map((config, index) => {
                            // console.log(config);
                            return (
                                <AlertTargets
                                    key={index}
                                    alertConfig={config}
                                    auth={{
                                        uid: userId,
                                        name: name,
                                        email: emailId,
                                        "auth-token": token,
                                    }}
                                ></AlertTargets>
                            );
                        })}
                    </TabPanel>
                </TabContext>
            </Box>
        </>
    );
};

const mapStateToProps = (state) => ({
    authentication: state.authentication,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AlertManager);
// export default
