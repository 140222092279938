import {Box, Grid} from "@mui/material";
import React,{useEffect, useState} from "react";
import {isTailConnected, onClose, onConnected, onMessage, tailLogsSelector} from "./TailSlice";
import {useDispatch, useSelector,useContext} from "react-redux";
import {TAIL_HOST} from "../../lib/url";
// import isPaused from '../explorer/Explorer'
// import {emailSelector, tokenSelector, uidSelector} from "../authentication/AuthSlice";
import {Log} from "../../component/LogsTableVIew";
import {selectClusterId} from "./explorerSlice";
import AuthContext from "../../../../../../authentication/views/components/Auth";

export default function Tail({query, maxHeight, token, emailId,userId}) {
    const isConnected = useSelector(isTailConnected)
   
    // const { token, emailId, userId } = useContext(AuthContext);
    const tailLogs = useSelector(tailLogsSelector)
    // const uid = useSelector(uidSelector)
    // const token = useSelector(tokenSelector)
    // const email = useSelector(emailSelector)
    const dispatch = useDispatch()
    const clusterName= useSelector(selectClusterId)
    const theme=sessionStorage.getItem("theme")
    useEffect(() => {
        console.log(tailLogs)
    }, [tailLogs])
    useEffect(()=>{
        console.log(sessionStorage.getItem("tailPaused"))
    })

    useEffect(() => {
        let socket = new WebSocket(`wss://${TAIL_HOST}/${clusterName}/loki/api/v1/tail?query=${encodeURI(query)}&&auth-token=${token}&&email=${emailId}&&uid=${userId}`)

        socket.addEventListener("open", (ev) => {
            dispatch(onConnected({}))
        })
        socket.addEventListener('close', ev => {
            console.log(ev)
            dispatch(onClose({}))
        })
        socket.addEventListener('message', ev => {
            dispatch(onMessage(ev.data))
        })
        socket.addEventListener('error', ev => {
            console.warn(ev)
            socket = new WebSocket(`wss://${TAIL_HOST}/${clusterName}/loki/api/v1/tail?query=${encodeURI(query)}&&auth-token=${token}&&email=${emailId}&&uid=${userId}`)
        })
        return () => {
            socket.close()
        }
    }, [maxHeight, dispatch])
    return <Box className="box-tail-logs" style={{backgroundColor:'transparent'}} sx={{maxHeight: maxHeight, minHeight: maxHeight}}>

        <Box textAlign={"left"}><p style={{color:theme=="dark"?"#fff":'#000',zIndex:25,position:'relative'}}>Status : {isConnected ? "connected" : "not connected"}</p></Box>
        <Box sx={{border: 1, p: 1, textAlign: "left", overflow: "auto",maxHeight: '70%',zIndex:20,backgroundColor:theme=="dark"?'#000':'transparent'}} >
            <Grid container sx={{backgroundColor:theme=="dark"?'#000':'transparent',position:'relative'}}>
             
                <Grid item xs={4} md={4} lg={4} style={{color:theme=="dark"?"#fff":'#000',position:'relative'}}>
                    Time
                </Grid>
                <Grid item xs={8} md={8} lg={8} style={{color:theme=="dark"?"#fff":'#000',position:'relative'}}>
                    Message
                </Grid>
                {tailLogs.map((value, index) => {
                    return <Grid key={index} container className={value.line.toUpperCase().startsWith("ERROR")?"table-row-logs-red":value.line.toUpperCase().startsWith("INFO - WARN")?'table-row-logs-yellow':'table-row-logs-green'} >
                        
                        <Grid item xs={4} md={3} lg={2}>
                            <p style={{color:theme=="dark"?"#fff":'#000',zIndex:20,position:'relative',backgroundColor:theme=="dark"?'#000':'transparent'}}>{value.time}</p>
                        </Grid>
                        <Grid item xs={8} md={9} lg={10}>
                            <Log log={value.line}/>
                        </Grid>
                    </Grid>
                })}
            </Grid>

        </Box>
    </Box>
}
