import React, { useState,useContext } from "react";
// import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect } from "react";
import _ from "lodash";
import { Table} from "antd";
import { useParams } from "react-router-dom";
import CpanelActions from "../../../redux/CpanelActions";
import "antd/dist/antd.css";
import "./data.scss";
import DashboardActions from "../../CRM/dashboard/redux/dashboardActions";
// import AuthContext from "../../CRM/authentication/views/components/Auth";
// import AuthContext from "../../CRM/authentication/views/components/Auth_Pulse";
import Multiselect from "multiselect-react-dropdown";

let columns = [];
let obj = {};
let cluster = [],
    clusterFiltered = [],
    clusterSource = [],
    cfiltering = false,
    clusterList = [];
let indexer = [],
    indexerFiltered = [],
    indexerSource = [],
    ifiltering = false,
    indexerList = [];
let kafka = [],
    kafkaFiltered = [],
    kafkaSource = [],
    kfiltering = false,
    kafkaList = [];
let search = [],
    searchFiltered = [],
    searchSource = [],
    sfiltering = false,
    searchList = [];
let gateway = [],
    gatewayFiltered = [],
    gatewaySource = [],
    gfiltering = false,
    gatewayList = [];
let optimizer = [],
    optimizerFiltered = [],
    optimizerSource = [],
    ofiltering = false,
    optimizerList = [];

const DataTable = ({ serverDetails, details, clusterDetails,getClusterDetail }) => {
    // const {token,emailId,userId}=useContext(AuthContext)
    // let header={
    //      token,
    //     userId,
    //      emailId
    // }
    const id = useParams();
    const [cselection, setcSelection] = useState("null");
    const [iselection, setiSelection] = useState("null");
    const [kselection, setkSelection] = useState("null");
    const [sselection, setsSelection] = useState("null");
    const [gselection, setgSelection] = useState("null");
    const [oselection, setoSelection] = useState("null");

    useEffect(() => {
        if (location.pathname.includes("/spike")) {
            getClusterDetail(id.id)
            // console.log("data if-spike");
            // serverDetails(header,id, "spike");
        } else {
            // serverDetails(header,id, "pulse");
            serverDetails(id, "pulse");
            console.log("data if-spike");
        }
    }, []);
   

    const convert = (entry) => {
        return entry.replace(/_/gi, " ").toUpperCase();
    };

    const onRemove = (selectedList, removedItem) => {
        console.log(selectedList, removedItem);
    };

    const getColumns = (info, objname) => {
        (columns = []),
            (clusterSource = []),
            (kafkaSource = []),
            (indexerSource = []),
            (searchSource = []),
            (gatewaySource = []),
            (optimizerSource = []);
        (indexer = []),
            (cluster = []),
            (kafka = []),
            (gateway = []),
            (search = []),
            (optimizer = []);
        // serverDetails(id);
        console.log(details);
        info.map(
            (item, index) => (
                //   columns
                index == 0 &&
                    Object.keys(item).map(
                        (entry, index2) => (
                            (obj["title"] = convert(entry)),
                            (obj["dataIndex"] = entry),
                            (obj["key"] = entry),
                            columns.push({
                                ...obj,
                            }),
                            columns.slice(index2),
                            objname == "cluster"
                                ? ((cluster = columns.slice()), cluster.slice(index2))
                                : objname == "indexer"
                                ? ((indexer = columns.slice()), indexer.slice(index2))
                                : objname == "kafka"
                                ? ((kafka = columns.slice()), kafka.slice(index2))
                                : objname == "search"
                                ? ((search = columns.slice()), search.slice(index2))
                                : objname == "gateway"
                                ? ((gateway = columns.slice()), gateway.slice(index2))
                                : objname == "optimizer"
                                ? ((optimizer = columns.slice()), optimizer.slice(index2))
                                : ""
                        ),
                    ),
                (obj = {}),
                objname == "cluster"
                    ? clusterSource.push({ ...item, item })
                    : objname == "indexer"
                    ? indexerSource.push({ ...item, item })
                    : objname == "kafka"
                    ? kafkaSource.push({ ...item, item })
                    : objname == "search"
                    ? searchSource.push({ ...item, item })
                    : objname == "gateway"
                    ? gatewaySource.push({ ...item, item })
                    : objname == "optimizer"
                    ? optimizerSource.push({ ...item, item })
                    : ""
            ),
        );

        // clusterSource = clusterSource.slice(clusterSource.length / 2);
        // indexerSource = indexerSource.slice(indexerSource.length / 2);
        // kafkaSource = kafkaSource.slice(kafkaSource.length / 2);
    };

    const drawTable = (server, source, filter, keyValue, list) => {
        const onSelect = (selectedList, selectedItem, keyEntry) => {
            if (keyEntry == "cluster") {
                if (!cfiltering && cselection == "clusterclicked") {
                    setcSelection("null");
                    clusterFiltered = [];
                }
                cfiltering = true;
                clusterFiltered = selectedList.slice();
            } else if (keyEntry == "indexer") {
                if (!ifiltering && iselection == "indexerClicked") {
                    setiSelection("null");
                    indexerFiltered = [];
                }
                ifiltering = true;
                indexerFiltered = selectedList.slice();
            } else if (keyEntry == "kafka") {
                kfiltering = true;
                if (!kfiltering && iselection == "kafkaClicked") {
                    setkSelection("null");
                    kafkaFiltered = [];
                }
                kafkaFiltered = selectedList.slice();
            } else if (keyEntry == "search") {
                sfiltering = true;
                if (!sfiltering && sselection == "searchClicked") {
                    setsSelection("null");
                    searchFiltered = [];
                }
                searchFiltered = selectedList.slice();
            } else if (keyEntry == "gateway") {
                gfiltering = true;
                if (!gfiltering && gselection == "gatewayClicked") {
                    setgSelection("null");
                    gatewayFiltered = [];
                }
                gatewayFiltered = selectedList.slice();
            } else if (keyEntry == "optimizer") {
                ofiltering = true;
                if (!ofiltering && oselection == "optimizerClicked") {
                    setoSelection("null");
                    optimizerFiltered = [];
                }
                optimizerFiltered = selectedList.slice();
            }
        };

        return (
            <div>
                <div className="list-menu">
                    {list.map((item) => (
                        <span className="list-menu-item">{item.title}</span>
                    ))}
                </div>
                <div className="dropdown-select-columns">
                    <Multiselect
                        className="dropdown-select-columns"
                        options={server}
                        placeholder="Select columns"
                        displayValue="title"
                        onSelect={(selectedList, selectedItem) =>
                            onSelect(selectedList, selectedItem, keyValue)
                        }
                        onRemove={(selectedList, removedItem) =>
                            onRemove(selectedList, removedItem)
                        }
                    />
                </div>

                <Table
                    scroll={{ x: true, y: 300 }}
                    columns={filter}
                    dataSource={source}
                    rowClassName={(record, index) => (index % 2 == 0 ? "light" : "dark")}
                />
            </div>
        );
    };

    // const getList=(list)=>{
    // list.map(item=>{
    //   return <div style={{marginBottom:30,marginTop:30}}>
    //     <span>{item.title}</span>
    //   </div>
    // })
    // }

    const reset = (server) => {
        server == "clusterFiltered"
            ? (setcSelection("null"), (clusterFiltered = []), (clusterList = []))
            : server == "indexerFiltered"
            ? (setiSelection("null"), (indexerFiltered = []), (indexerList = []))
            : server == "kafkaFiltered"
            ? (setkSelection("null"), (kafkaFiltered = []), (kafkaList = []))
            : server == "searchFiltered"
            ? (setsSelection("null"), (searchFiltered = []), (searchList = []))
            : server == "gatewayFiltered"
            ? (setgSelection("null"), (gatewayFiltered = []), (gatewayList = []))
            : server == "optimizerFiltered"
            ? (setoSelection("null"), (optimizerFiltered = []), (optimizerList = []))
            : "";
    };

    //  if (!_.isEmpty(details))
        return (
            <div className="container-server-details">
                {/* working */}
                {/* {serverDetails(id)} */}
                <div>
                    {/* start of table 1 */}
                    <div>
                        <p className="title-graph">Server Details</p>
                        <div className="container-filter-button">
                            <button
                                className={
                                    cselection == "null"
                                        ? "apply-filter-button-before"
                                        : "apply-filter-button"
                                }
                                onClick={() => (
                                    setcSelection("clusterclicked"),
                                    (cfiltering = false),
                                    (clusterList = clusterFiltered.slice(0))
                                )}
                            >
                                Apply filter
                            </button>
                            {cselection != "null" || cfiltering || clusterFiltered.length > 0 ? (
                                <button
                                    className="reset-filter-button"
                                    onClick={() => reset("clusterFiltered")}
                                >
                                    Reset filter
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                        {clusterSource.length == 0
                            ? !_.isEmpty(details.clusterInfo) && getColumns(details.clusterInfo, "cluster")
                            : <h5>No data</h5>}
                        {cselection == "null" || cfiltering || clusterFiltered.length == 0
                            ? drawTable(cluster, clusterSource, cluster, "cluster", clusterList)
                            : console.log("else in first")}

                        {cselection == "clusterclicked" && clusterFiltered.length > 0
                            ? drawTable(
                                  cluster,
                                  clusterSource,
                                  clusterFiltered,
                                  "cluster",
                                  clusterList,
                              )
                            : clusterFiltered.length == 0 && cselection == "clusterclicked"
                            ? setcSelection("null")
                            : ""}
                    </div>

                    {/* table 1 ends here */}

                    {/* Table 2 starts here */}
                    <div>
                        <p className="title-graph">Indexer Details</p>
                        <div>
                            <button
                                className={
                                    iselection == "null"
                                        ? "apply-filter-button-before"
                                        : "apply-filter-button"
                                }
                                onClick={() => (
                                    setiSelection("indexerClicked"),
                                    (ifiltering = false),
                                    (indexerList = indexerFiltered.slice(0))
                                )}
                            >
                                Apply filter
                            </button>
                            {iselection != "null" || ifiltering || indexerFiltered.length > 0 ? (
                                <button
                                    className="reset-filter-button"
                                    onClick={() => reset("indexerFiltered")}
                                >
                                    Reset filter
                                </button>
                            ) : (
                                ""
                            )}
                        </div>

                        {indexerSource.length == 0
                            ? !_.isEmpty(details.indexerInfo) &&getColumns(details.indexerInfo, "indexer")
                            : ""}
                        {console.log(indexerSource.length, indexer)}
                        {ifiltering || indexerFiltered.length == 0 || iselection == "null"
                            ? drawTable(indexer, indexerSource, indexer, "indexer", indexerList)
                            : // getColumns(details.indexerInfo, "indexer")
                              console.log("else in first")}

                        {iselection == "indexerClicked" && indexerFiltered.length > 0
                            ? drawTable(
                                  indexer,
                                  indexerSource,
                                  indexerFiltered,
                                  "indexer",
                                  indexerList,
                              )
                            : indexerFiltered.length == 0 && iselection == "indexerClicked"
                            ? setiSelection("null")
                            : ""}
                    </div>

                    {/* kafka table no.: 3 */}
                    <div>
                        <p className="title-graph">Kafka Details</p>
                        <div>
                            <button
                                className={
                                    kselection == "null"
                                        ? "apply-filter-button-before"
                                        : "apply-filter-button"
                                }
                                onClick={() => (
                                    setkSelection("kafkaClicked"),
                                    (kfiltering = false),
                                    (kafkaList = kafkaFiltered.slice(0))
                                )}
                            >
                                Apply filter
                            </button>
                            {kselection != "null" || kfiltering || kafkaFiltered.length > 0 ? (
                                <button
                                    className="reset-filter-button"
                                    onClick={() => reset("kafkaFiltered")}
                                >
                                    Reset filter
                                </button>
                            ) : (
                                ""
                            )}
                        </div>

                        {kafkaSource.length == 0 ?!_.isEmpty(details.cpanelInfo) &&  getColumns(details.cpanelInfo, "kafka") : ""}
                        {kfiltering || kafkaFiltered.length == 0 || kselection == "null"
                            ? drawTable(kafka, kafkaSource, kafka, "kafka", kafkaList)
                            : // getColumns(details.cpanelInfo, "kafka")
                              console.log("else in first")}

                        {kselection == "kafkaClicked" && kafkaFiltered.length > 0
                            ? drawTable(kafka, kafkaSource, kafkaFiltered, "kafka", kafkaList)
                            : kafkaFiltered.length == 0 && kselection == "kafkaClicked"
                            ? setkSelection("null")
                            : ""}
                    </div>

                    <div>
                        <p className="title-graph">Search Details</p>
                        <div>
                            <button
                                className={
                                    sselection == "null"
                                        ? "apply-filter-button-before"
                                        : "apply-filter-button"
                                }
                                onClick={() => (
                                    setsSelection("searchclicked"),
                                    (sfiltering = false),
                                    (searchList = searchFiltered.slice(0))
                                )}
                            >
                                Apply filter
                            </button>
                            {sselection != "null" || sfiltering || searchFiltered.length > 0 ? (
                                <button
                                    className="reset-filter-button"
                                    onClick={() => reset("searchFiltered")}
                                >
                                    Reset filter
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                        {searchSource.length == 0 ?!_.isEmpty(details.searchInfo) && getColumns(details.searchInfo, "search") : ""}
                        {sselection == "null" || sfiltering || searchFiltered.length == 0
                            ? drawTable(search, searchSource, search, "search", searchList)
                            : console.log("else in first")}

                        {sselection == "searchclicked" && searchFiltered.length > 0
                            ? drawTable(search, searchSource, searchFiltered, "search", searchList)
                            : searchFiltered.length == 0 && sselection == "searchclicked"
                            ? setsSelection("null")
                            : ""}
                    </div>

                    <div>
                        <p className="title-graph">Gateway Details</p>
                        <div>
                            <button
                                className={
                                    gselection == "null"
                                        ? "apply-filter-button-before"
                                        : "apply-filter-button"
                                }
                                onClick={() => (
                                    setgSelection("gatewayclicked"),
                                    (gfiltering = false),
                                    (gatewayList = gatewayFiltered.slice(0))
                                )}
                            >
                                Apply filter
                            </button>
                            {gselection != "null" || gfiltering || gatewayFiltered.length > 0 ? (
                                <button
                                    className="reset-filter-button"
                                    onClick={() => reset("gatewayFiltered")}
                                >
                                    Reset filter
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                        {gatewaySource.length == 0
                            ? !_.isEmpty(details.gatewayInfo) && getColumns(details.gatewayInfo, "gateway")
                            : ""}
                        {gselection == "null" || gfiltering || gatewayFiltered.length == 0
                            ? drawTable(gateway, gatewaySource, gateway, "gateway", gatewayList)
                            : console.log("else in first")}

                        {gselection == "gatewayclicked" && gatewayFiltered.length > 0
                            ? drawTable(
                                  gateway,
                                  gatewaySource,
                                  gatewayFiltered,
                                  "gateway",
                                  gatewayList,
                              )
                            : gatewayFiltered.length == 0 && gselection == "gatewayclicked"
                            ? setgSelection("null")
                            : ""}
                    </div>

                    <div>
                        <p className="title-graph">Optimizer Details</p>
                        <div>
                            <button
                                className={
                                    oselection == "null"
                                        ? "apply-filter-button-before"
                                        : "apply-filter-button"
                                }
                                onClick={() => (
                                    setoSelection("optimizerclicked"),
                                    (ofiltering = false),
                                    (optimizerList = optimizerFiltered.slice(0))
                                )}
                            >
                                Apply filter
                            </button>
                            {oselection != "null" || ofiltering || optimizerFiltered.length > 0 ? (
                                <button
                                    className="reset-filter-button"
                                    onClick={() => reset("optimizerFiltered")}
                                >
                                    Reset filter
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                        {optimizerSource.length == 0
                            ?!_.isEmpty(details.optimizerInfo) && getColumns(details.optimizerInfo, "optimizer")
                            : ""}
                        {oselection == "null" || ofiltering || optimizerFiltered.length == 0
                            ? drawTable(
                                  optimizer,
                                  optimizerSource,
                                  optimizer,
                                  "optimizer",
                                  optimizerList,
                              )
                            : console.log("else in first")}

                        {oselection == "optimizerclicked" && optimizerFiltered.length > 0
                            ? drawTable(
                                  optimizer,
                                  optimizerSource,
                                  optimizerFiltered,
                                  "optimizer",
                                  optimizerList,
                              )
                            : optimizerFiltered.length == 0 && oselection == "optimizerclicked"
                            ? setoSelection("null")
                            : ""}
                    </div>
                </div>
            </div>
        );
    // else {
    //     return <h2>No Data</h2>;
    // }
};

const mapStateToProps = (state) => ({
    details: state.cpanel.ServerDetail,
    clusterDetails: state.cpanel.liveClusterDetails,
});

const mapDispatchToProps = (dispatch) => ({
    serverDetails: (id, name) => dispatch(CpanelActions.ServerDetails(id, name)),
    getClusterDetail:(id)=>dispatch(DashboardActions.getClusterDetail(id))
});

export default connect(mapStateToProps, mapDispatchToProps)((DataTable));
