import React,{useEffect} from "react";
import { Typography,Slider,Progress } from "antd";
import PropTypes from "prop-types";
import { UIBaseCard, UIIcon } from "../../../components/lib";
import VERBIAGE from "../../../../../utils/VERBIAGE";
// import { faCircle } from '@fortawesome/free-solid-svg-icons';


import "../styles/licenseCard.scss";

// const { Title, Text } = Typography;
let number_of_days=0
let days=100;
let theme=sessionStorage.getItem("theme")
const LicenseCard = ({ companyName, licenseId, planName, daysLeft, isActive }) => {
if(planName=="SELF")
{
  number_of_days=30
}
else{
  number_of_days=365
}
  const getPercent=(daysLeft)=>{
     days= Math.round((daysLeft*100)/number_of_days)
   
    return days
  }

  const subscriptionLeft=()=>{
    console.log(daysLeft)
    if(daysLeft<=0)
    {
      sessionStorage.setItem("planOver",planName.toLowerCase())
    }
  }
  useEffect(()=>(theme = sessionStorage.getItem("theme")))
  return (
    <UIBaseCard
      classnames="license-card"
      padding={UIBaseCard.padding.large}
      margin={UIBaseCard.margin.none}
      variant={"outlined"}
      content={
        <>
          <div
            style={{
           
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              color:"#000"
            }}
          >
            {/* <div style={{ fontSize: "1.6rem", fontWeight: 600 }}>{companyName}</div> */}
            <div
              style={{
                // backgroundImage:"linear-gradient( to top right, #29C2B6, #2CD97B )",
                backgroundColor: "#DD0C4E",
                color: isActive ? "#FFF" : "#FF5C5C",
                height: "29px",
                lineHeight: "29px",
                width: "80px",
                display: "flex",
                justifyContent: "space-evenly",
                textAlign: "center",
                borderRadius: 3,
                marginTop:-5,
                paddingLeft:5,
                paddingRight:5,
                fontFamily:'Poppins',
                marginLeft:window.screen.width<900?100 :150
              }}
            >
              {daysLeft>0 ? <>Active</> : <>Inactive</>}
              <span style={{ fontSize: 10, alignSelf: "center" }}>
              
                </span>
            </div>
          </div>
          <p
            style={{
              marginTop: "-25px",
              fontFamily: "Poppins,sans-serif",
              fontSize: "0.9rem",
              fontWeight: 700,
              color:theme=="dark"?"#DCDCDC":'#3A3A3A'
            }}
          >
            {planName=='SELF'?'SELF HOSTED':planName}{" "}{VERBIAGE.PLANS.PLAN}
            <br/>
            <br/>
            <span style={{ fontSize: ".8rem", fontWeight: 500 , color:theme=="dark"?"#DCDCDC":'#3A3A3A'}}>{VERBIAGE.PROFILE.LICENSE.LICENSE_ID}{":  "}{daysLeft<1?" Expired":licenseId}</span>
            <br></br>
            <span style={{ fontSize: ".9rem", fontWeight: 700, color:theme=="dark"?"#DCDCDC":'#3A3A3A' }}>{daysLeft}</span>
            <span style={{ fontSize: ".7rem", color:theme=="dark"?"#DCDCDC":'#3A3A3A' }}>{VERBIAGE.PROFILE.LICENSE.DAYS_LEFT}</span>
            {subscriptionLeft()}
          </p>
          {/* <Slider defaultValue={365} /> */}
          <Progress strokeLinecap="square" percent={getPercent(daysLeft )} strokeColor={(days)<30?'#E42217':'#1C1850'} trailColor={"#c9c7c7"} showInfo={false}/>
          
        </>
      }
    />
  );
};

LicenseCard.propTypes = {
  // companyName: PropTypes.string.isRequired,
  licenseId: PropTypes.string.isRequired,
  planName: PropTypes.string.isRequired,
  daysLeft: PropTypes.number,
  isActive: PropTypes.bool
}

LicenseCard.defaultProps = {
  daysLeft: 0,
  isActive: false
}

export default LicenseCard;
