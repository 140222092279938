import { Typography, Modal } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UIBaseCard, UIIcon, UIButton } from "../../../components/lib";
import { CheckCircleFilled } from "@ant-design/icons";
import "../styles/pricingCard.scss";
import VERBIAGE from "../../../../../utils/VERBIAGE";
import Dialog from "../../../components/lib/dialogs/SimpleDialog";
import DashboardActions from "../../redux/dashboardActions";
import AuthenticationActions from "../../../authentication/redux/AuthenticationActions";
import AuthContext from "../../../authentication/views/components/Auth";
let theme = sessionStorage.getItem("theme");
const { Title } = Typography;
let plan = false;
const pricingCard = ({
    companyName,
    ongoingPlan,
    noOfDaysLeft,
    isActive,
    offer,
    cost,
    detail,
    sizew,
    sizeh,
    SubscribeToPlan,
    planId,
    getCredentials,
    history,
    dashboard,
    detailCost,
}) => {
    const { token, emailId, userId } = useContext(AuthContext);
    let navigate=useNavigate();
    let header = {
        token,
        userId,
        emailId,
    };

    const [modal, setIsModalVisible] = useState(false);
    // const [plan,setPlan]= useState(false)

    useEffect(() => getCredentials(header), []);

    const subscribe = () => {
        // console.log(planId);
        if (planId == "SAAS-1") {
            SubscribeToPlan(planId);
            plan = false;
            // sessionStorage.setItem("saas","true");
        } else if (planId == "SELF-1") {
            SubscribeToPlan(planId);
            plan = false;
            sessionStorage.setItem("self", "true");
        } else {
            SubscribeToPlan(planId);
            plan = true;
            sessionStorage.setItem("mgd", "true");
        }
        getCredentials(header), setIsModalVisible(true);
        // subscribe(),
        // console.log(plan);
    };
    useEffect(()=>(theme = sessionStorage.getItem("theme")))
    return (
        <div style={{ marginRight: 30 }}>
            {
                <Dialog
                    isModalVisible={modal}
                    onClose={() => setIsModalVisible(false)}
                    title={modal && !plan ? "Success" : "Subscription details"}
                    content={
                        dashboard.subscribed_msg == "" ? "Loading..." : dashboard.subscribed_msg
                    }
                    variant={"success"}
                    actionText={modal && !plan ? VERBIAGE.BUTTONS.CONTINUE : "Continue"}
                    onActionClick={() => (
                        getCredentials(header),
                        setIsModalVisible(false),
                        !plan
                            ? planId == "SAAS-1"
                                ? navigate(VERBIAGE.NAVIGATION.PATHS.CRMDASHBOARD)
                                : navigate(VERBIAGE.NAVIGATION.PATHS.PROFILE)
                            : navigate(VERBIAGE.NAVIGATION.PATHS.PLAN)
                    )}
                />
            }

            <UIBaseCard
                sizew={sizew}
                sizeh={sizeh}
                variant={isActive?"filled":"outlined"}
                color={"primary"}
                content={
                    <React.Fragment>
                        <div className="companyName">
                            
                            <div className="title-pricing-card">
                            <div
                                style={{
                                    color: isActive ? "#FFF" : "#FF5C5C",
                                    backgroundColor: isActive? "#DD0C4E"
                                        // ? "linear-gradient( to top right, #29C2B6, #2CD97B )"
                                        : "transparent",
                                    // backgroundColor: isActive ?'#41E799': 'transparent',
                                    border: "black",
                                    position:'relative',
                                    marginLeft: "70%",
                                    marginTop:'0.5em'
                                   
                                }}
                                className="cardStatus"
                            >
                                {isActive ? (
                                    <span style={{ fontSize: 14, alignSelf: "center" }}>
                                      &nbsp;  Active 
                                    </span>
                                ) : (
                                    ""
                                )}
                                <span style={{ fontSize: 10, alignSelf: "center" }}></span>
                            </div>
                        </div>
                        <Title level={3}>
                            <p style={{ fontWeight: "bold",zIndex:10,position:'relative', color: theme == "dark" ? "#DCDCDC" : "#FFF", marginLeft:ongoingPlan=='SaaS'?'5em':ongoingPlan.includes('Self')?'2em':'', marginTop:'0.8em' }}>{ongoingPlan}</p>
                        </Title>
                        </div>

                        <Typography className="sub-details-plan" style={{color: theme == "dark" ? "#DCDCDC" : "#3a3a3a"}}>{detail}</Typography>

                        <div
                            style={{
                                paddingTop: planId != "MGD-1" ? -40 : -10,
                                paddingBottom: planId != "MGD-1" ? 10 : 10,
                                color: theme == "dark" ? "#DCDCDC" : "#3a3a3a"
                            }}
                            className={"pricing-card-cost-title__margin-ss"}
                        >
                            <span style={{color: theme == "dark" ? "#DCDCDC" : "#3a3a3a"}}>
                                <h4 className="Dollarsymbol" style={{color: theme == "dark" ? "#DCDCDC" : "#3a3a3a"}}>{planId != "MGD-1" ? "$" : ""}</h4>
                                <span className={"pricing-card-cost"} style={{color: theme == "dark" ? "#DCDCDC" : "#3a3a3a"}}>{cost}</span>
                                <br />
                                {planId != "MGD-1" ? "per GB Ingested" : "pricing"}
                            </span>
                        </div>
                        <Typography className="sub-details-cost-plan" style={{color: theme == "dark" ? "#DCDCDC" : "#3a3a3a"}}>{detailCost}</Typography>

                        <div style={{ marginTop: 25 }}>
                            {/* {console.log(ongoingPlan.toLowerCase(),"&&&", sessionStorage.getItem("planOver"),  ongoingPlan.toLowerCase().includes(sessionStorage.getItem("planOver")) )} */}
                            {isActive && !ongoingPlan.includes(sessionStorage.getItem("planOver")) ? (
                                ""
                            ) : (
                                <UIButton
                                    onClick={
                                        () =>
                                            subscribe()
                                    }
                                    type={planId != "MGD-1" ? "primary" : "transparent"}
                                    className={
                                        planId == "MGD-1"
                                            ? "subscribe-button-price-categories__button-top-margin-mgd"
                                            : planId == "SELF-1"
                                            ? "subscribe-button-price-categories__button-top-margin-self"
                                            : "subscribe-button-price-categories__button-top-margin-saas"
                                    }
                                >
                                    {planId != "MGD-1"
                                        ? planId == "SAAS-1"
                                            ? "Subscribe"
                                            : "Start Trial"
                                        : "I am Interested"}
                                </UIButton>
                            )}
                        </div>
                    </React.Fragment>
                }
                classnames="parent"
            ></UIBaseCard>
        </div>
    );
};

const mapStateToProps = (state) => ({
    dashboard: state.dashboard,
    userProfile: state.authentication.profileDetails,
    companyProfile: state.authentication.companyDetails,
});

const mapDispatchToProps = (dispatch) => ({
    SubscribeToPlan: (id) => {
        dispatch(DashboardActions.SubscribeToPlan(id));
    },
    getCredentials: (header) => {
        dispatch(AuthenticationActions.getCredentials(header));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(pricingCard);
