import {
    Box,
    Button,
    Grid,
    Popover,
    Select,
    TextField,
    Typography,
    InputLabel,
    MenuItem,
    ListItem,
    ListItemButton,
    ListItemText,
    FormControl,
} from "@mui/material";
import moment from "moment";
// import {
//     PlayArrowSharp,
//     SearchSharp,
//     StopSharp,
//     DataArraySharp,
//     AddAlertSharp,
//     Label,
//     ContactSupportOutlined,
// } from "@mui/icons-material";

import PlayArrowSharpIcon from "@mui/icons-material/PlayArrowSharp";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import StopSharpIcon from "@mui/icons-material/StopSharp";
import DataArraySharpIcon from "@mui/icons-material/DataArraySharp";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PauseIcon from "@mui/icons-material/Pause";
import ReplayIcon from "@mui/icons-material/Replay";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import DateTimeSelector from "./DateTimeSelector";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
// import { SaveSharp } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import QuerySaveModal from "./QuerySaveModal";
import QueryListModal from "./QueryListModal";
import LabelForm from "./LabelForm";
import { FixedSizeList } from "react-window";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import SideLabelPanel from "./SideLabelPanel";
import {
    addQuery,
    onFiltersAdded,
    onFiltersRemoved,
    selectQueries,
    selectQuery,
    deleteQuery,
    onOpenQuery,
    fetchLogs,
    selectClusterId,
} from "../features/explorer/explorerSlice";
import AuthContext from "../../../../../authentication/views/components/Auth";
import React, { useState, useContext, useEffect } from "react";
import { reconstructQuery } from "../lib/queryUtils";
import { DateTime } from "luxon";
import { TimeUnits } from "../lib/dateTimeUtils";
import { Duration } from "luxon/build/cjs-browser/luxon";
// import { StaticDateTimePicker, TabContext, TabPanel } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers";
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
let theme = sessionStorage.getItem("theme");
let Time = [
    "Last 5 minutes",
    "Last 15 minutes",
    "Last 30 minutes",
    "Last 1 hour",
    "Last 3 hours",
    "Last 6 hours",
    "Last 12 hours",
    "Last 24 hours",
    "Last 2 days",
    "Last 7 days",
    "Last 30 days",
    "Last 60 days",
    "Last 90 days",
    "Last 6 months",
    "Last 1 year",
    "Last 2 years",
    "Last 5 years",
];
let tempTime;
// var a, b, diffDuration;
let count = 0;
const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "black",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "yellow",
        },
        "& .MuiOutlinedInput-root": {
            // backgroundColor:'none',
            // marginLeft:'-0.25rem',

            "& fieldset": {
                backgroundColor: "transparent",
                // borderColor: "#1C1850",
                height: "40px",
                marginTop: "5px",
                // width:'93%',
                color: "black",
                fontSize: "12px",
                borderWidth: "1.25px",
                borderStyle: "solid",
                borderImage: "linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);",
                borderImageSlice: 1,
            },
            "&:hover fieldset": {
                borderColor: "#1C1850",
            },
            "& .MuiOutlinedInput-notchedOutline legend": { display: "none" },
            // '&.Mui-focused fieldset': {
            //   borderColor: 'yellow',
            // },
        },
    },
})(TextField);
const useStyles = makeStyles({
    customOutline: {
        "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1.25px",
            borderStyle: "solid",
            borderImage: "linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);",
            borderImageSlice: 1,
        },
    },
});

export default function QueryForm({
    fromTimeRecord,
    toTimeRecord,
    query,
    labels,
    filters,
    onQueryChange,
    onFromDateChange,
    onToDateChange,
    onFilterChange,
    onSearchClicked,
    tailing,
    onTailing,
    onStopTailing,
    onpauseTailing,
    onResumeTailing,
}) {
    const userId2 = sessionStorage.getItem("userId");
    const { token, emailId, userId } = useContext(AuthContext);
    const [openQueryRepoDrawer, setOpenQueryRepoDrawer] = useState(false);
    const [latencyTime, setLatencyTime] = useState(0);
    // const [openQuerySaveModal, setOpenQuerySaveModal] = useState(false);
    const queries = useSelector(selectQueries);
    const selectedCluster = useSelector(selectClusterId);
    const query2 = useSelector(selectQuery);
    const dispatch = useDispatch();
    const [saveQueryModal, setOpenQuerySaveModal] = useState(false);
    const [relativeCount, setRelativeCount] = useState(15);
    const [timeUnit, setTimeUnit] = useState(2);
    const [selectedTime, setSelectedTime] = useState("Last 5 minutes");
    const [absFrom, setAbsFrom] = useState(DateTime.now().toFormat("MM/dd/yy hh:mm"));
    const [absTo, setAbsTo] = useState(DateTime.now().toFormat("MM/dd/yy hh:mm"));
    const [timeChange, setTimeChange] = useState(false);
    const classes = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        onQueryChange(reconstructQuery(filters, query));
    }, [filters]);

    useEffect(() => {
        sessionStorage.getItem("tailPaused");
        {
            theme = sessionStorage.getItem("theme");
        }
    });
    useEffect(() => {
        setTimeUnit(TimeUnits.indexOf(fromTimeRecord.unit));
        setRelativeCount(fromTimeRecord.interval);
    }, []);

    function handleChange(unit, qty, callback, callbackTo) {
        let val = unit.slice(4).split(" ");
        setRelativeCount(parseInt(val[1]));
        setTimeUnit(unit);

        let unitValue = val[2];
        let Tochange = {
            type: "Relative",
            unit: unitValue,
            interval: 0,
            epoch: DateTime.now().toMillis(),
        };
        let change = {
            type: "Relative",
            unit: unitValue,
            interval: parseInt(val[1]),
            epoch: DateTime.now()
                .minus(Duration.fromObject({ [unitValue]: parseInt(val[1]) }))
                .toMillis(),
        };
        callback(change);
        callbackTo(Tochange);
    }

    const reset = () => {
        filters.map((item) => dispatch(onFiltersRemoved(item)));
        sessionStorage.setItem("reset", "true");
        setSelectedTime("Last 5 minutes");

        onQueryChange("");
        console.log(fromTimeRecord);
        onFromDateChange({
            epoch: DateTime.now().minus({ minutes: 5 }).toMillis(),
            interval: 5,
            type: "Relative",
            unit: "minutes",
        });
        onToDateChange({
            epoch: DateTime.now().toMillis(),
            interval: 5,
            type: "Relative",
            unit: "minutes",
        });

        dispatch(
            fetchLogs({
                auth: {
                    uid: userId,
                    "auth-token": token,
                    email: emailId,
                },
                clusterId: selectedCluster,
                start: fromTimeRecord.epoch,
                end: toTimeRecord.epoch,
                query: "",
                limit: 1000,
            }),
        );
        onStopTailing();
        count = 1;
    };
    useEffect(() => {
        if (sessionStorage.getItem("label") == "true") {
            onSearchClicked();
            sessionStorage.setItem("label", "false");
        }
    });

    const fromChange = () => {
        let x = new Date(absFrom);
        let y = x.getTime();
        console.log(y);
        let change = {
            ...fromTimeRecord,
            type: "absolute",
            epoch: y,
        };
        onFromDateChange(change);
    };
    const ToChange = () => {
        let x = new Date(absTo);
        let y = x.getTime();
        let change = {
            ...toTimeRecord,
            type: "absolute",
            epoch: y,
        };
        onToDateChange(change);
    };

    const getLatencyTime = () => {
        if (
            sessionStorage.getItem("startTime") &&
            sessionStorage.getItem("endTime") &&
            sessionStorage.getItem("reset") == "false" &&
            sessionStorage.getItem("search") != "clicked"
        ) {
            var start = parseInt(sessionStorage.getItem("startTime"));
            var end = parseInt(sessionStorage.getItem("endTime"));
            // 2022-08-03T14:28:36.248+05:30
            var result = end - start;
            let diff = new Date(result);

            if (diff.getSeconds() < 60) {
                return `${diff.getSeconds() + 1}s`;
            } else return `${diff.getMinutes()}m`;
        } else return `0.0 s`;
    };

    return (
        <>
            <Box
                className={theme == "dark" ? "box-form-dark" : "box-form"}
                flexGrow={1}
                marginTop={"0px"}
                marginRight={2}
            >
                {/* {console.log("👍👍👍👍",sessionStorage.getItem("label") )}
                {count==2?reset():''} */}
                <Grid container spacing={1}>
                    <Grid item padding={"0.5rem"} sm={2}>
                        <Box>
                            <SideLabelPanel
                                filters={filters}
                                labels={labels}
                                onFilterClick={(filter) => {
                                    dispatch(onFiltersAdded(filter));
                                }}
                                FirstClick={(count) => {
                                    count >= 2 ? reset() : "";
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={8} md={8} lg={8} xl={8}>
                        <CssTextField
                            size={"small"}
                            fullWidth
                            multiline={true}
                            style={{ backgroundColor: theme == "dark" ? "#DCDCDC" : "#FFF" }}
                            className="search-query-field"
                            // ,border:"1px solid #41e799",borderTop:"1.75px solid #41e799", borderRadius:1, borderTop:"1.25px solid #41e799",borderTopLeftRadius:6,borderTopRightRadius:6
                            // sx={{ marginLeft:'0.5rem', color:'#000' }}
                            maxRows={4}
                            value={query}
                            placeholder={"Search query"}
                            onChange={(event) => {
                                console.log("Query", event.target.value);
                                if (event.target.value == "") {
                                    filters.map((item) => dispatch(onFiltersRemoved(item)));
                                    sessionStorage.setItem("reset", "true");
                                    setSelectedTime("Last 5 minutes");
                                } else onQueryChange(event.target.value);
                            }}
                            inputProps={{
                                style: { fontFamily: "Poppins", color: "black", fontSize: "12px" },
                                shrink: false,
                            }}
                        />
                    </Grid>

                    <Grid
                        className="latency-time-grid"
                        style={{
                            backgroundColor: theme == "dark" ? "#DCDCDC" : "#FFF",
                            zIndex: 10,
                        }}
                    >
                        <Typography style={{ color: theme == "dark" ? "#3A3A3A" : "#000" }}>
                            <AccessTimeIcon fontSize="13" sx={{ marginTop: "-2px" }} />
                            &nbsp;{getLatencyTime()}
                        </Typography>
                    </Grid>
                    <Grid className="clear-all-button">
                        <Button
                            size={"small"}
                            variant={"contained"}
                            sx={{
                                // marginRight: "0.5rem",
                                // border: "1px solid #DD0C4E ",
                                borderWidth: "1.5px",
                                borderStyle: "solid",
                                borderImage:
                                    "linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);",
                                borderImageSlice: 1,
                                backgroundColor: theme == "dark" ? "#DCDCDC" : "#FFF",
                                color: "#000",
                                fontFamily: "Poppins",
                                boxShadow: "none",
                                width: "90px",
                                ":hover": {
                                    backgroundColor: "#1C1850",
                                    color: "#FFF",
                                },
                            }}
                            onClick={() => {
                                reset();
                            }}
                            startIcon={<ReplayIcon />}
                        >
                            Reset
                        </Button>
                    </Grid>

                    <Grid item sm={12} md={12} xl={12} lg={12}>
                        <Box display={"flex"} flexGrow={1} justifyContent={"flex-end"}>
                            <Grid className="search-tail-grid">
                                {/* {console.log(timeUnit)} */}
                                {console.log(selectedTime.length)}
                                <FormControl
                                    classes={{ root: classes.customOutline }}
                                    className={
                                        selectedTime.length < 25
                                            ? "form-select-duration"
                                            : "form-select-duration-abs"
                                    }
                                    // style={{ width: selectedTime.length > 25 ? "18rem" : "10rem" , marginTop:'0.3em' }}
                                >
                                    <InputLabel
                                        style={{
                                            color: theme == "dark" ? "#FFF" : "#000",
                                            // marginTop: "-0.3em",
                                        }}
                                    >
                                        Duration
                                    </InputLabel>

                                    <InputLabel
                                        id="select-label"
                                        style={{ color: theme == "dark" ? "#FFF" : "#000" }}
                                    >
                                        {selectedTime}
                                    </InputLabel>
                                    <Select
                                        open={timeChange}
                                        onOpen={() => setTimeChange(true)}
                                        onClose={() => setTimeChange(false)}
                                        style={{
                                            backgroundColor:
                                                theme == "dark" ? "transparent" : "#FFF",
                                            color: theme == "dark" ? "#FFF" : "#000",
                                            // paddingTop:selectedTime.length > 25 ?'-0.5rem':'',
                                            // justifyContent:'center'
                                        }}
                                        size="small"
                                        value={timeUnit}
                                        label={"Duration"}
                                        onChange={(event) => {
                                            console.log(event);
                                            setTimeChange(false);
                                            handleChange(
                                                event.target.value,
                                                relativeCount,
                                                (change) => {
                                                    onFromDateChange(change);
                                                },
                                                (changeTo) => {
                                                    onToDateChange(changeTo);
                                                },
                                            );
                                        }}
                                    >
                                        <div className="group-div-select">
                                            <div className="group-relative">
                                                <optgroup label="Relative"> </optgroup>
                                                {Time.map((value, index) => {
                                                    return (
                                                        <MenuItem
                                                            key={index}
                                                            onClick={() => (
                                                                setTimeChange(false),
                                                                setSelectedTime(value),
                                                                handleChange(
                                                                    value,
                                                                    relativeCount,

                                                                    (change) => {
                                                                        console.log(
                                                                            change,
                                                                            DateTime.now()
                                                                                .minus({
                                                                                    minutes: 5,
                                                                                })
                                                                                .toMillis(),
                                                                        ),
                                                                            //                                                                         interval: 5
                                                                            // type: "Relative"
                                                                            // unit: "minutes"

                                                                            onFromDateChange(
                                                                                change,
                                                                            );
                                                                    },
                                                                    (changeTo) => {
                                                                        onToDateChange(changeTo);
                                                                    },
                                                                )
                                                            )}
                                                            value={value}
                                                        >
                                                            {value}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </div>

                                            <div className="group-absolute">
                                                <optgroup label="Absolute"> </optgroup>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DateTimePicker
                                                        label="From"
                                                        value={
                                                            absFrom
                                                            //     (fromTimeRecord.epoch!=undefined)?
                                                            //     DateTime.fromMillis(
                                                            //     fromTimeRecord.epoch,
                                                            // ):''
                                                        }
                                                        InputProps={{
                                                            sx: {
                                                                "&.MuiOutlinedInput-root": {
                                                                    marginTop: "20px",
                                                                    marginBottom: "5px",
                                                                    marginLeft: "3px",
                                                                },
                                                            },
                                                        }}
                                                        onChange={(newValue) => {
                                                            console.log(newValue);
                                                            let x = new Date(
                                                                "Wed Aug 03 2022 01:55:00 GMT+0530 (India Standard Time)",
                                                            );
                                                            let y = x.getTime();
                                                            console.log(x, y);
                                                            setAbsFrom(newValue);
                                                            //  fromChange();
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                fullWidth
                                                                size={"small"}
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DateTimePicker
                                                        className="date-picker"
                                                        InputProps={{
                                                            sx: {
                                                                "&.MuiOutlinedInput-root": {
                                                                    marginTop: "20px",
                                                                    marginBottom: "15px",
                                                                    marginLeft: "3px",
                                                                },
                                                            },
                                                        }}
                                                        sx={{ marginTop: "1em" }}
                                                        label="To"
                                                        value={absTo}
                                                        onChange={(newValue) => {
                                                            setAbsTo(newValue);
                                                            // ToChange()
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                fullWidth
                                                                size={"small"}
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>

                                                <Button
                                                    onClick={() => (
                                                        setTimeChange(false),
                                                        fromChange(),
                                                        ToChange(),
                                                        setSelectedTime(
                                                            `${new Date(
                                                                absFrom,
                                                            ).getDay()}/${new Date(
                                                                absFrom,
                                                            ).getMonth()}/${new Date(
                                                                absFrom,
                                                            ).getFullYear()} ${new Date(
                                                                absFrom,
                                                            ).getHours()}:${new Date(
                                                                absFrom,
                                                            ).getMinutes()}-
                                                            ${new Date(absTo).getDay()}/${new Date(
                                                                absTo,
                                                            ).getMonth()}/${new Date(
                                                                absTo,
                                                            ).getFullYear()} ${new Date(
                                                                absTo,
                                                            ).getHours()}:${new Date(
                                                                absTo,
                                                            ).getMinutes()}`,
                                                        )
                                                    )}
                                                >
                                                    Apply time range
                                                </Button>
                                            </div>
                                        </div>
                                    </Select>
                                </FormControl>

                                <Button
                                    size={"small"}
                                    variant={"contained"}
                                    sx={{
                                        marginRight: 1,
                                        marginLeft: 1,
                                        width: "100px",
                                        backgroundColor: "#DD0C4E",
                                        ":hover": {
                                            backgroundColor: "#1C1850",
                                            color: "#FFF",
                                        },
                                        // backgroundImage:
                                        //     "linear-gradient( to top right, #29C2B6, #2CD97B )",
                                    }}
                                    startIcon={<SearchSharpIcon />}
                                    onClick={() => {
                                        sessionStorage.setItem(
                                            "startTime",
                                            DateTime.now().toMillis(),
                                        );
                                        sessionStorage.setItem("search", "clicked");
                                        onSearchClicked();
                                    }}
                                >
                                    Search
                                </Button>

                                {tailing === undefined || tailing === false ? (
                                    <Button
                                        className="button-outline"
                                        size={"small"}
                                        variant={"contained"}
                                        sx={{
                                            marginRight: "0.5rem",
                                            width: "100px",
                                            // border: "1px solid #DD0C4E ",
                                            // backgroundColor: "#FFF",
                                            borderWidth: "1.5px",
                                            borderStyle: "solid",
                                            borderImage:
                                                "linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);",
                                            borderImageSlice: 1,
                                            color: "#000",
                                            fontFamily: "Poppins",
                                            boxShadow: "none",
                                            backgroundColor: theme == "dark" ? "#CDCDCD" : "#FFF",
                                            ":hover": {
                                                backgroundColor: "#1C1850",
                                                color: "#FFF",
                                            },
                                            // backgroundImage:
                                            //     "linear-gradient( to top right, #29C2B6, #2CD97B )",
                                        }}
                                        onClick={() => {
                                            onTailing();
                                        }}
                                        startIcon={<PlayArrowSharpIcon />}
                                    >
                                        Tail
                                    </Button>
                                ) : (
                                    <>
                                        {/* {sessionStorage.getItem("tailPaused")} */}
                                        {/* { sessionStorage.getItem("tailPaused")=="connected" ||sessionStorage.getItem("tailPaused")=="resumed" ?   */}

                                        <Button
                                            size={"small"}
                                            variant={"contained"}
                                            sx={{
                                                marginRight: "0.5rem",
                                                // width:'100px',
                                                backgroundColor:
                                                    theme == "dark" ? "#CDCDCD" : "#FFF",
                                                color: "#000",
                                                borderWidth: "1.5px",
                                                borderStyle: "solid",
                                                borderImage:
                                                    "linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);",
                                                borderImageSlice: 1,
                                                ":hover": {
                                                    backgroundColor: "#1C1850",
                                                    color: "#FFF",
                                                },
                                            }}
                                            onClick={() => {
                                                onpauseTailing();
                                            }}
                                            startIcon={
                                                sessionStorage.getItem("tailPaused") != "paused" ? (
                                                    <PauseIcon />
                                                ) : (
                                                    <PlayArrowSharpIcon />
                                                )
                                            }
                                        >
                                            {sessionStorage.getItem("tailPaused") != "paused"
                                                ? "Pause"
                                                : "Resume"}
                                        </Button>

                                        {/* // : 
                                        
                                         <Button
                                        //     size={"small"}
                                        //     variant={"contained"}
                                        //     sx={{
                                        //         marginRight: "0.5rem",
                                        //         backgroundImage:
                                        //             "linear-gradient( to top right, #29C2B6, #2CD97B )",
                                        //     }}
                                        //     onClick={() => {
                                        //         onResumeTailing();

                                        //     }}
                                        //     startIcon={<PlayArrowSharp />}
                                        // >
                                        //    Resume Tail
                                        // </Button>
                                        
                                        // } */}

                                        <Button
                                            size={"small"}
                                            variant={"contained"}
                                            sx={{
                                                marginRight: "0.5rem",
                                                // width:'100px',
                                                backgroundColor:
                                                    theme == "dark" ? "#CDCDCD" : "#FFF",
                                                color: "#000",
                                                borderWidth: "1.5px",
                                                borderStyle: "solid",
                                                borderImage:
                                                    "linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);",
                                                borderImageSlice: 1,
                                                ":hover": {
                                                    backgroundColor: "#1C1850",
                                                    color: "#FFF",
                                                },
                                            }}
                                            onClick={() => {
                                                onStopTailing();
                                            }}
                                            startIcon={<StopSharpIcon />}
                                        >
                                            Stop Tail
                                        </Button>
                                    </>
                                )}
                            </Grid>

                            <QueryListModal
                                open={openQueryRepoDrawer}
                                queries={queries}
                                onOpen={(userQuery) => {
                                    dispatch(onOpenQuery(userQuery));
                                }}
                                onDelete={(query2) => {
                                    dispatch(
                                        deleteQuery({
                                            auth: {
                                                uid: userId,
                                                email: emailId,
                                                "auth-token": token,
                                            },
                                            userQuery: query2,
                                        }),
                                    );
                                }}
                                history={[]}
                                userId={userId2}
                                onEdit={(queryId, query2, queryName, userId2, shared) => {
                                    let updateRequest = {
                                        queryId: queryId,
                                        query: query2,
                                        queryName: queryName,
                                        userId: userId2,
                                        shared: shared,
                                        tags: "",
                                    };
                                    dispatch(
                                        updateQuery({
                                            auth: {
                                                uid: userId,
                                                email: emailId,
                                                "auth-token": token,
                                            },
                                            userQuery: updateRequest,
                                        }),
                                    );
                                }}
                                onClose={() => {
                                    setOpenQueryRepoDrawer(false);
                                }}
                            />

                            <Grid className="grid-save-filter">
                                <Grid className="grid-query-repo">
                                    <Button
                                        sx={{
                                            color: "#000",
                                            border: "1px solid #DD0C4E ",
                                            backgroundColor: theme == "dark" ? "#DCDCDC" : "#FFF",
                                            color: "#000",
                                            fontFamily: "Poppins",
                                            boxShadow: "none",
                                            borderWidth: "1.5px",
                                                borderStyle: "solid",
                                                borderImage:
                                                    "linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);",
                                                borderImageSlice: 1,
                                            
                                            ":hover": {
                                                backgroundColor:
                                                    "#1C1850",
                                                color: "#FFF" ,
                                            },
                                        }}
                                        size={"small"}
                                        onClick={(event) => {
                                            setOpenQueryRepoDrawer(true);
                                        }}
                                        startIcon={<DataArraySharpIcon />}
                                    >
                                        Query Repo
                                    </Button>
                                </Grid>
                                <Button
                                    variant={"contained"}
                                    sx={{
                                        // marginLeft: "0.5rem",
                                        textTransform: "capitalize",
                                        border: "1px solid #DD0C4E ",
                                        backgroundColor: theme == "dark" ? "#DCDCDC" : "#FFF",
                                        color: "#000",
                                        fontFamily: "Poppins",
                                        boxShadow: "none",
                                        width: "100px",
                                        borderWidth: "1.5px",
                                                borderStyle: "solid",
                                                borderImage:
                                                    "linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);",
                                                borderImageSlice: 1,
                                        ":hover": {
                                            backgroundColor: "#1C1850" ,
                                            color:"#FFF" ,
                                        },
                                    }}
                                    size={"small"}
                                    startIcon={<SaveSharpIcon />}
                                    onClick={(event) => {
                                        setOpenQuerySaveModal(true);
                                    }}
                                >
                                    Save
                                </Button>
                                {/* <LabelForm
                                    labels={labels}
                                    filters={filters}
                                    onFilterAdded={(filter) => {
                                        dispatch(onFiltersAdded(filter));
                                    }}
                                    onFilterDeleted={(filter) => {
                                        dispatch(onFiltersRemoved(filter));
                                    }}
                                /> */}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <QuerySaveModal
                userId={userId2}
                open={saveQueryModal}
                query={query}
                filters={filters}
                onSave={(queryId, query, queryName, userId2, shared) => {
                    dispatch(
                        addQuery({
                            auth: { uid: userId, email: emailId, "auth-token": token },
                            userQuery: {
                                query: query,
                                queryName: queryName,
                                userId: userId2,
                                shared: shared,
                                tags: "",
                            },
                        }),
                    );
                }}
                onClose={() => {
                    setOpenQuerySaveModal(false);
                }}
            />
        </>
    );
}
