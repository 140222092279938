import React, { useEffect, useState, useContext } from "react";
import {
    Table,
    Input,
    InputNumber,
    Dropdown,
    Popconfirm,
    Switch,
    Menu,
    Form,
    Typography,
    Modal,
    Tag,
    Button,
    Radio,
    Popover,
    List,
} from "antd";
import {
    DownOutlined,
    PlusOutlined,
    MinusOutlined,
    CopyOutlined,
    LinkOutlined,
    SendOutlined,
} from "@ant-design/icons";

import MenuItem from "antd/lib/menu/MenuItem";
import "./Manageusers.scss";
import { UIButton } from "../../../../components/lib";
import DashboardActions from "../../../redux/dashboardActions";
import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
import _ from "lodash";
import VERBIAGE from "../../../../../../utils/VERBIAGE";
import Dialog from "../../../../components/lib/dialogs/SimpleDialog";
import moment from "moment";
import AuthContext from "../../../../authentication/views/components/Auth";
import AuthenticationActions from "../../../../authentication/redux/AuthenticationActions";
import Footer from "../../../../authentication/views/common/Footer";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
let auth = getAuth();
let theme=sessionStorage.getItem("theme");
let newData = [];

const ManageUsers = ({
    getUsersList,
    listOfUsers,
    sendInvite,
    dashboard,
    resetFetched,
    getInvitedList,
    listOfInvitedUsers,
    listOfCompanyInvites,
    getCompanyInvitedList,
    authentication,
    sendCompanyInvite,
    getCredentials,
}) => {
    let { token, emailId, userId } = useContext(AuthContext);
    console.log(emailId);
    let header = {
        token,
        userId,
        emailId,
    };
    let invitedData = [],
        CompanyinvitedData = [];
    let navigate = useNavigate();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState("");
    const [modal, setIsModalVisible] = useState(false);
    const [editval, setEdit] = useState(false);
    const [active, setActive] = useState(true);
    const [role, setRole] = useState("");
    const [value, setValue] = useState("admin");
    const [email, setEmail] = useState("");
    const [domain, setDomain] = useState("");
    const [formValues, setFormValues] = useState([{ emailId: "", userRole: "" }]);
    
    const [error, setError] = useState(false);
    const [themeloading,setThemeloading] = useState(true)
    useEffect(
        () => (
            
            (theme==sessionStorage.getItem("theme"))?
            
                ( 
                    // (themeloading)?( setTimeout(() => setThemeloading(false), 100)):'',
                theme = sessionStorage.getItem("theme"),setThemeloading(false))
            :
           ( theme = sessionStorage.getItem("theme"),
           setThemeloading(false))
            
        ),
    );
    useEffect(() => getUsersList(header), []);

    useEffect(() => (getInvitedList(header), getCompanyInvitedList(header)), []);

    useEffect(() => {
        const interval = setInterval(() => {
            console.log("Manageusers-- 10 minutes");
            getLoadingDetails();
            sessionStorage.removeItem("expired");
        }, 600000);
        return () => clearInterval(interval);
    });
    // for radio button
    const onChange = (e) => {
        setValue(e.target.value);
    };

    // to close pop-up after confirmation
    const closeModal = () => {
        getUsersList(header);
        resetFetched();
        setIsModalVisible(false);
        getInvitedList(header);
        getCompanyInvitedList(header);
        setFormValues([{ emailId: "", userRole: "" }]);
        setError(false);
        setDomain("");
    };

    const handleOk = () => {
        setIsModalVisible(false);
        getInvitedList(header);
        getCompanyInvitedList(header);
        setFormValues([{ emailId: "", userRole: "" }]);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const addFormFields = () => {
        setFormValues([...formValues, { emailId: "", userRole: "" }]);
    };

    const removeFormFields = (i) => {
        if (formValues.length > 1) {
            let newFormValues = [...formValues];
            newFormValues.splice(i, 1);
            setFormValues(newFormValues);
        } else {
            setFormValues([{ emailId: "", userRole: "" }]);
        }
    };

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        const inputNode =
            dataIndex === "role" ? (
                <Dropdown
                    overlay={
                        <Menu>
                            <MenuItem onClick={() => (setRole("Developer"), setEdit(true))} key={1}>
                                Developer
                            </MenuItem>
                            <MenuItem onClick={() => (setRole("Admin"), setEdit(true))} key={2}>
                                Admin
                            </MenuItem>
                        </Menu>
                    }
                >
                    <a className="role-selection-drop-down" onClick={(e) => e.preventDefault()}>
                        {role == "" ? "Choose a role" : role}
                        <DownOutlined />
                    </a>
                </Dropdown>
            ) : (
                <Switch checked={active} onChange={() => setActive(!active)} />
            );

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const isEditing = (record) => record.key === editingKey;

    const cancel = () => {
        setEditingKey("");
    };

    const columns = [
        { title: "Name", key: "username", dataIndex: "username", editable: false },

        { title: "Email", key: "emailid", dataIndex: "emailid", editable: false },
        {
            title: "Contact Number",
            key: "contactno",
            dataIndex: "contactno",
            editable: true,
        },
        { title: "Date joined", key: "createdat", dataIndex: "createdat", editable: false },

        {
            title: "Role",
            key: "userrole",
            dataIndex: "userrole",
            editable: true,
        },
        {
            title: "Status",
            key: "active",
            dataIndex: "active",
            editable: true,
        },
    ];
    const columns_inviteDetails = [
        // { title: "Name", key: "username", dataIndex: "username", editable: false },
        { title: "Email", key: "emailid", dataIndex: "emailid", editable: false },

        {
            title: "Invite url",
            key: "invitetoken",
            dataIndex: "invitetoken",
            render: (inviteToken) => {
                return (
                    <Tag
                        className="tag-invite-token"
                        key={inviteToken}
                        onClick={() => console.log()}
                        style={{backgroundColor:'transparent', color:theme=="dark"?'#fff':'#000'}}
                    >
                        {/* <Popover
                            className="popover-invite-token"
                            content={`https://spike.episilia.com/joincompany/${inviteToken}`}
                        > */}
                        <span>{`https://spike.episilia.net/join/${inviteToken}`}</span>
                        <Button
                            className="button-invite-token"
                            type="primary"
                            style={{backgroundColor:'transparent', color:theme=="dark"?'#fff':'#000'}}
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    `https://spike.episilia.net/join/${inviteToken}`,
                                );
                            }}
                        >
                            <CopyOutlined />
                        </Button>
                        {/* </Popover> */}
                    </Tag>
                );
            },
        },

        {
            title: "User role",
            key: "userrole",
            dataIndex: "userrole",
        },
        {
            title: "Status",
            key: "status",
            dataIndex: "status",
        },
        {
            title: "Valid till",
            key: "validtill",
            dataIndex: "validtill",
        },
    ];
    const columns_orgDetails = [
        {
            title: "Domain",
            key: "validdomains",
            dataIndex: "validdomains",
        },
        {
            title: "Invitation URL",
            key: "invitetoken",
            dataIndex: "invitetoken",
            render: (inviteToken) => {
                return (
                    <Tag
                        className="tag-invite-token"
                        key={inviteToken}
                        style={{backgroundColor:'transparent', color:theme=="dark"?'#fff':'#000'}}
                        onClick={() => console.log()}
                    >
                        {/* <Popover
                            className="popover-invite-token"
                            content={`https://spike.episilia.com/joincompany/${inviteToken}`}
                        > */}
                        <span >{`https://spike.episilia.net/joincompany/${inviteToken}`}</span>
                        <Button
                            className="button-invite-token"
                            type="primary"
                            style={{backgroundColor:'transparent', color:theme=="dark"?'#fff':'#000'}}
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    `https://spike.episilia.net/joincompany/${inviteToken}`,
                                );
                            }}
                        >
                            <CopyOutlined />
                        </Button>
                        {/* </Popover> */}
                    </Tag>
                );
            },
        },

        {
            title: "user role",
            key: "userrole",
            dataIndex: "userrole",
        },
        {
            title: "Status",
            key: "status",
            dataIndex: "status",
        },

        {
            title: "Valid till",
            key: "validtill",
            dataIndex: "validtill",
        },
        {
            title: "Accepted Count",
            key: "acceptedcount",
            dataIndex: "acceptedcount",
            editable: false,
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                // inputType: col.dataIndex === "age" ? "number" : "text",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const dataSource = () => {
        let obj = [];

        !_.isEmpty(listOfUsers) &&
            listOfUsers.map((item, index) => {
                (obj["username"] = item.username ? item.username : "-NA-"),
                    (obj["userid"] = item.userid),
                    (obj["contactno"] = item.contactno==''?"-NA-":item.contactno),
                    (obj["userrole"] = item.userrole),
                    (obj["emailid"] = item.emailid.toLowerCase()),
                    (obj["createdat"] = moment(item.createdat).format(VERBIAGE.DATE.FORMAT)),
                    (obj["active"] = item.active == "Y" ? "Active" : "Inactive"),
                    (obj["index"] = index);
                data.push({ ...obj });
            });

        newData = data.filter((v, i, a) => a.findIndex((v2) => v2.userid === v.userid) === i);
        
    };
    const dataSourceInvite = () => {
        let obj2 = [];

        !_.isEmpty(listOfInvitedUsers)
            ? listOfInvitedUsers.map((item, index) => {
                  item.emailid.includes(".") && item.emailid.includes("@")
                      ? ((obj2["userrole"] = item.userrole),
                        (obj2["createdby"] = item.createdby),
                        (obj2["invitetoken"] = item.invitetoken),
                        (obj2["status"] = item.status),
                        (obj2["validtill"] = moment(item.validtill).format(VERBIAGE.DATE.FORMAT)),
                        (obj2["emailid"] = item.emailid.toLowerCase()),
                        (obj2["createdat"] = moment(item.createdat).format(VERBIAGE.DATE.FORMAT)),
                        // (obj2["active"] = item.active == "Y" ? "Invited" : "Rejected"),
                        invitedData.push({ ...obj2 }))
                      : "";
              })
            : "";
    };
    const dataSourceCompanyInvite = () => {
        let obj2 = [];

        !_.isEmpty(listOfCompanyInvites)
            ? listOfCompanyInvites.map((item, index) => {
                  (obj2["acceptedcount"] = item.acceptedcount),
                      (obj2["createdat"] = moment(item.createdat).format(VERBIAGE.DATE.FORMAT)),
                      (obj2["createdby"] = item.createdby),
                      (obj2["invitetoken"] = item.invitetoken),
                      //onClick={() => {navigator.clipboard.writeText(this.state.textToCopy)}}
                      //   `https://spike.episilia.com/joincompany/${item.invitetoken}`),
                      (obj2["lastacceptedat"] = moment(item.lastacceptedat).format(
                          VERBIAGE.DATE.FORMAT,
                      )),
                      (obj2["userrole"] = item.userrole),
                      (obj2["status"] = item.status),
                      (obj2["validdomains"] = item.validdomains),
                      (obj2["validtill"] = moment(item.validtill).format(VERBIAGE.DATE.FORMAT)),
                      CompanyinvitedData.push({ ...obj2 });
              })
            : "";
    };

    let handleChange = (i, e, key) => {
        let newFormValues = [...formValues];
        if (key == "emailId") {
            //  if(e.target.value.includes('.')&&e.target.values.includes('@'))
            newFormValues[i][key] = e.target.value;
        } else newFormValues[i][key] = e.target.value;

        setFormValues(newFormValues);
    };

    const getUrl = () => {
        let data = {
            companyId: authentication.companyDetails.companyId,
            userRole: "devops",
            validDomain: domain,
        };
        sendCompanyInvite(data);
    };
    const submit = () => {
        // if(dashboard.inviteSent)

        {
            formValues.map((item) =>
                item.emailId.includes(".") && item.emailId.includes("@")
                    ? item.userRole == ""
                        ? ((item.userRole = "devops"), sendInvite(item))
                        : sendInvite(item)
                    : setError(true),
            );
        }
    };

    useEffect(() => dataSourceInvite(), [listOfInvitedUsers]);

    const getLoadingDetails = () => {
        auth.onAuthStateChanged(async (user) => {
            if (sessionStorage.getItem("logout") == "false") {
                token = await user.getIdToken();
                emailId = user.email;
                userId = user.uid;

                header = {
                    token,
                    emailId,
                    userId,
                };
                getUsersList(header);
                getInvitedList(header);
                getCompanyInvitedList(header);
                getCredentials(header);
            } else {
                sessionStorage.clear();
                localStorage.clear();
                auth.signOut();
                navigate("/");
                window.location.reload();
            }
        });

        if (sessionStorage.getItem("expired") == "true") {
            sessionStorage.removeItem("expired");
        }
    };
    useEffect(()=>(theme = sessionStorage.getItem("theme")))
    return (
        <div style={{backgroundColor:theme=="dark"?"#282828":"transparent",marginTop:'-7em'}}>
            
       {!themeloading  && <div className="container-manage-users" >
            {/* <h2>Manage Users</h2> */}
            {/* {getLoadingDetails()} */}
            {dataSource()}
            {token == null || sessionStorage.getItem("expired") == "true" || token == ""
                ? getLoadingDetails()
                : ""}

            {modal ? (
                <div className="modal-adduser" >
                    <Modal
                        // style={{backgroundColor:theme=="dark"?"#000":"transparent"}}
                        bodyStyle={{
                            backgroundColor:theme=="dark"?"#282828":"#fff",
                        }}
                        width={600}
                        title="Invite User"
                        visible={modal}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        className="modal-adduser"
                        footer={null}
                        onClose={handleCancel}
                    >
                        <div className="company-invite" >
                            <h5 className="label-email" style={{color:theme=="dark"?"#fff":"#3a3a3a"}}>Get company invitation</h5>
                            <Input
                                className="send-invite-input"
                                style={{backgroundColor:theme=="dark"?"#404040":"transparent",color:theme=="dark"?"#000":"#3a3a3a"}}
                                placeholder={VERBIAGE.PLACEHOLDERS.COMPANY_DOMAIN}
                                value={domain}
                                onChange={(e) => setDomain(e.target.value)}
                                // value={item.emailId}
                                // onChange={(e) => handleChange(index, e, "emailId")}
                            />
                        </div>
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={getUrl}
                            className="manage-user-getinvite-button"
                            style={{ marginBottom: 10 }}
                        >
                            <LinkOutlined /> Generate Invite Link
                        </Button>
                        <div className="profile-dts-container__dts-tbl__line-break" style={{color:theme=="dark"?"#DCDCDC":"#3a3a3a", borderBottom: "1px solid #DCDCDC"}}></div>
                        <h6 className="or-text" style={{ textAlign: "center",color:theme=="dark"?"#fff":"#3a3a3a" }}>
                            OR
                        </h6>
                        <h5 className="label-email" style={{color:theme=="dark"?"#fff":"#3a3a3a"}}>Send Invitation to E-mail</h5>
                        <h6 className="label-email" style={{color:theme=="dark"?"#fff":"#3a3a3a"}}>Email</h6>
                        <h6 className="label-role" style={{color:theme=="dark"?"#fff":"#3a3a3a"}}>Select a role</h6>

                        {formValues.map((item, index) => (
                            <div className="send-invite-div">
                                <Input
                                    className="send-invite-input"
                                    style={{backgroundColor:theme=="dark"?"#404040":"transparent",color:theme=="dark"?"#000":"#3a3a3a"}}
                                    placeholder={VERBIAGE.PLACEHOLDERS.EMAIL}
                                    value={item.emailId}
                                    onChange={(e) => handleChange(index, e, "emailId")}
                                    required
                                />

                                <Radio.Group
                                    className="send-invite-radio"
                                    //</div>
                                    // onChange={onChange} value={value}
                                    value={item.userRole}
                                    onChange={(e) => handleChange(index, e, "userRole")}
                                >
                                    <Radio value="admin" style={{color:theme=="dark"?"#DCDCDC":"#3a3a3a"}}>Admin</Radio>
                                    <Radio value="devops" style={{color:theme=="dark"?"#DCDCDC":"#3a3a3a"}}>Devops</Radio>
                                </Radio.Group>
                                <Button
                                    className="manage-user-button-remove"
                                    onClick={() => removeFormFields(index)}
                                    style={{backgroundColor:'transparent'}}
                                >
                                    {" "}
                                    Remove
                                </Button>
                            </div>
                        ))}
                        <Button style={{color:theme=="dark"?"#889BFA":"#3f25ff"}}
                            className="manage-user-button-addrow"
                            onClick={() => addFormFields()}
                        >
                            + Add another
                        </Button>
                        <div />

                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={submit}
                            className="manage-user-button"
                            disabled={formValues.emailId == ""}
                            
                        >
                            <SendOutlined /> Send Invite
                        </Button>
                    </Modal>
                </div>
            ) : (
                <div />
            )}

            {authentication.userRole == "admin" && (
                <div id="Adduser">
                    <Button
                        onClick={() => setIsModalVisible(true)}
                        className="manage-user-button-add"
                    >
                        <span>
                            <strong style={{ fontSize: 20 }}>+</strong> Send invite
                        </span>
                    </Button>
                </div>
            )}
            <div className="manage-users-table" style={{color:theme=="dark"?"#DCDCDC":"#3a3a3a"}}>
                <h4 style={{color:theme=="dark"?"#DCDCDC":"#3a3a3a"}}>List of Users</h4>
                {/* <Form form={form} component={false}> */}
               
                {!_.isEmpty(data) && window.screen.width > 900 ? (
                    <Table 
                    className={theme=="dark"?'dark-table':''}
                        scroll={{ x: 500 }}
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        // bordered
                        rowKey="emailid"
                        // bordered
                        dataSource={newData}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={{
                            onChange: cancel,
                        }}
                    />
                ) : (
                    <List
                        bordered
                        itemLayout="horizontal"
                        dataSource={newData}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    //   avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                    title={`${item.username} - ${item.emailid}`}
                                    description={`Role- ${item.userrole} , Status- ${item.active}`}
                                />
                            </List.Item>
                        )}
                    />
                )}
                {/* {dashboard.companyInvite_Token != "" ? (
                    <div>
                          <h4>
                             Invitation URL:{" "}
                             {`https://spike.episilia.com/joincompany/${dashboard.companyInvite_Token}`}
                         </h4> 
                    </div>
                ) : (
                    <div />
                )} */}
                {!_.isEmpty(listOfInvitedUsers) && authentication.userRole == "admin" && (
                    <div>
                        {dataSourceInvite()}
                        <h4 style={{color:theme=="dark"?"#DCDCDC":"#3a3a3a"}}>Invited Users</h4>
                        {window.screen.width > 900 ? (
                            <Table
                            className={theme=="dark"?'dark-table':''}
                                scroll={{ x: 500 }}
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                // bordered
                                rowKey="invitetoken"
                                dataSource={invitedData}
                                columns={columns_inviteDetails}
                                rowClassName="editable-row"
                                pagination={{
                                    onChange: cancel,
                                }}
                            />
                        ) : (
                            <List
                                bordered
                                itemLayout="horizontal"
                                dataSource={invitedData}
                                renderItem={(item) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            //   avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                            title={`${item.emailid} - https://spike.episilia.com/join/${item.invitetoken}`}
                                            description={`Role- ${item.userrole} , Status- ${item.status}`}
                                        />
                                    </List.Item>
                                )}
                            />
                        )}
                    </div>
                )}
                {!_.isEmpty(listOfCompanyInvites) && authentication.userRole == "admin" && (
                    <div>
                        {dataSourceCompanyInvite()}
                        <h4 style={{color:theme=="dark"?"#DCDCDC":"#3a3a3a"}}>Company Invitation Details</h4>
                        {window.screen.width > 900 ? (
                            <Table
                            className={theme=="dark"?'dark-table':''}
                                scroll={{ x: 500 }}
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                // bordered
                                rowKey="invitetoken"
                                dataSource={CompanyinvitedData}
                                columns={columns_orgDetails}
                                rowClassName="editable-row"
                                pagination={{
                                    onChange: cancel,
                                }}
                            />
                        ) : (
                            <List
                                bordered
                                itemLayout="horizontal"
                                dataSource={CompanyinvitedData}
                                renderItem={(item) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            style={{ fontSize: "12" }}
                                            //   avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                            title={`${item.validdomains} -Accepted- ${item.acceptedcount} `}
                                            description={`spike.episilia.net/joincompany/${item.invitetoken}`}
                                        />
                                    </List.Item>
                                )}
                            />
                        )}
                    </div>
                )}

                {/* </Form> */}

                {(dashboard.showDialog_users || error) && (
                    <Dialog
                        isModalVisible={dashboard.showDialog_users || error}
                        onClose={closeModal}
                        title={
                            dashboard.inviteSent
                                ? VERBIAGE.TEXT.INVITE_SUCCESS
                                : VERBIAGE.TEXT.INVITE_FAILED
                        }
                        // content={dashboard.inviteSent ? VERBIAGE.TEXT.UPDATE_PROFILE_SUCCESS : VERBIAGE.TEXT.GENERAL_FAILURE_MESSAGE}
                        variant={dashboard.inviteSent ? "success" : "error"}
                        actionText={
                            dashboard.inviteSent
                                ? VERBIAGE.BUTTONS.CONTINUE
                                : VERBIAGE.BUTTONS.TRY_AGAIN
                        }
                        onActionClick={closeModal}
                    />
                )}
                {dashboard.companyInvite && (
                    <Dialog
                        isModalVisible={dashboard.companyInvite}
                        onClose={closeModal}
                        title={
                            dashboard.companyInvite
                                ? "Link has been generated successfully"
                                : VERBIAGE.TEXT.INVITE_FAILED
                        }
                        // content={dashboard.inviteSent ? VERBIAGE.TEXT.UPDATE_PROFILE_SUCCESS : VERBIAGE.TEXT.GENERAL_FAILURE_MESSAGE}
                        variant={dashboard.companyInvite ? "success" : "error"}
                        actionText={
                            dashboard.companyInvite
                                ? VERBIAGE.BUTTONS.CONTINUE
                                : VERBIAGE.BUTTONS.TRY_AGAIN
                        }
                        onActionClick={closeModal}
                    />
                )}
            </div>
            {window.screen.width < 900 ? (
                    <span style={{marginTop:5,fontFamily:'Poppins'}}>For more details, View this website in a desktop browser</span>
                ) : (
                    ""
                )}
            <div
                style={{
                    marginTop:
                        !_.isEmpty(listOfCompanyInvites) && authentication.userRole == "admin"
                            ? 50
                            : 250,
                    marginLeft: window.screen.width < 900 ? "-2rem" : "",
                }}
            >
                
                <Footer />
            </div>
        </div>}
        </div>
    );
};

const mapStateToProps = (state) => ({
    authentication: state.authentication,
    dashboard: state.dashboard,
    listOfUsers: state.dashboard.listOfUsers,
    listOfInvitedUsers: state.dashboard.listOfInvitedUsers,
    listOfCompanyInvites: state.dashboard.listOfCompanyInvites,
});

const mapDispatchToProps = (dispatch) => ({
    getUsersList: (header) => {
        dispatch(DashboardActions.getUsersList(header));
    },
    sendInvite: (credentials) => {
        dispatch(DashboardActions.sendInvite(credentials));
    },
    getInvitedList: (header) => {
        dispatch(DashboardActions.getInvitedList(header));
    },
    getCompanyInvitedList: (header) => {
        dispatch(DashboardActions.getCompanyInvitedList(header));
    },
    resetFetched: () => {
        dispatch(DashboardActions.resetFetched());
    },
    sendCompanyInvite: (header, data) => {
        dispatch(DashboardActions.sendCompanyInvite(header, data));
    },
    getCredentials: (header) => {
        dispatch(AuthenticationActions.getCredentials(header));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);

// ReactDOM.render(<EditableTable />, mountNode);
