//@flow
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Clients } from "../../lib/url";
import { AlertConfigData, Auth } from "../../lib/url";

let initialState = {
    config: [
        {
            companyId: "",
            clusterName: "",
            clusterId: 0,
            targets: [
                {
                    targetId: "sample",
                    type: "SLACK",
                    authKey: "xynnnqor",
                    targetUrl: "/callback?message",
                    title: "alertOnError",
                    description: "error target",
                },
            ],
            rules: [
                {
                    id: "sample_alert",
                    name: "sample",
                    query: `{@version="v1"}|="error"`,
                    keys: ["label"],
                    kvKeys: ["label"],
                    kvValues: ["value"],
                    alertOnCount: 5,
                    alertDuration: "1m",
                    summary: "alert on error for v1",
                    description: "alert on error for v1",
                },
            ],
        },
    ],
};

export const initialiseConfig = createAsyncThunk("alert/cluster/list", async (auth) => {
    try {
        let config = [];
        let clusters = await Clients.CLUSTER.LIST(auth);
        // console.log( clusters);
        let flag = false;
        for (const value of clusters) {
            // console.log(value,sessionStorage.getItem("cluster_name"));
            let alertConfig;

            if (sessionStorage.getItem("cluster_name") == value.cluster_name) {
                // sessionStorage.setItem("clusterId", value.id);
                // console.log(value.id);
                alertConfig = await Clients.ALERTS.FETCH(auth, value.id);
                config.push(alertConfig);
                flag = true;
            }
           
            // else {
            //     alertConfig = await Clients.ALERTS.FETCH(auth, value.id);
            //     config.push(alertConfig);
            // }
            // console.log(sessionStorage.getItem("clusterId") == value.id,sessionStorage.getItem("clusterId") , value.id)
            //     if( sessionStorage.getItem("clusterId") == null)
            //    { alertConfig= await Clients.ALERTS.FETCH(auth, value.id)
            //     config.push(alertConfig)}
            //     else if(sessionStorage.getItem("clusterId") == value.id) {
            //         alertConfig= await Clients.ALERTS.FETCH(auth, value.id)
            //         config.push(alertConfig)
            //     }
        }
        if (!flag) {
            alertConfig = await Clients.ALERTS.FETCH(auth, value.id);
            config.push(alertConfig);
        }
        return config;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const updateConfig = createAsyncThunk(
    "alert/config/update",
    async ({ auth, alertConfig }, thunkAPI) => {
        try {
            console.log(alertConfig);
            let updateResponse = await Clients.ALERTS.UPDATE(auth, alertConfig);
            return thunkAPI.dispatch(initialiseConfig(auth));
        } catch (e) {
            thunkAPI.rejectWithValue(e);
        }
    },
);

export const alertConfigDataSelector = (state) => state.alert.config;

const alertSlice = createSlice({
    name: "alert",
    initialState: initialState,
    reducers: {
        onModifyKeyInRule: (state, action) => {
            const { alertRuleId, keyId, key } = action.payload;
            if (alertRuleId !== -1 && keyId !== -1 && key !== undefined) {
                let rule = state.alertConfigData.rules[alertRuleId];
                rule.keys[keyId] = key;
                state.alertConfigData.rules[alertRuleId] = rule;
            }
        },
        onModifyKVInRule: (state, action) => {
            const { alertRuleId, kvKeyId, kvKey, kvValue } = action.payload;
            if (alertRuleId >= 0 && kvKeyId >= 0) {
                let rule = state.alertConfigData.rules[alertRuleId];
                rule.kvKeys[kvKeyId] = kvKey;
                rule.kvValues[kvKeyId] = kvValue;
                state.alertConfigData.rules[alertRuleId] = rule;
            }
        },
        onKeyRemoved: (state, action) => {
            const { alertRuleId, keyId, key } = action.payload;
            if (alertRuleId >= 0 && keyId >= 0) {
                let rule = state.alertConfigData.rules[alertRuleId];
                rule.keys = rule.keys.filter((value, index) => {
                    return value !== key && keyId === index;
                });
                state.alertConfigData.rules[alertRuleId] = rule;
            }
        },
        onKeyAdded: (state, action) => {
            const { alertRuleId, key } = action.payload;
            if (alertRuleId >= 0 && key !== undefined && key.length > 0) {
                let rule = state.alertConfigData.rules[alertRuleId];
                rule.keys.push(key);
                state.alertConfigData.rules[alertRuleId] = rule;
            }
        },
        onKvRemoved: (state, action) => {
            const { alertRuleId, kvKey, kvValue, kvPairIndex } = action.payload;
            if (alertRuleId >= 0 && kvPairIndex >= 0) {
                let rule = state.alertConfigData.rules[alertRuleId];
                rule.kvKeys = rule.kvKeys.filter((value, index) => {
                    return value !== kvKey;
                });
                rule.kvValues = rule.kvValues.filter((value, index) => {
                    return value !== kvValue;
                });
                if (rule.kvKeys.length === rule.kvValues.length)
                    state.alertConfigData.rules[alertRuleId] = rule;
            }
        },
        onKvAdded: (state, action) => {
            const { alertRuleId, key, value } = action.payload;
            if (alertRuleId >= 0 && key !== undefined && value !== undefined) {
                let rule = state.alertConfigData.rules[alertRuleId];
                rule.kvKeys.push(key);
                rule.kvValues.push(value);
                state.alertConfigData.rules[alertRuleId] = rule;
            }
        },
        onTargetAdd: (state, action) => {
            if (action.payload !== undefined) {
                state.alertConfigData.targets.push(action.payload);
            }
        },
        onRuleAdd: (state, action) => {
            if (action.payload !== undefined) {
                state.alertConfigData.rules.push(action.payload);
            }
        },
        onRuleRemoved: (state, action) => {
            if (action.payload !== undefined && "id" in action.payload) {
                state.alertConfigData.rules = state.alertConfigData.rules.filter((value) => {
                    return value.id !== action.payload.id;
                });
            }
        },
        onTargetRemoved: (state, action) => {
            if (action.payload !== undefined && "targetId" in action.payload) {
                state.alertConfigData.targets = state.alertConfigData.targets.filter((value) => {
                    return value.targetId !== action.payload.targetId;
                });
            }
        },
        onRuleModified: (state, action) => {
            const { alertRuleId, alertRule } = action.payload;
            if (alertRuleId >= 0 && alertRule !== undefined) {
                state.alertConfigData.rules[alertRuleId] = alertRule;
            }
        },
        onTargetModified: (state, action) => {
            const { targetId, alertTarget } = action.payload;
            if (targetId >= 0 && alertTarget !== undefined) {
                state.alertConfigData.targets[targetId] = alertTarget;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(initialiseConfig.fulfilled, (state, action) => {
            // console.log(action.payload);
            if (action.payload === undefined) return;
            if (Array.isArray(action.payload)) {
                let alertData = [];
                action.payload.forEach((alert) => {
                    if (`alertData` in alert) {
                        alertData.push(JSON.parse(alert.alertData));
                    }
                });
                state.config = alertData;
            }
        });
        builder.addCase(initialiseConfig.rejected, (state, action) => {
            console.log(action.payload);
        });
    },
});

export const {
    onModifyKeyInRule,
    onModifyKVInRule,
    onKeyRemoved,
    onKeyAdded,
    onKvRemoved,
    onKvAdded,
    onTargetAdd,
    onRuleAdd,
    onRuleRemoved,
    onTargetRemoved,
    onRuleModified,
    onTargetModified,
} = alertSlice.actions;

export default alertSlice.reducer;
