import React, { useEffect, useContext } from "react";
// import { withRouter } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import Dialog from "../../../components/lib/dialogs/SimpleDialog";

import "../styles/profile.scss";
import ProfileLayout from "../layouts/ProfileLayout";
// import LicenseCard from "./LicenseCard";
import ProfileDetails from "./profileDetails";
// import DashboardActions from "../../redux/dashboardActions";
// import LicenseDetails from "./LicenseDetails";
import VERBIAGE from "../../../../../utils/VERBIAGE";
// import { FileTextOutlined } from "@ant-design/icons";
import { UITypography } from "../../../components/lib";
// import { Statistic, Row, Col, Button } from "antd";
import Typography from "../../../components/lib/typography/typography";
import LicenseCard from "./LicenseCard";
import AuthContext from "../../../authentication/views/components/Auth";
import Footer from "../../../authentication/views/common/Footer";
import AuthenticationActions from "../../../authentication/redux/AuthenticationActions";
import { getAuth } from "firebase/auth";

let theme=sessionStorage.getItem("theme")
let auth = getAuth();
const Profile = ({
    getCredentials,
    dashboard,
    authentication,
    companyProfile,
    licenseProfile,
    companyInvite,
    history,
}) => {
    let { token, emailId, userId } = useContext(AuthContext);
    let header = {
        token,
        userId,
        emailId,
    };
    let navigate = useNavigate();
    const getDaysLeft = (expiry) => {
        if (expiry) {
            const endDate = moment(expiry);
            const diffDays = endDate.diff(moment(), "days");
            if (diffDays < 1) return 0;
            return diffDays;
        }
        return 0;
    };
    useEffect(() => {
        if (token == null) {
            getLoadingDetails();
        }
        const interval = setInterval(() => {
            getLoadingDetails();
        }, 1800000);
        return () => clearInterval(interval);
    });
    useEffect(()=>(theme = sessionStorage.getItem("theme")))
    const getLoadingDetails = () => {
        auth.onAuthStateChanged(async(user) => {
            if (sessionStorage.getItem("logout") == "false") {
                token = await user.getIdToken();
                emailId = user.email;
                userId = user.uid;

                header = {
                    token,
                    emailId,
                    userId,
                };

                getCredentials(header);
            } else {
                sessionStorage.clear();
                localStorage.clear();
                auth.signOut();
                navigate("/");
                window.location.reload();
            }
        });
        if (sessionStorage.getItem("expired") == "true") {
            sessionStorage.removeItem("expired");
        }
    };

    useEffect(() => getCredentials(header), []);
    return (
        <div className="profile-container-crm" style={{backgroundColor:theme=="dark"?'#282828':''}}>
          
            {/* {getLoadingDetails()} */}
            {token == null || sessionStorage.getItem("expired") == "true" || token == ""
                ? getLoadingDetails()
                : ""}
            {/* {console.log(typeof sessionStorage.getItem("self"), sessionStorage.getItem("self")=="true")} */}
            {sessionStorage.getItem("self") == "true" && (
                <Dialog
                    isModalVisible={
                        sessionStorage.getItem("self") == "true"
                        //&& listLength != clusterList.length
                    }
                    onClose={() => {
                        sessionStorage.setItem("self", "false");
                        navigate(VERBIAGE.NAVIGATION.PATHS.PROFILE);
                    }}
                    content={"Refer to the guide and start creating your clusters"}
                    title={"Installation Guide"}
                    variant={"success"}
                    actionText={"OPEN GUIDE"}
                    onActionClick={() => {
                        window.open(
                            "https://docs.episilia.com/product-docs/installation",
                            "_blank",
                        ),
                            sessionStorage.setItem("self", "false");
                        navigate(VERBIAGE.NAVIGATION.PATHS.PROFILE);
                    }}
                />
            )}
            <ProfileLayout
                selectedMenuItem={"profile"}
                leftColumn={
                    <div className="div-container-layout" style={{backgroundColor:theme=="dark"?'#282828':''}}>
                        <div className="org-container" style={{backgroundColor:theme=="dark"?'#282828':''}}>
                            <div className={Profile.styles.subHeaderProfile}>
                                <Typography className="profile-dts-container__header__title" style={{color:theme=="dark"?'#DCDCDC':'#3A3A3A'}}>
                                    {VERBIAGE.TITLE.ORGANIZATION_DETAILS}
                                </Typography>
                                <div style={{ marginTop: 20 }} />
                                <UITypography style={{ marginTop: 20 }} className="gray-text">
                                    {VERBIAGE.PROFILE.LICENSE.YOUR_ORGANISATION}:
                                </UITypography>
                                <strong
                                    style={{
                                        fontSize: "18px",color:theme=="dark"?'#DCDCDC':'#3A3A3A'
                                    }}
                                >
                                    {/* Value */}
                                    {companyProfile.companyName}
                                </strong>

                                <div className={Profile.styles.lineBreak}></div>
                            </div>

                            <div className={Profile.styles.subHeaderProfile}>
                                <UITypography className="gray-text" >
                                    {VERBIAGE.PROFILE.LICENSE.COMPANY_ID}:
                                </UITypography>
                                <strong
                                    style={{
                                        fontSize: "18px",color:theme=="dark"?'#DCDCDC':'#3A3A3A'
                                    }}
                                >
                                    {/* Value */}
                                    {companyProfile.companyId}
                                </strong>

                                <div className={Profile.styles.lineBreak}></div>
                            </div>

                            <div className={Profile.styles.subHeaderProfile}>
                                <UITypography className="gray-text" >
                                    {VERBIAGE.PROFILE.LICENSE.COMPANY_URL}:
                                </UITypography>
                                <strong
                                    style={{
                                        fontSize: "18px",color:theme=="dark"?'#DCDCDC':'#3A3A3A'
                                    }}
                                >
                                    {/* Value */}
                                    {companyProfile.companyUrl}
                                </strong>

                                <div className={Profile.styles.lineBreak}></div>
                            </div>

                            <div className={Profile.styles.subPlanHeader} data-testid="activePlan">
                                <UITypography className="gray-text" >
                                    {VERBIAGE.PROFILE.LICENSE.CONTACT_NUMBER}:
                                </UITypography>
                                <strong
                                    style={{
                                        fontSize: "15px",color:theme=="dark"?'#DCDCDC':'#3A3A3A'
                                    }}
                                >
                                    {companyProfile.contactNo==""?"NA":companyProfile.contactNo}
                                </strong>

                                <div className={Profile.styles.lineBreak}></div>
                            </div>

                            <div
                                className={Profile.styles.subStartDateHeader} 
                                data-testid="startDate"
                            >
                                <UITypography className="gray-text" >
                                    {VERBIAGE.CAPS_LABELS.START_DATE}:
                                </UITypography>
                                <strong
                                    style={{
                                        fontSize: "15px",color:theme=="dark"?'#DCDCDC':'#3A3A3A'
                                    }}
                                >
                                    {/* Value */}
                                    {moment(companyProfile.createdAt).format(VERBIAGE.DATE.FORMAT)}
                                </strong>

                                <div className={Profile.styles.lineBreak}></div>
                            </div>

                            <div className={Profile.styles.subEndDateHeader} data-testid="endDate">
                                <UITypography className="gray-text" >UPDATED DATE</UITypography>
                                <strong
                                    style={{
                                        fontSize: "15px",color:theme=="dark"?'#DCDCDC':'#3A3A3A'
                                    }}
                                >
                                    {/* Value */}
                                    {moment(companyProfile.updatedAt).format(VERBIAGE.DATE.FORMAT)}
                                </strong>

                                <div className={Profile.styles.lineBreak}></div>
                            </div>
                        </div>

                        <div className="profile-view" style={{backgroundColor:theme=="dark"?'#282828':'', color:theme=="dark"?'#DCDCDC':'#3A3A3A'}}>
                            <ProfileDetails />
                        </div>
                        <div className="license-plan" >
                            {licenseProfile != [] &&
                                !_.isEmpty(licenseProfile) &&
                                licenseProfile.map((item, index) => (
                                    <div key={index} style={{borderRadius:3}}>
                                        <LicenseCard
                                            licenseId={item.licenseId}
                                            planName={item.planType}
                                            daysLeft={getDaysLeft(item.validTill)}
                                            isActive={item.active == "Y"}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                }
            />

           {/* {window.screen.width>900 && <div className="div-footer-profile">
                <Footer />
            </div>} */}
        </div>
    );
};

Profile.propTypes = {};

const mapStateToProps = (state) => ({
    dashboard: state.dashboard,
    authentication: state.authentication,
    userProfile: state.authentication.profileDetails,
    companyProfile: state.authentication.companyDetails,
    licenseProfile: state.authentication.licenseDetails,
});

const mapDispatchToProps = (dispatch) => ({
    getCredentials: (header) => {
        dispatch(AuthenticationActions.getCredentials(header));
    },
    // generateLicenseKey: () => {
    //     dispatch(DashboardActions.generateLicenseKey());
    // },
    // getLicenseKey: (credentials) => {
    //     dispatch(DashboardActions.getLicenseKey());
    // },
});

Profile.styles = {
    headerProfile: "headerProfile",
    subHeaderProfile: "subHeaderProfile",
    bottomheaderProfile: "bottomheaderProfile",
    lineBreak: "line-break",
    subLastHeader: "subLastHeader",
    subPlanHeader: "subPlanHeader",
    subLicenseHeader: "subLicenseHeader",
    subStartDateHeader: "subDateStartHeader",
    subEndDateHeader: "subDateEndHeader",
    profileDetails: "profileDetails",
    installation: "installation",
    installationContainer: "installationContainer",
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
