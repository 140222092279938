import {createSlice} from "@reduxjs/toolkit";
import {DateTime} from "luxon/build/cjs-browser/luxon";

const initialState = {
    isConnected: false,
    isPaused:false,
    tailLogs: []
}

function extractLogs(payload) {
    if ('streams' in payload && Array.isArray(payload.streams)) {
        let logs = []
        payload.streams.forEach((value, index) => {
            if ('values' in value && Array.isArray(value.values)) {
                value.values.forEach(line => {

                    logs.push({time: DateTime.fromMillis(line[0] / 1000000).toFormat("yyyy MM/dd  HH:mm:s"), line: line[1]})
                    logs.reverse()
                })
            }
        })
        return logs
    }
    return []
}

const tailSlice = createSlice({
    name: 'tail',
    initialState: initialState,
    reducers: {
        onConnected: (state, action) => {
            console.log("onConnected😎")
            state.isConnected = true
            
        },
        onMessage: (state, action) => {
            console.log("onMessage 😎")
            let logs = extractLogs(JSON.parse(action.payload))
            let sliceLength = 10000 - state.tailLogs.length - logs.length
            let tailLogs = [...state.tailLogs]
            console.log(sessionStorage.getItem("tailPaused"))
            if(sessionStorage.getItem("tailPaused")=="resumed" || sessionStorage.getItem("tailPaused")=="connected")  
            {
            if (sliceLength < 0) {
                tailLogs.splice(0, sliceLength * -1)
            }
            tailLogs = [...logs].concat(tailLogs)
            console.log(typeof tailLogs)
            }
            state.tailLogs = tailLogs


        },
        onClose: (state, action) => {
             state.tailLogs = []
            console.log("Close 😎")
            state.isConnected = false
        },
        onError: (state, action) => {
            console.log("Error 😎")
            state.isConnected = false
        }
    }
})

export const {onConnected, onMessage, onClose, onError} = tailSlice.actions

export const tailLogsSelector = (state) => state.tail.tailLogs
export const isTailConnected = (state) => state.tail.isConnected
export default tailSlice.reducer