// Import the functions you need from the SDKs you need

import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';
// import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyDx9yg99uPVCBrtNcsCjPxki3ddm-DGPSI",
  authDomain: "episilia-spike.firebaseapp.com",
  projectId: "episilia-spike",
  storageBucket: "episilia-spike.appspot.com",
  messagingSenderId: "812636127202",
  appId: "1:812636127202:web:5ebf2efcc0562285ff83bb"
};
// Initialize Firebase
// if(firebase.length!=0)
//  var app= firebase.initializeApp(firebaseConfig);
// export default app

// if(firebase.length!=0)
//  export default firebaseConfig
// Initialize Firebase
if(firebase.length!=0)
  var app=firebase.initializeApp(firebaseConfig);


const auth=getAuth(app)

 export default firebase
