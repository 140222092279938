
const headers=()=>{
let token=localStorage.getItem("Token")
let uid=localStorage.getItem("userId")
let email=localStorage.getItem("emailId")

return { "auth-token": token,
"uid": uid,
"email": email,}
}

export default headers