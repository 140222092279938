import AuthenticationActionTypes from "./AuthenticationActionTypes";
import URLBuilder from "../../../../utils/Url_builder";
import _ from "lodash";
// import header from "../../../../utils/Header";

import api from "../../../../utils/API";
import URL from "../../../../utils/URL";
import VERBIAGE from "../../../../utils/VERBIAGE";

let HeaderOptions;

const AuthenticationActions = {
    setAuthenticationResponse: (payload) => ({
        type: AuthenticationActionTypes.SET_SESSION,
        payload,
    }),

    setRegistrationResponse: (payload) => ({
        type: AuthenticationActionTypes.SET_REGISTRATION_DETAILS,
        payload,
    }),

    setAuthFailResponse: (payload) => ({
        type: AuthenticationActionTypes.SET_SESSION,
        payload,
    }),

    validateCredentials: () => (dispatch) => {
        const token = localStorage.getItem("Token");
        const payload = {
            session: { token },
        };
        dispatch(AuthenticationActions.setAuthenticationResponse(payload));
    },

    resetFetched: () => ({
        type: AuthenticationActionTypes.CLOSE_DIALOG,
    }),

    setRoute: (payload) => ({
        type: AuthenticationActionTypes.SET_ROUTE,
        payload,
    }),

    getCredentials: (header) => (dispatch) => {
        const headers = { "auth-token": header.token, uid: header.userId, email: header.emailId };
        const headeroptions = { headers };
        HeaderOptions = headeroptions;

        // api.get( "https://ssl-pulse.episilia.in/spike/user/current",headeroptions)
        api.get(URLBuilder.build(URL.AUTHENTICATION.VERIFY), headeroptions)
            .then((response) => {
                // console.log(response)
                if (response.data.data.registered) {
                    sessionStorage.setItem("registered", response.data.data.registered);
                    sessionStorage.setItem("userId", response.data.data.userProfile.userId);
                    dispatch(AuthenticationActions.setAuthenticationResponse(response.data.data));

                    AuthenticationActions.navigateToPath(
                        props,
                        VERBIAGE.NAVIGATION.PATHS.CRMDASHBOARD,
                    );
                } else if (
                    !response.data.data.authenticated &&
                    !response.data.data.registered &&
                    sessionStorage.getItem("logout") == "false"
                ) {
                    console.log("line 67%%%%%");
                    sessionStorage.setItem("expired", true);
                } else {
                    dispatch({ type: AuthenticationActionTypes.VERIFIED });
                    AuthenticationActions.navigateToPath(props, VERBIAGE.NAVIGATION.PATHS.REGISTER);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },

    registerUser: (payload, props) => (dispatch) => {
        api.post(URLBuilder.build(URL.AUTHENTICATION.REGISTER), payload, HeaderOptions)
            .then((response) => {
                console.log(response)
                if (response.data.data.registered) {
                    sessionStorage.setItem("registered", response.data.data.registered);
                    console.log("registers success");
                    // dispatch({ type: AuthenticationActionTypes.FETCHING_SUCCESS });
                    AuthenticationActions.navigateToPath(
                        props,
                        VERBIAGE.NAVIGATION.PATHS.CRMDASHBOARD,
                    );
                   
                    dispatch(AuthenticationActions.setRegistrationResponse(response.data.data));
                    // window.location.reload()
                } else if (response.data.message) {
                    console.log("already available");
                    if (response.data.message.includes("company is already registered"))
                        sessionStorage.setItem("company", "registered");
                } 
                else 
               { dispatch({ type: AuthenticationActionTypes.FETCHING_FAILED });}

                // dispatch(AuthenticationActions.setAuthenticationResponse(response.data.data));
                // dispatch(
                //     AuthenticationActions.setRoute({
                //         route: VERBIAGE.NAVIGATION.PATHS.CRMDASHBOARD,
                //     }),
                // );
            })
            .catch((error) => {
                console.log("error catch");
                // dispatch(AuthenticationActions.setAuthFailResponse(error.data));
            });
    },

    registerUserWithInvite: (payload, props) => (dispatch) => {
        // const headers= {"auth-token":header.token,"uid":header.userId,"email":header.emailId}
        //  const headeroptions = { headers };
        api.post(URLBuilder.build(URL.AUTHENTICATION.REGISTER_WITH_INVITE), payload, HeaderOptions)
            .then((response) => {
                if (response.data.data.registered) {
                    sessionStorage.setItem("registered", response.data.data.registered);
                    dispatch({ type: AuthenticationActionTypes.FETCHING_SUCCESS });
                    AuthenticationActions.navigateToPath(
                        props,
                        VERBIAGE.NAVIGATION.PATHS.CRMDASHBOARD,
                    );
                } 
                else dispatch({ type: AuthenticationActionTypes.FETCHING_FAILED });
                dispatch(AuthenticationActions.setAuthenticationResponse(response.data.data));
                // dispatch(AuthenticationActions.setRegistrationResponse(response.data));
            })
            .catch((error) => {
                dispatch(AuthenticationActions.setAuthFailResponse(error.data));
            });
    },

    invitedUser: (header) => (dispatch) => {
        const headers = { "auth-token": header.token, uid: header.userId, email: header.emailId };
        const headeroptions = { headers };
        let url = URL.AUTHENTICATION.VERIFY_INVITE;
        let replacedUrl = url.replace("$token", localStorage.getItem("inviteToken"));

        api.get(URLBuilder.build(replacedUrl), headeroptions)
            .then((response) => {
                dispatch({
                    type: AuthenticationActionTypes.VERIFICATION_SUCCESS,
                    payload: response.data.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: AuthenticationActionTypes.VERIFICATION_FAILED,
                    payload: error.data,
                });
            });
    },

    // to verify the invitation
    verifyInvitedCompany: (header) => (dispatch) => {
        const headers = { "auth-token": header.token, uid: header.userId, email: header.emailId };
        const headeroptions = { headers };
        //  console.log(header)
        let url = URL.AUTHENTICATION.VERIFY_COMPANY_INVITE;
        let replacedUrl = url.replace("$token", localStorage.getItem("inviteToken"));
        api.get(URLBuilder.build(replacedUrl), headeroptions)
            .then((response) => {
                // console.log(response)
                dispatch({
                    type: AuthenticationActionTypes.COMPANY_VERIFICATION_SUCCESS,
                    payload: response.data.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: AuthenticationActionTypes.COMPANY_VERIFICATION_FAILED,
                    payload: error.data,
                });
            });
    },

    setPreviousRoute: (props) => (dispatch) => {
        if (location.pathname === VERBIAGE.NAVIGATION.PATHS.REGISTER)
            dispatch(AuthenticationActions.setRoute({ route: VERBIAGE.NAVIGATION.PATHS.REGISTER }));
        else dispatch(AuthenticationActions.setRoute({ route: VERBIAGE.NAVIGATION.PATHS.LOGIN }));
    },

    navigateToPath: (props, path) => {
        console.log(props,path)
        window.location.reload()
        navigate(path);
    },
};

export default AuthenticationActions;
