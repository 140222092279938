import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss'
import 'antd/dist/antd.css';
import {firebaseConfig} from './config'
import firebase from 'firebase/compat/app';
import Router from "../App/CRMRouter";
import regeneratorRuntime from "regenerator-runtime";
import { AuthProvider } from './authentication/views/components/Auth';

let value=(firebase.apps.length)
if (!value) {
  firebase.initializeApp(firebaseConfig);

} else {
  firebase.app();
}

const App = () => {

sessionStorage.setItem("app","spike")

  return (
    <AuthProvider>
    <BrowserRouter>
    
        <div>
          <Router />
        </div>
     
    </BrowserRouter>
    </AuthProvider>
  );
};

export default App;

