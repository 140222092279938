import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import TopbarSidebarButton from "./TopbarSidebarButton";
// import Logo from '../../../assets/Butterfly_new.png';
import CRMLogo from "../../../assets/Butterfly_new.png";
// import Navigation from "../../CRM/dashboard/views/components/Search/component/Navigation";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SearchIcon from "@mui/icons-material/Search";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PersonIcon from "@mui/icons-material/Person";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Image } from "antd";
let theme=sessionStorage.getItem("theme")
const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
    const url = location.pathname;
    const [pulse, setPulse] = useState(true);
    const navigate = useNavigate();
    useEffect(() => setPulse(url.includes("/cluster")));

    useEffect(()=> (theme=sessionStorage.getItem("theme")))


    return (
        <div className="topbar" style={{backgroundColor:theme=="dark"?location.pathname.includes('dashboard')?'#282828':'':'transparent',position:location.pathname.includes('dashboard')?'absolute':'', marginTop:location.pathname.includes('dashboard')?'1.5em':''}}>
            <div className="topbar__left"  style={{backgroundColor:theme=="dark"?'#282828':'transparent'}}>
               
            </div>
            {url.includes("dashboard") ? (
                <span style={{color:theme=="dark"?'#DCDCDC':'#000',backgroundColor:theme=="dark"?'#282828':'transparent',position:'relative',zIndex:125,
                paddingTop:'14px',paddingBottom:'2px',height:'20px'}}>
                    <DashboardIcon /> Dashboard
                </span>
            ) : url.includes("explorer") ? (
                // style={{color:'#B2B2AC', zIndex:105,position:'relative'}}
                <span style={{color:theme=="dark"?'#DCDCDC':'#000',backgroundColor:theme=="dark"?'#2B2829':'transparent',position:'relative',zIndex:45}} >
                    <SearchIcon /> Search
                </span>
            ) : url.includes("plan") ? (
                <span style={{color:theme=="dark"?'#DCDCDC':'#000',backgroundColor:theme=="dark"?'#282828':'transparent',position:'relative',zIndex:45}}>
                    <MonetizationOnIcon /> Plan and Pricing
                </span>
            ) : url.includes("users") ? (
                <span style={{color:theme=="dark"?'#DCDCDC':'#000',backgroundColor:theme=="dark"?'#282828':'transparent',position:'relative',zIndex:45}}>
                    <PersonAddAltIcon /> Manage Users
                </span>
            ) : url.includes("profile") ? (
                <span style={{color:theme=="dark"?'#DCDCDC':'#000',backgroundColor:theme=="dark"?'#282828':'transparent',position:'relative',zIndex:45}}>
                    <PersonIcon /> Profile
                </span>
            ) : url.includes("support") ? (
                <span style={{color:theme=="dark"?'#DCDCDC':'#000',backgroundColor:theme=="dark"?'#282828':'transparent',position:'relative',zIndex:45}}>
                    <SupportAgentIcon /> Support
                </span>
            ) : (
                ""
            )}
        </div>
    );
};
Topbar.propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
