import PropTypes from 'prop-types';
import {
    Box,
    ButtonGroup,
    Drawer, IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText, ListSubheader
} from "@mui/material";
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import LaunchSharpIcon from '@mui/icons-material/LaunchSharp';
// import {DeleteSharp, EditSharp, LaunchSharp} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import QuerySaveModal from "./QuerySaveModal";

export default function QueryListModal({userId, open, queries, history, onDelete, onOpen, onEdit, onClose}) {
    const [queryToModify, setQueryToModify] = useState(null)
    const [openModal, setOpenModal] = useState(false)

    return <>
        <>
            <Drawer anchor={"right"} onClose={() => {
                onClose()
            }} open={open}>
                <Box minWidth={350}>
                    {console.log("****** Query List Modal",queries)}
                    <List dense>
                        <ListSubheader>
                            Queries
                        </ListSubheader>
                        {queries?.map((query, index) => {
                            return <ListItem key={index}>
                                <ListItemText>{query.queryName}</ListItemText>
                                <ListItemSecondaryAction><ButtonGroup size={"small"}>
                                    <IconButton onClick={() => {
                                        console.log(query)
                                        onOpen(query)
                                    }} size={"small"}><LaunchSharpIcon/></IconButton>
                                    <IconButton onClick={(event) => {
                                        setQueryToModify(query)
                                        setOpenModal(true)
                                    }} size={"small"} component={"span"}>
                                        <EditSharpIcon/></IconButton>
                                    <IconButton onClick={() => {
                                        onDelete(query)
                                    }} size={"small"} component={"span"}>
                                        <DeleteSharpIcon/></IconButton>
                                </ButtonGroup></ListItemSecondaryAction>
                            </ListItem>
                        })}
                    </List>
                </Box>
            </Drawer>
        </>

        {queryToModify !== null && <QuerySaveModal open={openModal} onClose={() => {
            setQueryToModify(null)
            setOpenModal(false)
        }} queryToModify={queryToModify} userId={userId} onSave={onEdit}></QuerySaveModal>}
    </>
}
QueryListModal.propTypes = {
    queries: PropTypes.array,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onShare: PropTypes.func
}

