const STYLE = {
    DIMENSIONS: {
        MARGIN: {
            small: "margin-small",
            medium: "margin-medium",
            large: "margin-large",
            none: "margin-none"
        },
        PADDING: {
            small: "padding-small",
            medium: "padding-medium",
            large: "padding-large",
            none: "padding-none"
        }
    }
};

export default STYLE;