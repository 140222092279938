import React from "react";
import PropTypes from "prop-types";

const MainWrapper = ({ children }) => (
    <div
        className="theme-light"
        style={{
            background:
                sessionStorage.getItem("theme") == "dark"
                    ? location.pathname.includes("dashboard")
                        ? "#282828"
                        : ""
                    : "#fff",
                    position:'relative',
            marginTop: location.pathname.includes("dashboard") ? "-2.5em" : "",
        }}
    >
        <div className="wrapper">{children}</div>
    </div>
);

MainWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MainWrapper;
