import {
    Box,
    Divider,
    TextField,
    Typography,
    Select,
    InputLabel,
    FormControl,
    Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import LabelList from "./LabelList";
import { onQueryChange } from "../features/explorer/explorerSlice";

let count=0;


const useStyles = makeStyles({
    customOutline: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#1C1850"
      }
    }
  });


export default function SideLabelPanel({ labels, onFilterClick, FirstClick }) {
    const classes = useStyles()
    const [filter, setFilter] = useState("");
    const [filteredLabels, setFilteredLabels] = useState([]);
    let theme=sessionStorage.getItem("theme")
    useEffect(() => {
        if (filter !== "") {
            let filtered = {};
            let filteredKeys = Object.keys(labels).filter((value) => {
                return value.includes(filter);
            });
            filteredKeys.forEach((value) => {
                filtered[value] = labels[value];
            });
            setFilteredLabels(filtered);
        } else {
            setFilteredLabels(labels);
        }
    }, [filter, labels]);

    return (
        <>
            <Box width={"100%"} paddingLeft={"0.5rem"} marginLeft={"0px"} sx={{ border: "none" }}   >
                <FormControl fullWidth classes={{ root: classes.customOutline }}>
                    <InputLabel id="demo-simple-select-label">Label</InputLabel>
                    
                    <Select
                    onOpen={()=>( count++, FirstClick(count))}
                        sx={{ backgroundColor: theme=="dark"?'#DCDCDC':'#FFF' }}
                        fullWidth
                        variant="outlined"
                        placeholder="Label"
                        label="Label"
                        size={"small"}
                      
                    >
                        {labels.length === 0 && (
                            <MenuItem disabled value={-1}>
                                No Labels Found
                            </MenuItem>
                        )}

                        <LabelList
                            labels={filteredLabels}
                            onFilterClick={(value) => {
                                onFilterClick(value);
                               
                            }}
                        />
                        {/* <Button onClick={()=> sessionStorage.setItem("label","true")}>Add query</Button> */}
                    </Select>
                </FormControl>
                
            </Box>
        </>
    );
}
