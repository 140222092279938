const VERBIAGE = {
    BUTTONS: {
        GET_OTP: "Get Link",
        LOGIN: "Login",
        NEW_FEATURES: "NEW FEATURES",
        CREATE_ACCOUNT: "Create Account",
        SAVE: "Save",
        EDIT: "Edit",
        CANCEL: "Cancel",
        CONTINUE: "Continue",
        TRY_AGAIN: "Try Again",
        SHOW_LICENSE_DETAILS: "Show License Details",
        HIDE_LICENSE_DETAILS: "Hide License Details",
        NOTIFY_ME: "Notify Me",
        UPGRADE: "Upgrade",
        CONTACT:"Contact Support"
    },
    LABELS: {
        EMAIL: "Email*",
        OTP: "OTP",
        NAME: "Name*",
        COUNTRY: "Country",
        MOBILE: "Mobile Number",
        COMPANY_NAME: "Company Name",
        ROLE: "USER ROLE",
        COMPANY_DOMAIN: "Company Domain",
        COMPANY_URL: "Company URL",
    },
    CAPS_LABELS: {
        NAME: "NAME",
        EMAIL: "EMAIL ADDRESS",
        MOBILE: "MOBILE",
        COMPANY_NAME: "COMPANY NAME",

        TENANT_URL: "TENANT URL",
        COMPANY_URL: "COMPANY URL",
        CLIENT_ID: "CLIENT ID",
        PLAN: "PLAN",
        START_DATE: "START DATE",
        END_DATE: "END DATE",
        UPDATED_DATE: "UPDATED DATE",
        STATUS: "STATUS",
        DAILY_VOL_LOGS: "DAILY VOLUME OF LOGS",
        DAILY_VOL_RETENTION_LOGS: "DAILY VOLUME OF RETENTION LOGS",
        LICENSE_ID: "LICENSE ID",
        WELCOME: "WELCOME",
        ACTIVE: "ACTIVE",
        USERID: "USER ID",
        ROLE: "USER ROLE",
    },
    TITLE: {
        HOME: "Login",
        REGISTER: "Create an Account",
        FEATURE: "Familiar Grafana UI",
        SECURE: "Data Fully Secure",
        CONTACT_DETAILS: "Contact Details",
        PROFILE_DETAILS: "Profile Details",
        ORGANIZATION_DETAILS: "Organization Details",
        LICENSE_DETAILS: "License Details",
        COMING_SOON: "Coming Soon",
        CHOOSE_PLAN: "Choose a plan",
        SERVICE: "Choose a plan that's right for you",
        RIGHT: "at the right price",
    },
    SUB_TITLE: {
        HOME: "Power of logs at your fingertips",
        FEATURE:
            "Episilia is integrated with grafana log browser,so teams can start searching logs right away with zero learning curve.",
        PLAN: "We make it simple. Pay as you go or make the yearly commitment and gain the most flexibility.",
        SECURE: "Episilia runs in your own cloud account or datacenter and all data resides in your cloud storage. There are no incoming API calls into your episilia cluster. Usage info is aggregated and sent to episilia.com via secure APIs.",
        PRICING: "Simple, Transparent Pricing that grows with you",
    },
    LINKS: {
        REGISTER: "Register",
        RESEND_NEW_OTP: "Resend New OTP",
        LEARN_MORE: "LEARN MORE",
        LOGIN: "Login",
    },
    PLACEHOLDERS: {
        EMAIL: "example@gmail.com",
        OTP: "Enter your OTP here",
        NAME: "John Doe",
        COUNTRY: "Country",
        MOBILE: "Your Mobile Number",
        COMPANY_NAME: "Your Company Name",
        COMPANY_URL: "Company URL",
        ROLE: "Devops or Admin",
        COMPANY_DOMAIN: "example.com",
    },
    TEXT: {
        HAVE_ACCOUNT: "Already an user? ",
        NOT_HAVE_ACCOUNT: "Don't have an account? ",
        OTP_SENT_MESSAGE_PART_ONE: "We have sent an OTP to your email address.",
        OTP_SENT_MESSAGE_PART_TWO: "Please enter the same to Login",
        REGISTRATION_SUCCESS: "Registration Successful",
        REGISTRATION_FAILED: "Registration Failed",
        UPDATE_SUCCESS: "Profile Updated",
        UPDATE_FAILED: "Updating Failed",
        CREATE_RPOFILE_SUCCESS: "Congratulations, your account has been successfully created",
        UPDATE_PROFILE_SUCCESS: "Congratulations, your account has been successfully updated",
        GENERAL_FAILURE_MESSAGE: "Oops, something went wrong. Please try again",
        INVALID_OTP: "Incorrect OTP, Please try again",
        NOTIFY_ME_SUCCESS: "You will be notified",
        NOTIFY_ME_FAILED: "Something went wrong, Please try again",
        COST_SUB_HEADER_ONE: "Only $99/month",
        COST_SUB_HEADER_TWO: "upto 500GB per day",

        INVITE_SUCCESS: "Invitation sent to email id",
        INVITE_FAILED: "Please check email id and Retry",
        // CREATE_RPOFILE_SUCCESS: "Congratulations, your account has been successfully created",
        // UPDATE_PROFILE_SUCCESS: "Congratulations, your account has been successfully updated",
        // GENERAL_FAILURE_MESSAGE: "Oops, something went wrong. Please try again",
    },
    NAVIGATION: {
        PATHS: {
            LOGIN: "/",
            REGISTER: "/register",

            CRMHOME: "/spike",
            CRMDASHBOARD: "/spike/dashboard",
            CRMDASHBOARDCLUSTERS: "/spike/dashboard/servers/:id",
            PROFILE: "/spike/profile",
            PLAN: "/spike/plan",
            SUPPORT: "/spike/support",
            MANAGEUSERS: "/spike/users",
            BILLING: "/spike/billing",
            SEARCH: "/spike/explorer",

            CLUSTER: "/cluster",
            //PROFILE: '/profile',
            // VERIFY: '/verifyOTP',
        },
    },
    PROFILE: {
        MENU: {
            OVERVIEW: "OVERVIEW",
            LINKS: {
                PROFILE: "Profile",
                PLANS_AND_PRICING: "Plans & Pricing",
                LOGOUT: "Logout",

                CLUSTER: "Live Cluster",
                METRICS: "Metrics",
                EVENTS: "Events",
                CONFIG: "Config",
                HISTORY: "History Servers",

                OVERVIEW: "All Clusters",
                DETAILS: "Details",
            },
        },
        CUSTOMER_SUPPORT: {
            FLAVOR_TEXT: "Have any problems with Episilia?",
            CONTACT_CS: "Get in Touch",
            CONTACT_US: "support@episilia.com",
            INSTALLATION_GUIDE: "Installation Guide For New Users",
        },
        LICENSE: {
            ACTIVE_LICENSE: "ACTIVE LICENSE",
            LICENSE_ID: "LICENSE ID",
            DAYS_LEFT: " days left",
            UPGRADE: "UPGRADE YOUR PLAN",
            ACTIVE_PLAN: "ACTIVE PLAN",
            YOUR_ORGANISATION: "YOUR ORGANISATION",
            COMPANY_ID: "COMPANY ID",
            COMPANY_URL: "COMPANY URL",
            CONTACT_NUMBER: "CONTACT NUMBER",

            OFFERS: {
                // COMMUNITY: ["Unlimited Ingestion", "Unlimited Retention", "Unlimited Users", "Search up to last 7 days",  "Community Support"],
                // COMMUNITY_MOBILE_VIEW: ["Unlimited Ingestion",  "Unlimited Retention", "Unlimited Users", "Search up to last 7 days", "Community Support"],
                // PREMIUM: ["Unlimited Ingestion", "Unlimited Retention", "Unlimited Users", "Unlimited Search", "Live Tail", "Unlimited Alerts", "Email, Slack Support"],
                PREMIUM: [
                    "Unlimited Ingestion",
                    "Unlimited Retention",
                    "Unlimited Users",
                    "Unlimited search",
                    "Unlimited Alerts",
                    "Live Tail",
                    "Email, Slack Support",
                ],
                // CORPORATE: ["Unlimited Ingestion", "Unlimited Retention", "Unlimited Users", "Unlimited Search", "Live Tail", "Unlimited Alerts", " PrestoDB,Hive & Athena Integration", "IAM Supported", "Assisted Installation", "Email, Slack Support"],
                // CORPORATE: ["All Pro Features", "PrestoDB & Hive Integration", "IAM Supported", "Assisted Installation",],
                SAAS: [
                    "Pay per use",
                    "Free credit of 30GB logs per month",
                    "$0.20 per GB for logs ingested above 30GB",
                    "30 days logs retention included",
                    "$0.02 per GB per month for additional retention of logs",
                    
                ],
                SELF_HOSTED: [
                    "Pay per use",
                    "$99 for the first 2TB of logs ingested per month",
                    "$0.10 per GB for logs ingested above 2TB",
                    "Clusters deployed in client's cloud account",
                    "All data stored in client's cloud account",
                ],
                MANAGED: [
                    "Clusters deployed in client's cloud account",
                    " Clusters deployed and operated by Episilia team",
                    " Plans start at $999 per month",
                ],
            },
        },
    },
    STATUSES: {
        ACTIVE_CAPS: "ACTIVE",
        INACTIVE_CAPS: "INACTIVE",
        ACTIVE: "Active",
        INACTIVE: "Inactive",
    },
    PLANS: {
        COMMUNITY: "Community",
        PREMIUM: "Pro",
        CORPORATE: "Enterprise",
        PLAN: "Plan",
        COMMUNITY_LOWERCASE: "community",
        PRO_LOWERCASE: "pro",
        ENTERPRISE_LOWERCASE: "enterprise",
        FOR_STARTUPS: "For Startups",
        FOR_LARGE_ENTERPRISES: "For Large Companies",
    },
    DATE: {
        FORMAT: "DD-MMM-YYYY, ddd",
    },
    HEADER: {},
    FOOTER: {
        TEXT: "© 2021 Episilia All rights reserved",
    },
};

export default VERBIAGE;
