import { size } from "lodash";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import _ from "lodash";
import "./charts.scss";
import VERBIAGE from "../../../utils/VERBIAGE";
import moment from "moment";

let data3, value;

//data (Indexer details)
//data2 kafka details
//data4 indexer details
// data 5 search gateway
const Charts = ({ data, data2, data4, data5, len, len2, len3, len4 }) => {
  // data3 = (data, data2);

  const getMin = (data3, dataMin) => {
    return data3 == 1
      ? 0
      : data3 == 2
      ? 10
      : data3 == 3
      ? 50
      : data3 == 4
      ? 100
      : data3 == 5
      ? 1000
      : data3 == 6
      ? 10000
      : data3 == 7
      ? 100000
      : 1000000;
  };

  const getHigherMin = (data3) => {
    return data3 == 1
      ? 0
      : data3 == 2
      ? 40
      : data3 == 3
      ? 400
      : data3 == 4
      ? 4000
      : data3 == 5
      ? 40000
      : data3 == 6
      ? 400000
      : data3 == 7
      ? 4000000
      : 40000000;
  };
  const getMax = (data3) => {
  
    return data3 == 1
      ? 10
      : data3 == 2
      ? 100
      : data3 == 3
      ? 500
      : data3 == 4
      ? 5000
      : data3 == 5
      ? 50000
      : data3 == 6
      ? 500000
      : data3 == 7
      ? 5000000
      : 50000000;
  };
  const getHigherMax = (data3) => {
  
    return data3 == 1
      ? 10
      : data3 == 2
      ? 100
      : data3 == 3
      ? 1000
      : data3 == 4
      ? 10000
      : data3 == 5
      ? 100000
      : data3 == 6
      ? 1000000
      : data3 == 7
      ? 10000000
      : 100000000;
  };

  return (
    <Card className="card-charts-container">
      <CardBody className="card-body-charts">
        {(len > 0 && (
          <div className="graph-container-1">
            <span className="title-graph">Indexer - Log Reader rps</span>
            {/* Graph 1 */}
            <ResponsiveContainer
              className="div-background"
              width={"100%"}
              height={300}
              barGap={60}
              margin={60}
            >
              <LineChart
                stackOffset="expand"
                width={300}
                height={500}
                data={data}
                barCategoryGap={10}
                margin={{
                  top: 5,
                  right: 50,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid stroke="#08AC99" strokeDasharray="3 3" />
                <Tooltip />
                <Legend align="left" />
                <XAxis
                  data={moment(data).format(VERBIAGE.DATE.FORMAT)}
                  dataKey="report_time"
                  tick={{ fontSize: 10 }}
                  dx={5}
                  tickCount={5}
                  stroke={"black"}
                  className="label-name"
                  tickMargin={5}
                  label={{
                    value: "Time stamp",
                    color: "08AC99",
                    fontSize: 12,
                    position: "insideBottomCenter",
                    dy: 20,
                  }}
                />

                <YAxis
                  type="number"
                  dy={0}
                  barCategoryGap={40}
                  tickCount={5}
                  domain={[
                    // (data3 = size(dataMin.toString())),getValues(data3))

                    (dataMin) => (
                      ((data3 = size(dataMin.toString())),
                      (value = dataMin.toString().slice(0, 1))),
                      value < 5 ? getMin(data3) : getHigherMin(data3)
                    ),
                    (dataMax) => (
                      ((data3 = size(dataMax.toString())),
                      (value = dataMax.toString().slice(0, 1))),
                      value < 5 ? getMax(data3) : getHigherMax(data3)
                    ),
                  ]}
                  stroke={"black"}
                  label={{
                    value: "Log reader",
                    fontSize: 12,
                    position: "insideCenterLeft",
                    angle: -90,
                    dy: -10,
                    dx: -35,
                  }}
                />
                <Line
                  strokeWidth={2}
                  stroke={"#08AC99"}
                  barSize={30}
                  data={data}
                  dataKey="log_reader_rps"
                  fill="#FFF"
                  type="monotone"
                  activeDot={{ r: 8 }}
                />
                {/* <Line strokeWidth={3} stroke={"#FAD7A0"} barSize={30}  dataKey="log_input_rps" fill="#FFF" />
                    <Line strokeWidth={3} stroke={"#D7BDE2"} barSize={30}  dataKey="log_ingest_rps" fill="#FFF"  /> */}
              </LineChart>
            </ResponsiveContainer>
          </div>
        )) || (
          <div className="graph-container-1">
            {" "}
            <p>No live Indexer-Log reader rps</p>
          </div>
        )}

        {(len2 > 0 && (
          <div className="graph-container-2">
            {/* Graph 2 */}
            <span className="title-graph">
              Kafka - Log input rps, Log ingest rps
            </span>
            <ResponsiveContainer
              className="div-background"
              width={"100%"}
              height={300}
              barGap={60}
              margin={(-50, 0, 0, -300)}
            >
              <LineChart
                stackOffset="expand"
                width={100}
                height={600}
                data={data2}
                barCategoryGap={10}
                margin={{
                  top: 5,
                  right: 50,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid stroke="#08AC99" strokeDasharray="3 3" />
                <Tooltip />
                <Legend align="left" />
                <XAxis
                  dataKey="report_time"
                  data={data2}
                  tick={{ fontSize: 10 }}
                  dx={5}
                  tickCount={5}
                  stroke={"black"}
                  label={{
                    value: "Time stamp",
                    fontSize: 12,
                    position: "insideBottomRight",
                    dy: 20,
                    dx: -30,
                  }}
                  //  tick={false}
                />

                <YAxis
                  type="number"
                  dy={5}
                  barCategoryGap={40}
                  tickCount={5}
                  domain={[
                    // (dataMin) => (
                    //   (data3 = size(dataMin.toString())), getMin(data3)
                    // ),
                    // (dataMax) => (
                    //   (data3 = size(dataMax.toString())), getMax(data3)
                    // ),
                    (dataMin) => (
                      ((data3 = size(dataMin.toString())),
                      (value = dataMin.toString().slice(0, 1))),
                      value < 5 ? getMin(data3) : getHigherMin(data3)
                    ),
                    (dataMax) => (
                      ((data3 = size(dataMax.toString())),
                      (value = dataMax.toString().slice(0, 1))),
                      value < 5 ? getMax(data3) : getHigherMax(data3)
                    ),
                  ]}
                  stroke={"black"}
                  label={{
                    value: "Log Input and Log ingest",
                    fontSize: 12,
                    position: "insideCenterLeft",
                    angle: -90,
                    dy: -10,
                    dx: -35,
                  }}
                />
                {/* <Line strokeWidth={3} stroke={"#57DCBE"} barSize={30} data={data} dataKey="log_reader_rps" fill="#FFF" /> */}
                <Line
                  strokeWidth={2}
                  stroke={"#F3A752"}
                  barSize={30}
                  dataKey="log_input_rps"
                  fill="#FFF"
                  type="monotone"
                  activeDot={{ r: 8 }}
                  // data={data2}
                />
                <Line
                  strokeWidth={2}
                  stroke={"#08AC99"}
                  barSize={30}
                  dataKey="log_ingest_rps"
                  fill="#FFF"
                  type="monotone"
                  activeDot={{ r: 8 }}
                  // data={data2}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )) || (
          <div className="graph-container-2">
            <p>No live Kafka</p>
          </div>
        )}

        {(len3 > 0 && (
          <div className="graph-container-3">
            <span className="title-graph">Kafka Details - Log lag</span>
            <ResponsiveContainer
              className="div-background"
              width={'100%'}
              height={300}
              barGap={60}
              margin={{
                top: 5,
                right: 30,
                left: 30,
                bottom: 5,
              }}
            >
              <LineChart
                width={70}
                height={600}
                data={data4}
                barCategoryGap={10}
                barGap={10}
                margin={{
                  top: 25,
                  right: 10,
                  left: 30,
                  bottom: 5,
                }}
              >
                <CartesianGrid stroke="#08AC99" strokeDasharray="3 3" />
                <Tooltip />
                <Legend align="left" />
                <XAxis
                  data={data4}
                  dataKey="report_time"
                  tick={{ fontSize: 10 }}
                  stroke={"black"}
                  tickCount={5}
                  dx={5}
                  label={{
                    value: "Time stamp",
                    fontSize: 12,
                    position: "insideBottomCenter",
                    dy: 20,
                  }}
                />
                <YAxis
                  type="number"
                  stroke={"black"}
                  dy={-3}
                  tickCount={5}
                  domain={[
                    (dataMin) => (
                      ((data3 = size(dataMin.toString())),
                      (value = dataMin.toString().slice(0, 1))),
                      value < 5 ? getMin(data3) : getHigherMin(data3)
                    ),
                    (dataMax) => (
                      ((data3 = size(dataMax.toString())),
                      (value = dataMax.toString().slice(0, 1))),
                      value < 5 ? getMax(data3) : getHigherMax(data3)
                    ),
                  ]}
                  label={{
                    value: "Log lag",
                    fontSize: 12,
                    position: "insideLeft",
                    angle: -90,
                    dy: -10,
                    dx: -20,
                  }}
                />
                <Line
                  barSize={30}
                  data={data4}
                  dataKey="log_lag"
                  strokeWidth={2}
                  stroke={"#08AC99"}
                  fill="#FFF"
                  className="graph2_bar"
                  type="monotone"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )) || (
          <div className="graph-container-3">
            <p>No live Kafka- Log lag</p>
          </div>
        )}

        {(len4 > 0 && (
          <div className="graph-container-4">
            <span className="title-graph">Query Search time</span>
            <ResponsiveContainer
              className="div-background"
              width={"100%"}
              height={300}
              barGap={60}
              margin={30}
            >
              <BarChart
                width={70}
                height={600}
                data={data5}
                barCategoryGap={10}
                barGap={10}
                margin={{
                  top: 5,
                  right: 5,
                  left: 10,
                  bottom: 5,
                }}
              >
                <CartesianGrid stroke="#08AC99" strokeDasharray="2 2" />
                <Tooltip />
                <Legend align="left" />
                <XAxis
                  data={data5}
                  dataKey="report_time"
                  tick={{ fontSize: 8 }}
                  dx={6}
                  tickCount={10}
                  label={{
                    value: "Time stamp",
                    fontSize: 12,
                    position: "insideBottomCenter",
                    dy: 10,
                  }}
                />
                <YAxis
                  type="number"
                  dy={-5}
                  tickCount={5}
                  // domain={["dataMin", "dataMax"]}
                  domain={[
                    (dataMin) => (
                      (data3 = size(dataMin.toString())), getMin(data3)
                    ),
                    (dataMax) => (
                      (data3 = size(dataMax.toString())), getMax(data3)
                    ),
                  ]}
                  label={{
                    value: "Time (seconds)",
                    fontSize: 12,
                    position: "insideLeft",
                    angle: -90,
                    dy: 20,
                    dx: -5,
                  }}
                />
                <Bar
                  barSize={30}
                  data={data5}
                  dataKey="searches_0sto5s_count"
                  fill="#3CBC2A"
                  className="graph2_bar"
                />
                <Bar
                  barSize={30}
                  // data={data5}
                  dataKey="searches_5sto10s_count"
                  fill="#0B43A8"
                  className="graph2_bar"
                />
                <Bar
                  barSize={30}
                  // data={data5}
                  dataKey="searches_10sto20s_count"
                  fill="#C3CC0A"
                  className="graph2_bar"
                />
                <Bar
                  barSize={30}
                  // data={data5}
                  dataKey="searches_20sto40s_count"
                  fill="#B9AAF6"
                  className="graph2_bar"
                />
                <Bar
                  barSize={30}
                  // data={data5}
                  dataKey="searches_40s_plus_count"
                  fill="#F76355"
                  className="graph2_bar"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        )) || (
          <div className="graph-container-4">
            <p>No live Search </p>
          </div>
        )}
      </CardBody>
    </Card>
  );

  
};

export default Charts;


