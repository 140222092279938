import React from "react";
import "./footer.scss";
import VERBIAGE from "../../../../../utils/VERBIAGE";
import { UITypography } from "../../../components/lib";

const Footer = () => {
  return <div className={Footer.styles.headerRow}>
    <UITypography className='gray-text'>{VERBIAGE.FOOTER.TEXT}</UITypography>
  </div>
};

Footer.styles = {
  headerRow: 'footer-row'
};

export default React.memo(Footer);
