import React,{useEffect, useState} from "react";
import {Grid, MenuItem, Select, TextField} from "@mui/material";
import {DateTime} from "luxon";
import {TimeUnits} from "../lib/dateTimeUtils";

import {Duration} from "luxon/build/cjs-browser/luxon";


let Time=['Last 5 minutes','Last 15 minutes','Last 30 minutes','Last 1 hour','Last 3 hours','Last 6 hours', 'Last 12 hours','Last 24 hours','Last 2 days']

export default function DateTimeInput({
                                          timeRecord,
                                          onChange
                                      }) {
    const [relativeCount, setRelativeCount] = useState(15)
    const [timeUnit, setTimeUnit] = useState(2)

   
    useEffect(() => {
        if (timeRecord.type === "Relative") {
            setTimeUnit(TimeUnits.indexOf(timeRecord.unit))
            setRelativeCount(timeRecord.interval)
        }
    }, [timeRecord])


    function handleChange(unit, qty, callback) {
        console.log(unit, unit.slice(4), qty, callback)
        
        // console.log(unit.slice(4).split(" "))
        let val=unit.slice(4).split(" ")
        setRelativeCount(parseInt(val[1]))
        setTimeUnit(unit)
       
        let unitValue = val[2];
        console.log(relativeCount,timeUnit,unitValue)
        let change = {
            type: 'Relative',
            unit: unitValue,
            interval: parseInt(val[1]),
             epoch: DateTime.now().minus(Duration.fromObject({[unitValue]: parseInt(val[1])})).toMillis()
        }
        console.log(change)
        callback(change)
    }

    return (
        <Grid container spacing={1}>
                {/* <Grid item sm={6}>
                    <TextField value={relativeCount} onChange={(event) => {
                        handleChange(timeUnit, event.target.value, onChange)
                    }}>
                    </TextField>
                </Grid> */}
            <Grid item sm={6}>
                <Select value={timeUnit} onChange={(event) => {
                    console.log(event)
                    label="Duration"
                    handleChange(event.target.value, relativeCount, onChange)
                }}>
                    {
                        Time.map((value, index) => {
                            return (
                                <MenuItem key={index} value={value}>{value}</MenuItem>
                            )
                        })
                    }
                </Select>
            </Grid>
        </Grid>
    )
}