import {Navigate, useLocation} from 'react-router-dom'
import React from 'react';
// import {authenticateUser, checkLogin, isAuthenticatedSelector, validateLogin} from "./AuthSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

export default function PrivateRoute({children}) {

    let location = useLocation()
   
    
    return sessionStorage.getItem("email")=="true"&&sessionStorage.getItem("anonymous")=="false" ? children : <Navigate to="/" state={{from: location}} replace/>;
}