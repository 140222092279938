import React from "react";
import { Typography } from "antd";
import PropTypes from "prop-types";
import classNames from "classnames";

// import "./typography.scss";

const UITypography = ({ children, strong, type, className,italic,disabled,onClick,underline,level }) => {
    return (
        <Typography
        style={{color:sessionStorage.getItem("theme")=="dark"?'#DCDCDC':'#3A3A3A'}}
            strong={strong? 1 : 0}
            level={level}
            italic={italic? 1 : 0}
            disabled={disabled}
            onClick={onClick}
            underline={underline? 1 : 0}
            type={type}
            className={classNames([
                {[UITypography.styles.title]: true},
                {[className]: className}
            ])}
        >
            {children}
        </Typography>
    );
};

UITypography.propTypes = {
    children: PropTypes.any,
    strong: PropTypes.bool,
    type: PropTypes.string,
    underline:PropTypes.bool,
    disabled: PropTypes.bool,
    italic:PropTypes.bool,
    level:PropTypes.string
};

UITypography.defaultProps = {
    children: undefined,
    strong: false,
    type: "secondary",
    italic:false,
    disabled:false,
    underline:false,
    level:"h1"
};

UITypography.types = {
    secondary: "secondary",
    success: "success",
    danger: "danger",
    warning: "warning",
};
UITypography.levels = {
    h1:"h1",
    h2:"h2",
    h3:"h3",
    h4:"h4",
    h5:"h5"
}

UITypography.styles = {
    title: "title",
};

export default UITypography;
