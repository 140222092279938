//@flow
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    ButtonGroup,
    Dialog,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
// import {AddSharp} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "./alertsSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
// import  {AlertConfigData, Auth} from "../../lib/url";


import { makeStyles } from "@material-ui/core/styles";
const theme=sessionStorage.getItem("theme")



const useStyles = makeStyles({
  select: {
    '&.Mui-disabled':{
        position:'relative',
        "-webkit-text-fill-color":'#DCDCDC !important',
        color: `#DCDCDC !important`
    },
    '&.MuiSelect-nativeInput':{
        backgroundColor:'red'
    }
  },

});

const textFieldColor = '#DCDCDC'
const textFieldSX = {
    input: {
        "-webkit-text-fill-color": `${textFieldColor} !important`,
        color: `${textFieldColor} !important`,

    },
    "& label": {
       color:theme=="dark"?'#DCDCDC':'#000',
      },
};
const CssTextField = withStyles({
    root: {
      
        "& label.Mui-disabled": {
            color: "#DCDCDC",
            
        },
    },
})(TextField);


function Target({ isWindowForNew, id, alertTarget, clusters = [], onChange, onRemove,index }) {
    const [disabled, isDisabled] = useState(!isWindowForNew);
    const [targetId, setTargetId] = useState("");
    const [type, setType] = useState("Type");
    const [authKey, setAuthKey] = useState("");
    const [targetUrl, setTargetUrl] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [clusterId, setClusterId] = useState(clusters.length === 0 ? -1 : clusters[0].id);
    const classes = useStyles();
    useEffect(() => {
        if (isWindowForNew) {
            setTargetId(id);
        } else {
            setTargetId(alertTarget.targetId);
            setType(alertTarget.type);
            setAuthKey(alertTarget.authKey);
            setTargetUrl(alertTarget.targetUrl);
            setTitle(alertTarget.title);
            setDescription(alertTarget.description);
        }
    }, [isWindowForNew, targetId, alertTarget]);

    function handleFormChange(event) {
        if (disabled) return;
        switch (event.target.name) {
            case "type":
                setType(event.target.value);
                break;
            case "authKey":
                // type=="SLACK"?setAuthKey("NA"):
                console.log(event.target.value);
                setAuthKey(event.target.value);
                break;
            case "targetUrl":
                setTargetUrl(event.target.value);
                break;
            case "title":
                setTitle(event.target.value);
                break;
            case "description":
                setDescription(event.target.value);
                break;
            case "clusterId":
                console.log(event.target.value);
                break;
            default:
                return;
        }
    }

    return (
        <>
            <Box>
                <form
                    onChange={(event) => {
                        handleFormChange(event);
                    }}
                >
                    {isWindowForNew && (
                        <Select
                            name={"clusterId"}
                            value={clusterId}
                            label="ClusterId"
                            sx={{color:theme=="dark"?'#FFF':'#000'}}
                            onChange={(event, child) => {
                                setClusterId(event.target.value);
                            }}
                            fullWidth
                            size={"small"}
                        >
                            {clusters.length === 0 ? (
                                <MenuItem value={-1} disabled>
                                    No Cluster
                                </MenuItem>
                            ) : (
                                clusters.map((cluster, index) => {
                                    return (
                                        <MenuItem key={index} value={cluster.id}>
                                            {cluster.cluster_name}
                                        </MenuItem>
                                    );
                                })
                            )}
                        </Select>
                    )}
                    <CssTextField
                      sx={textFieldSX}
                        required={true}
                        label={"title"}
                        size={"small"}
                        margin={"normal"}
                        disabled={disabled}
                        fullWidth
                        name={"title"}
                        value={title}
                    ></CssTextField>
                    <CssTextField
                      sx={textFieldSX}
                        required={true}
                        label={"description"}
                        size={"small"}
                        margin={"normal"}
                        disabled={disabled}
                        fullWidth
                        name={"description"}
                        value={description}
                    ></CssTextField>
                    <FormControl fullWidth style={{color:theme=="dark"?'#FFF':'#000'}} >
                        <InputLabel style={{color:theme=="dark"?'#FFF':'#000'}}>Type</InputLabel>

                        <Select
                            className={classes.select}
                            classes={{disabled:classes.select}}
                            placeholder="Type"
                            disabled={disabled}
                            defaultValue={"SLACK"}
                            required={true}
                            label={"type"}
                            style={{color:'#DCDCDC'}}
                            size={"small"}
                            margin={"normal"}
                            fullWidth
                            name={"type"}
                            value={type}
                            >
                            <MenuItem value={"SLACK"} onClick={() => setType("SLACK")}>
                                Slack
                            </MenuItem>
                        </Select>
                    </FormControl>
                    {/* <TextField required={true} label={"type"} size={"small"} margin={"normal"} disabled={disabled} fullWidth name={'type'}
                           value={type}></TextField> */}
                    <CssTextField
                      sx={textFieldSX}
                      
                        required={true}
                        label={authKey==''?"authKey Eg: xynnnqor":"authKey"}

                        size={"small"}
                        margin={"normal"}
                        disabled={disabled || type == "SLACK"}
                        fullWidth
                        name={"authKey"}
                        value={type != "SLACK" ? authKey : "NA"}
                    ></CssTextField>
                    <CssTextField
                      sx={textFieldSX}
                        required={true}
                        label={targetUrl==''?"Target Url Eg: /callback?message":"targetUrl"}
                        size={"small"}
                        margin={"normal"}
                        disabled={disabled}
                        fullWidth
                        name={"targetUrl"}
                        value={targetUrl}
                    ></CssTextField>
                </form>
            </Box>
            <Box display={"flex"} justifyContent={"flex-end"}>
                {/* <ButtonGroup> */}

                {!isWindowForNew && disabled && index!=0 && (
                    <Button
                        sx={{
                            color: "#000",
                            border: "#DD0C4E 1px solid",
                            width: "100px",
                            marginRight: "1.5rem",
                            backgroundColor:theme=="dark"?"#DCDCDC":"#FFF"
                        }}
                        onClick={() => isDisabled(false)}
                    >
                        Edit
                    </Button>
                )}
                {!disabled && (
                    <Button
                        sx={{
                            color: "#000",
                            border: "#DD0C4E 1px solid",
                            width: "100px",
                            marginRight: "1.5rem",
                            backgroundColor:theme=="dark"?"#DCDCDC":"#FFF"
                        }}
                        onClick={() => {
                            if (isWindowForNew) {
                                onChange({
                                    clusterId,
                                    targetId,
                                    type,
                                    authKey,
                                    targetUrl,
                                    title,
                                    description,
                                });
                                return;
                            }
                            onChange({ targetId, type, authKey, targetUrl, title, description });
                            isDisabled(true);
                        }}
                    >
                        Save
                    </Button>
                )}
                {!isWindowForNew && index!=0 &&(
                    <Button
                        sx={{
                            color: "#000",
                            border: "#DD0C4E 1px solid",
                            width: "100px",
                            marginRight: "1.5rem",
                            backgroundColor:theme=="dark"?"#DCDCDC":"#FFF"
                        }}
                        color={"warning"}
                        startIcon={<DeleteSharpIcon />}
                        onClick={() => {
                            onRemove({ targetId, type, authKey, targetUrl, title, description });
                        }}
                    >
                        Delete
                    </Button>
                )}
                {/* </ButtonGroup> */}
            </Box>
        </>
    );
}

export function CreateAlertTargetDialog({
    targetId,
    clusters = [],
    open,
    onClose,
    onSave,
    onRemove,
}) {
    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    onClose();
                }}
            >
                <DialogTitle sx={{backgroundColor:theme=="dark"?'#555353':'#FFF', color:theme=="dark"?'#FFF':'#000'}}>Create New Alert Target</DialogTitle>
                <Box p={1} sx={{backgroundColor:theme=="dark"?'#555353':'#FFF', color:theme=="dark"?'#FFF':'#000'}}>
                    <Target
                        isWindowForNew={true}
                        clusters={clusters}
                        id={targetId}
                        onChange={(target) => {
                            onSave(target);
                        }}
                        onRemove={(target) => {
                            onRemove(target);
                        }}
                    ></Target>
                </Box>
            </Dialog>
        </>
    );
}

export default function AlertTargets({ alertConfig, auth }) {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(0);

    const targets = alertConfig.targets;
    // const [closeExpanded, setCloseExpanded] = useState(false)
    // console.log(targets.length)
    // console.log(targets)
    const [open, setOpen] = useState(false);
    return (
        <>
            {targets !== undefined &&
                targets.map((target, index) => {
                    return (
                        <Box key={index}>
                            <Accordion sx={{backgroundColor:theme=="dark"?'#555353':'#FFF', color:theme=="dark"?'#FFF':'#000'}} expanded={index == expanded} key={index}>
                                {/* {console.log(expanded, closeExpanded )} */}
                                {/* setCloseExpanded(targets.length),expanded==index?setCloseExpanded(index):'' */}
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            onClick={() =>
                                                expanded == index
                                                    ? setExpanded(targets.length)
                                                    : setExpanded(index)
                                            }
                                        />
                                    }
                                >
                                    ${target.title}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Target
                                        isWindowForNew={false}
                                        index={target.targetId=='sample'?0:1}
                                        onRemove={(target) => {
                                            let updatedConfig = { ...alertConfig };
                                            updatedConfig.targets = updatedConfig.targets.filter(
                                                (t, idx) => {
                                                    console.log(idx !== index);
                                                    return idx !== index;
                                                },
                                            );
                                            // console.log( updatedConfig)
                                            dispatch(
                                                updateConfig({
                                                    auth: auth,
                                                    alertConfig: updatedConfig,
                                                }),
                                            );
                                            //  dispatch(updateConfig(auth, updatedConfig))
                                        }}
                                        alertTarget={target}
                                        onChange={(alertTarget) => {
                                            let updatedConfig = { ...alertConfig };
                                            console.log(alertTarget);
                                            console.log(
                                                updatedConfig,
                                                updatedConfig.targets,
                                                typeof updatedConfig.targets,
                                            );
                                            updatedConfig.targets = alertTarget;
                                            console.log(updatedConfig);
                                            // updatedConfig.targets.splice(index,1,alertTarget)
                                            dispatch(
                                                updateConfig({
                                                    auth: auth,
                                                    alertConfig: updatedConfig,
                                                }),
                                            );

                                            //  dispatch(auth, updatedConfig)
                                        }}
                                    ></Target>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    );
                })}
            <CreateAlertTargetDialog
                targetId={"some_id"}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                onSave={(target) => {
                    let updatedConfig = { ...alertConfig };
                    let targets = [...updatedConfig.targets];
                    targets.push(target);
                    dispatch(updateConfig);
                }}
            ></CreateAlertTargetDialog>
        </>
    );
}
