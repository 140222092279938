import AuthenticationActionTypes from "./AuthenticationActionTypes";
import _ from "lodash";


const initialState = {
    email: "",
    token: "",
    expiry: "",

    fetching: false,
    fetched: false,
    showDialog: false,
    route: "/",

    profileDetails: [],
    companyDetails: [],
    inviteDetails: [],
    authenticated: false,
    registered: false,
    subscribed: false,

    verified: false,
    inviteverified: false,
    companyinviteverified: false,
    userRole: "",
    orgName: "",
    orgUrl: "",
    Role: "",
    plans: [],
};

const authenticationReducer = (state = initialState, action) => {
    switch (action.type) {
        case AuthenticationActionTypes.FETCH_DATA:
            return {
                ...state,
            };

        case AuthenticationActionTypes.SET_REGISTRATION_DETAILS:
            return {
                ...state,
                authenticated: action.payload.authenticated,
                registered: action.payload.registered,
            };
        //changed
        case AuthenticationActionTypes.SET_SESSION:
           console.log(action.payload.licenseProfiles)
            return action.payload.licenseProfiles!=undefined ? action.payload.licenseProfiles != []
                ? {
                      ...state,
                      profileDetails: action.payload.userProfile,
                      companyDetails: action.payload.companyProfile,
                      authenticated: action.payload.authenticated,
                      registered: action.payload.registered,
                      subscribed: action.payload.subscribed,
                      userRole: action.payload.userProfile
                          ? action.payload.userProfile.userRole
                          : "",
                      licenseDetails: action.payload.licenseProfiles,
                      plans: action.payload.licenseProfiles
                          ? action.payload.licenseProfiles.map((i) => i.planId)
                          : "",
                  }
                : action.payload.userProfile? {
                      ...state,
                      profileDetails: action.payload.userProfile,
                      companyDetails: action.payload.companyProfile,
                      authenticated: action.payload.authenticated,
                      registered: action.payload.registered,
                      subscribed: action.payload.subscribed,
                      userRole: action.payload.userProfile.userRole,
                  }:'':{
                    ...state,
                    profileDetails: action.payload.userProfile,
                    companyDetails: action.payload.companyProfile,
                    authenticated: action.payload.authenticated,
                    registered: action.payload.registered,
                    subscribed: action.payload.subscribed,
                    
                };

        case AuthenticationActionTypes.VERIFICATION_SUCCESS:
            return {
                ...state,
                inviteverified: true,
                orgName: action.payload.company.companyName,
                orgUrl: action.payload.company.companyUrl,
                userRole: action.payload.invite.userRole,
                companyDetails: action.payload.company,
                inviteDetails: action.payload.invite,
            };

        case AuthenticationActionTypes.VERIFICATION_FAILED:
            return { ...state, inviteverified: false, orgName: "", orgUrl: "", userRole: "" };

        case AuthenticationActionTypes.COMPANY_VERIFICATION_SUCCESS:
            return {
                ...state,
                companyinviteverified: true,
                orgName: action.payload.company.companyName,
                orgUrl: action.payload.company.companyUrl,
                userRole: action.payload.invite.userRole,
                companyDetails: action.payload.company,
                inviteDetails: action.payload.invite,
            };

        case AuthenticationActionTypes.COMPANY_VERIFICATION_FAILED:
            return {
                ...state,
                companyinviteverified: false,
                orgName: "",
                orgUrl: "",
                userRole: "",
            };

        // case AuthenticationActionTypes.SET_OTP_STATUS:
        //     return {
        //         ...state,
        //         isOtpGenerated: action.payload.msg === "otp sent",
        //     };
        // case AuthenticationActionTypes.ON_INVALID_OTP:
        //     return {
        //         ...state,
        //         isOtpExpired: true,
        //     };
        case AuthenticationActionTypes.SET_REGISTRATION_DETAILS:
            return {
                ...state,
                userId: _.get(action, "payload.uuid", ""),
                email: _.get(action, "payload.attributes.email", ""),
            };
        case AuthenticationActionTypes.UNAUTH_USER:
            return {
                ...state,
                ...initialState,
            };
        case AuthenticationActionTypes.FETCHING:
            return {
                ...state,
                fetching: true,
            };
        case AuthenticationActionTypes.VERIFIED:
            return {
                ...state,
                verified: true,
            };
        case AuthenticationActionTypes.FETCHING_SUCCESS:
            return {
                ...state,
                fetching: false,
                fetched: true,
                showDialog: true,
            };
        case AuthenticationActionTypes.FETCHING_FAILED:
            return {
                ...state,
                fetching: true,
                fetched: false,
                showDialog: true,
            };
        case AuthenticationActionTypes.CLOSE_DIALOG:
            return {
                ...state,
                fetching: false,
                fetched: false,
                showDialog: false,
                
            };
        // case AuthenticationActionTypes.CLOSE_DIALOG:
        //     return {
        //         ...state,
        //         fetching: false,
        //         fetched: false,
        //         showDialog: false,
        //     };
        case AuthenticationActionTypes.SET_ROUTE:
            // console.log(action, action.payload);
            return {
                ...state,
                route: _.get(action, "payload.route"),
            };
        default:
            return state;
    }
};

export default authenticationReducer;
