import Highlighter from "react-highlight-words";
import {Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow,IconButton,Box} from "@mui/material";
import {DateTime} from "luxon";
import {useSelector} from "react-redux";
import {selectFilters, selectQuery} from "../features/explorer/explorerSlice";
import React, {useEffect, useState} from "react";
import {tokenize} from "../lib/queryUtils";
import './Highlighter.scss'
const theme=sessionStorage.getItem("theme")

export function Log({log}) {
    const query = useSelector(selectQuery)
    const filters = useSelector(selectFilters)
    const [tokens, setTokens] = useState([])
    useEffect(() => {
        let ex = tokenize(filters, query)
        if (ex!==undefined)
            setTokens(ex)
    }, [query, filters])

    return (
        <Box style={{zIndex:30,position:'relative' }} sx={{whiteSpace:'pre-wrap',backgroundColor:"transparent",color:'#787878'}} >
            {/* theme=="dark"?"#fff":"#000" */}
            <Highlighter 
             className="highlighter-pre"
                searchWords={tokens}
                autoEscape={true}
                textToHighlight={log}
            />
        </Box>
    )
}

export default function LogsTableVIew({logs}) {
    let theme = sessionStorage.getItem("theme")
    return <div style={{backgroundColor:'transparent',position:'relative'}}>
        <Table color={"#000"} size={"small"} padding={"none"} sx={{
            [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
            }
        }} className="logs-table">
            <colgroup>
                <col width='20%'/>
                <col width='80%'/>
            </colgroup>
            <TableHead>
                <TableRow>
                    <TableCell style={{color:theme=="dark"?'#FFF':'#000'}}>_ts</TableCell>
                    <TableCell style={{color:theme=="dark"?'#FFF':'#000'}}>Message</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    logs.map((log, index) => {
                        return log.logs.map((value, logIndex) => {
                            return <TableRow key={`${index}-${logIndex}`}  className={value.log.startsWith("ERROR")?"table-row-logs-red":(value.log.startsWith("INFO - WARN")||(value.log.includes('WARNING')))?'table-row-logs-yellow':'table-row-logs-green'}>
                                <TableCell style={{color:theme=="dark"?'#FFF':'#000'}} >
                                    <p style={{color:theme=="dark"?'#DCDCDC':'#8D918D'}}>{DateTime.fromMillis(value._ts / 1000000).toFormat('yy LLL dd HH:mm:s')}</p>
                                </TableCell>
                                <TableCell style={{color:theme=="dark"?'#FFF':'#000'}}>
                                    <Log log={value.log}/>
                                </TableCell>
                            </TableRow>
                        })
                    })
                }
            </TableBody>
        </Table>
    </div>
}