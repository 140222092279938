import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Form } from "antd";
import Dialog from "../../../components/lib/dialogs/SimpleDialog";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import UIButton from "../../../components/lib/button/Button";
import Typography from "../../../components/lib/typography/typography";
import Input from "../../../components/lib/input/input";
import Wrapper from "./Wrapper";

import VERBIAGE from "../../../../../utils/VERBIAGE";
import AuthenticationActions from "../../redux/AuthenticationActions";
import Logo from "../common/Logo";
import Footer from "../common/Footer";
// import GoogleSignin from "../../../../../assets/flat-color-icons_google.svg";
import GoogleSignin from "../../../../../assets/g.png";
import AuthContext from "./Auth";

import {
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    sendSignInLinkToEmail,
    signInWithEmailLink,
    isSignInWithEmailLink,
} from "firebase/auth";

const provider = new GoogleAuthProvider();

import "../styles/login.scss";

let id,
    segments,
    url = location.pathname;
let req_header;
const Login = (props) => {
    

    const {
        authentication,
        validateCredentials,
        getCredentials,
        setPreviousRoute,
        invitedUser,
        verifyInvitedCompany,
        clusterList,
        history
    } = props;
    const [loggedIn, setLoggedIn] = useState(false);
    const [invite, setInvite] = useState(false);
    const [form] = Form.useForm();
    const [domainInvalid, setDomainInvalid] = useState(false);
    const [inviteModal, setInviteModal] = useState(false);
    const [emailIds, setEmailId] = useState(null)
    const [tokens, setToken] = useState(null);
    const [userIds, setUserId] = useState(null);
    let { token, emailId, userId } = useContext(AuthContext);
     
   let navigate=useNavigate();
    const auth = getAuth();
    let header = {
        token,
        userId,
        emailId,
    };

    useEffect(() => {
        checkRoute();
    }, []);
    useEffect(() => (loggedIn ? "" : validating()), []);
    useEffect(() => getCredentials(header), []);
    useEffect(() => registerOrDashboard(), []); 

    const checkRoute = () => {
        getCredentials(header);
        // getUrlDetails()
        if (
            sessionStorage.getItem("logout") == "false" &&
            sessionStorage.getItem("registered") &&
            authentication.registered == undefined &&
            !url.includes("/join")
        ) {
            console.log("if");
           navigate(VERBIAGE.NAVIGATION.PATHS.REGISTER);
        } else if (authentication.registered && sessionStorage.getItem("Name")) {
            console.log("if-1");
           navigate(VERBIAGE.NAVIGATION.PATHS.CRMDASHBOARD);
        }
    };
    const getUrlDetails = () => {
        url = location.pathname;
        localStorage.setItem("inviteToken", "");

        if (url.includes("/joincompany")) {
            // window.location.reload();
            // history.goBack();
            segments = url.split("/");
            id = segments[segments.length - 1];
            localStorage.setItem("inviteToken", id);
            if (!invite) {
                console.log("if-2");
                verifyInvitedCompany(header);
                setInvite(true);
            }
            localStorage.setItem("tokenType", "company");
        } else if (url.includes("/join/")) {
            segments = url.split("/");
            id = segments[segments.length - 1];
            localStorage.setItem("inviteToken", id);
            localStorage.setItem("tokenType", "user");
            if (!invite) {
                invitedUser(header);
                setInvite(true);
            }
        }
        localStorage.getItem("inviteToken");
    };

    const signIn = () => {
        const auth = getAuth();

        signInWithPopup(auth, provider)
            .then((result) => {
                const user = result.user;

                sessionStorage.setItem("Name", user.displayName);
                localStorage.setItem("inviteToken", id);
                getUrlDetails();
                req_header = {
                    token: result.user.accessToken,
                    emailId: result.user.email,
                    userId: result.user.uid,
                };
                header = req_header;
                // console.log(header);
                if (
                    sessionStorage.getItem("logout") == "false" &&
                    authentication.registered == undefined
                    //&& !url.includes("/join")
                ) {
                   navigate(VERBIAGE.NAVIGATION.PATHS.REGISTER);
                } else if (authentication.registered) {
                   navigate(VERBIAGE.NAVIGATION.PATHS.CRMDASHBOARD);
                }
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    };

   

    const registerOrDashboard = () => {
        // console.log(authentication.authenticated)
        if (authentication.companyinviteverified) {
            // console.log(localStorage.getItem("ID"))
            if (
                localStorage.getItem("ID").includes(authentication.inviteDetails.validDomains)
            ) {
               navigate(VERBIAGE.NAVIGATION.PATHS.REGISTER);
            } else {
                setDomainInvalid(true);
            }
        } else if (authentication.inviteverified) {
           navigate(VERBIAGE.NAVIGATION.PATHS.REGISTER);
        }

        if (authentication.authenticated) {
            if (
                //    header.token && header.emailId && header.userId &&
                !authentication.registered
            ) {
               
               navigate(VERBIAGE.NAVIGATION.PATHS.REGISTER);
            } else if (authentication.registered && authentication.authenticated) {
               
               navigate(VERBIAGE.NAVIGATION.PATHS.CRMDASHBOARD);
            }
        }
    };

    const validating = () => {
        // const auth = getAuth();

        if (isSignInWithEmailLink(auth, window.location.href)) {
            getUrlDetails();

            let email = localStorage.getItem("ID");
            if (!email) {
                email = window.prompt("Please provide your email for confirmation");
            }

            // The client SDK will parse the code from the link for you.
            signInWithEmailLink(auth, email, window.location.href)
                .then((result) => {
                    sessionStorage.setItem("Name", result.user.displayName);
                    getUrlDetails();
                    // getCredentials();
                    req_header = {
                        Token: result.user.accessToken,
                        emailId: result.user.email,
                        userId: result.user.uid,
                    };
                    header = req_header;
                    console.log( window.location.search)
                    if (
                        // window.location.search.includes("apiKey") &&
                        //  header
                        sessionStorage.getItem("logout") == "false"
                    ) {
                        getCredentials(req_header);

                       navigate(VERBIAGE.NAVIGATION.PATHS.REGISTER);
                    }

                })
                .catch((error) => {});
        }
    };

    const onFinish = async () => {
        form.validateFields().then((values) => {
            const auth = getAuth();
            const actionCodeSettings = {
                // url: "https://spike.episilia.com/",
                url: location.href,
                android: undefined,
                iOS: undefined,
                handleCodeInApp: true,
                dynamicLinkDomain: undefined,
            };

            sendSignInLinkToEmail(auth, values.Email, actionCodeSettings)
                .then(() => {
                    localStorage.setItem("ID", values.Email);
                    setInviteModal(true);
                    validating();
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                });
        });
    };

    const setTextWithRoute = () => {
        setPreviousRoute(props);
    };

    useEffect(() => setTextWithRoute(), [authentication.route]);

    useEffect(() => validateCredentials(), [validateCredentials]);

    return (
        <Wrapper>
            {/* {getUrlDetails()} */}
            <div className={Login.styles.container}>
                <div className="logo-in-header">
                    <Logo />
                </div>

                <div className={Login.styles.leftSection}>
                    <div className={Login.styles.header}>
                        <Typography level="h5" className={Login.styles.title}>
                            {authentication.route === VERBIAGE.NAVIGATION.PATHS.REGISTER
                                ? VERBIAGE.TITLE.REGISTER
                                : VERBIAGE.TITLE.HOME}
                        </Typography>
                        <Typography type="secondary" className={Login.styles.subTitle}>
                            {VERBIAGE.SUB_TITLE.HOME}
                        </Typography>
                    </div>

                    <div className={Login.styles.Inputform}>
                        <div className="google-button-div">
                            <button
                                className={Login.styles.button2}
                                style={{ height: 35 }}
                                onClick={() => signIn()}
                            >
                                <img
                                    src={GoogleSignin}
                                    size="contain"
                                    className="google-signIn-img"
                                    // width={30}
                                    // height={20}
                                    // style={{aspectRatio:1/1}}
                                />
                                Log In With Google
                            </button>
                        </div>

                        <p style={{ textAlign: "center", marginTop: 25, marginLeft: "0%" }}>OR</p>

                        <Form
                            layout="vertical"
                            className={Login.styles.form}
                            initialValues={{
                                Email: "",
                            }}
                            form={form}
                        >
                            <Form.Item
                                className={Login.styles.label}
                                wrapperCol={{ span: 24 }}
                                name="Email"
                                label="Email"
                                rules={[
                                    { required: true },
                                    { type: "email", message: "Please enter valid Email" },
                                ]}
                            >
                                <Input
                                    placeholder={VERBIAGE.PLACEHOLDERS.EMAIL}
                                    className={Login.styles.inputText}
                                    data-testid="emailInput"
                                />
                            </Form.Item>
                            <Form.Item wrapperCol={{ span: 24 }}>
                                <UIButton
                                    className={Login.styles.button1}
                                    htmlType="submit"
                                    data-testid="getOtp"
                                    onClick={() => onFinish()}
                                >
                                    {VERBIAGE.BUTTONS.GET_OTP}
                                </UIButton>
                            </Form.Item>
                        </Form>

                        <div className="login-footer-position">

                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                isModalVisible={inviteModal}
                onClose={() => setInviteModal(false)}
                title="Email sent"
                content="Invite link has been sent to your email"
                variant="success"
                actionText={"OK"}
                onActionClick={() => setInviteModal(false)}
            />
            {domainInvalid ? (
                <Dialog
                    isModalVisible={domainInvalid}
                    onClose={() => setDomainInvalid(false)}
                    title="Error"
                    content="Invalid domain"
                    variant="error"
                    actionText={VERBIAGE.BUTTONS.TRY_AGAIN}
                    onActionClick={() => setDomainInvalid(false)}
                />
            ) : (
                ""
            )}
        </Wrapper>
    );
};

Login.propTypes = {
    validateCredentials: PropTypes.func.isRequired,
    getCredentials: PropTypes.func.isRequired,
};

Login.styles = {
    container: "login-page-container",
    leftSection: "login-page-container__left-section",
    header: "login-page-container__left-section__header",
    title: "login-page-container__left-section__header__title",
    subTitle: "login-page-container__left-section__header__sub-title",
    Inputform: "login-page-container__left-section__form",
    form: "login-page-container__left-section__form__form-container",
    label: "login-page-container__left-section__form__label",
    inputText: "login-page-container__left-section__form__text-field",
    button1: "login-page-container__left-section__form__btn-1",
    button2: "login-page-container__left-section__form__btn-2",
    noAcc: "login-page-container__left-section__form__na-acc",
    noAccText: "login-page-container__left-section__form__na-acc__text",
    noAccLink: "login-page-container__left-section__form__na-acc__link",
};

const mapStateToProps = (state) => ({
    authentication: state.authentication,
    clusterList: state.dashboard.clusterList,
});

const mapDispatchToProps = (dispatch) => ({
    validateCredentials: () => {
        dispatch(AuthenticationActions.validateCredentials());
    },
    getCredentials: (header) => {
        dispatch(AuthenticationActions.getCredentials(header));
    },
    setPreviousRoute: (props) => {
        dispatch(AuthenticationActions.setPreviousRoute(props));
    },
    invitedUser: (credential) => {
        dispatch(AuthenticationActions.invitedUser(credential));
    },
    verifyInvitedCompany: (header) => {
        dispatch(AuthenticationActions.verifyInvitedCompany(header));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)((Login));
