
import React, {  useState, useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";


let theme = sessionStorage.getItem("theme");
const Loading = ({
 
}) => {
    let navigate = useNavigate();
   const [loading,setLoading] = useState(true);
   let count=1

   useEffect(()=>{
    setTimeout(()=>(setLoading(false),navigate(-1)),500)}
    ,[])
    return (
        <div>
 {console.log(location.pathname)}
          { loading && <LoadingOutlined />}
          {/* {!loading && count==1 ? (navigate(-1) , count=2):''} */}
        </div>
    );
};


export default (Loading);
