import React,{useEffect, useState} from "react";
import {Box, Button, Popover, Tab, Tabs, TextField,IconButton} from "@mui/material";
import {getTimeDiffToCurrent, TimeUnits} from "../lib/dateTimeUtils";
import {DateTimePicker, LocalizationProvider, StaticDateTimePicker, TabContext, TabPanel} from "@mui/lab";
import DateTimeInput from "./DateTimeInput";
import AdapterDateFns from '@mui/lab/AdapterLuxon';
import {DateTime, Duration} from "luxon";
import CloseIcon from '@mui/icons-material/Close';
// import { Close } from "@mui/icons-material";

export default function DateTimeSelector({ timeRecord, onDateTimeChange }) {
  const [tabIndex, setTabIndex] = useState("0");
  const [anchorEle, setAnchorEle] = useState(null);
  const [dateTimeLabel, setDateTimeLabel] = useState(
    getTimeDiffToCurrent(timeRecord.epoch)
  );
  let panelSections = ["Relative", "Absolute", "Current"];

  useEffect(() => {
    setDateTimeLabel(getTimeDiffToCurrent(timeRecord.epoch));
  }, [timeRecord]);
   (() => {
    if ((timeRecord.type === "Relative" || timeRecord.type === "Current") &&  sessionStorage.getItem("date")=="clicked") {
      let interval = setInterval(() => {
        onDateTimeChange({
          ...timeRecord,
          epoch:
            timeRecord.type === "Relative"
              ? DateTime.now()
                  .minus(
                    Duration.fromObject({
                      [timeRecord.unit]: timeRecord.interval,
                    })
                  )
                  .toMillis()
              : DateTime.now().toMillis(),
        });
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [timeRecord, tabIndex]);
  return (
    <>
      <Box>
        <Button
          onClick={(event) => {
            setAnchorEle(event.target);
           sessionStorage.setItem("date","clicked")
          }}
          fullWidth
          sx={{ height: 40, textTransform: "lowercase" ,
          // backgroundImage:"linear-gradient( to top right, #29C2B6, #2CD97B )",
        }}
          variant={"outlined"}
        >
          {dateTimeLabel}
        </Button>
      </Box>

      <Popover
        open={anchorEle != null}
        anchorEl={anchorEle}
        onClose={() => setAnchorEle(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <IconButton
          style={{
            cursor: "pointer",
            float: "right",
            marginTop: "5px",
            marginRight: "2px",
            width: "20px",
          }}
          onClick={() => {
            setAnchorEle(null);
            sessionStorage.setItem("date","done")
          }}
        >
          <CloseIcon />
        </IconButton>
        <TabContext value={tabIndex}>
          <Box p={"1rem"}>
            <Tabs
              value={tabIndex.toString()}
              onChange={(change, index) => {
                setTabIndex(index.toString());
              }}
            >
              {panelSections.map((value, index) => {
                return (
                  <Tab key={index} label={value} value={index.toString()} />
                );
              })}
            </Tabs>
          </Box>
          <TabPanel value={"0"} index={0}>
            <DateTimeInput
              timeRecord={timeRecord}
              onChange={onDateTimeChange}
            />
          </TabPanel>

          <TabPanel value={"1"} index={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="DateTime"
                value={DateTime.fromMillis(timeRecord.epoch)}
                onChange={(newValue) => {
                  
                  let change = {
                    ...timeRecord,
                    type: panelSections[1],
                    epoch: newValue.toMillis(),
                  };
                  onDateTimeChange(change);
                }}
                renderInput={(params) => (
                  <TextField fullWidth size={"small"} {...params} />
                )}
              />
            </LocalizationProvider>
          </TabPanel>
          <TabPanel value={"2"} index={2}>
            <Button
              fullWidth
              variant={"contained"}
              onClick={() => {
                
                let change = {
                  ...timeRecord,
                  type: "Current",
                  epoch: DateTime.now().toMillis(),
                };
                console.log(change);
                onDateTimeChange(change);
                sessionStorage.setItem("date","done")
              }}
            >
              Current Time
            </Button>
          </TabPanel>
        </TabContext>
      </Popover>
    </>
  );
}
