import _ from "lodash";
import CpanelActionTypes from "./CpanelActionTypes";


const initialState = {


  liveClusterDetails: {},
  ServerDetail: {},
 
};

const CpanelReducer = (state = initialState, action) => {
  switch (action.type) {
 

  

    case CpanelActionTypes.LIVE_CLUSTER_DETAILS:
      return { ...state, liveClusterDetails: action.payload };

    case CpanelActionTypes.SERVER_DETAILS_CPANEL:
      return { ...state, ServerDetail: action.payload };

    default:
      return state;
  }
};

export default CpanelReducer;
