/* eslint-disable no-param-reassign */
/**
 * This is a file is a global request-response handler.
 * Do not edit this file unless a global change is required.
 */

import axios from "axios";
// import {store} from "../Store";
//  import AuthenticationActionTypes from '../authentication/redux/AuthenticationActionTypes';

const api = axios.create();

api.defaults.headers.post["Content-Type"] = "application/json";

api.interceptors.request.use(
  (config) => {
    config.withCredentials = false;
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }

    if (response.status === 403) {
      // Handle Forbidden
      localStorage.clear();
      // store.dispatch({ type: "UNAUTH_USER" });
    }
    const error = new Error(response.status);
    error.response = response;
    throw error;
  },
  (error) => {
    if (error.response.status === 403) {
      // Handle Forbidden
      localStorage.clear();
      console.log("Unauthorized User");
      //    store.dispatch({ type: AuthenticationActionTypes.UNAUTH_USER })
    }
    else if (error.response.status === 500) {
      // Handle Forbidden
     //  localStorage.clear();
      console.error("Internal Storage error");
    //  store.dispatch({ type: AuthenticationActionTypes.UNAUTH_USER })
    }

    Promise.reject(error);
  }
  // Do something with response error
);

export default api;
