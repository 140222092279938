import QueryForm from "../../component/QueryForm";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "antd";
import {
    acquireLabels,
    acquireQueries,
    addQuery,
    fetchLogs,
    onFiltersAdded,
    onFiltersRemoved,
    onFromDateTimeRangeChange,
    onQueryChange,
    onToDateTimeRangeChange,
    selectClusterId,
    selectFilters,
    selectFrom,
    selectLabels,
    selectLoading,
    selectLogs,
    selectQuery,
    selectTo,
} from "./explorerSlice";
import { Box, Button, Divider, Grid, LinearProgress } from "@mui/material";
import React, { useEffect, useRef, useState, useContext } from "react";
import LabelForm from "../../component/LabelForm";
import LogsTableVIew from "../../component/LogsTableVIew";
import StatsGraph from "../../component/StatsGraph";
import SideLabelPanel from "../../component/SideLabelPanel";
import NoLogs from "../../component/NoLogs";
import Tail from "./Tail";
// import { SaveSharp } from "@mui/icons-material";
import AuthContext from "../../../../../../authentication/views/components/Auth";
import QuerySaveModal from "../../component/QuerySaveModal";
import { reconstructQuery } from "../../lib/queryUtils";
import { initialiseConfig } from "../alerts/alertsSlice";
import { useNavigate } from "react-router-dom";
import VERBIAGE from "../../../../../../../../utils/VERBIAGE";
import background from "../../../../../../../../assets/BG.jpg";
import darkbackground from "../../../../../../../../assets/BGDARK.jpg";

export default function Explorer() {
    const { token, emailId, userId } = useContext(AuthContext);

    const dispatch = useDispatch();
    // const uid = useSelector(uidSelector)
    // const token = useSelector(tokenSelector)
    // const email = useSelector(emailSelector)
    const query = useSelector(selectQuery);
    const labels = useSelector(selectLabels);
    const filters = useSelector(selectFilters);
    const logs = useSelector(selectLogs);
    const loading = useSelector(selectLoading);
    const selectedCluster = useSelector(selectClusterId);
    const [logTableHeight, setLogTableHeight] = useState(0);
    const [tailing, setTailing] = useState(false);
    const [tailPause, setTailPause] = useState(false);
    const [logWindow, setLogWindow] = useState("search");
    const [saveQueryModal, setOpenQuerySaveModal] = useState(false);
    const userId2 = sessionStorage.getItem("userId");
    const fromTimeRecord = useSelector(selectFrom);
    const toTimeRecord = useSelector(selectTo);
    const node = useRef(null);
    let theme = sessionStorage.getItem("theme");

    let navigate = useNavigate();

    useEffect(() => {
        if (selectedCluster !== -1 || selectedCluster !== undefined)
            if (node !== null && node.current !== null) {
                let height = window.innerHeight - node?.current.clientHeight - 10;
                setLogTableHeight(height);
                setLogTableHeight('82vh')
            }
    }, [selectedCluster, node]);

    useEffect(async () => {
        dispatch(initialiseConfig({ "auth-token": token, email: emailId, uid: userId }));
    }, []);
    useEffect(() => {
        if (selectedCluster !== -1 && selectedCluster !== undefined) {
            dispatch(
                acquireLabels({
                    auth: { uid: userId, "auth-token": token, email: emailId },
                    clusterId: selectedCluster,
                }),
            );
        }
    }, [selectedCluster, dispatch, userId, emailId, token]);
    useEffect(() => {
        dispatch(acquireQueries({ auth: { uid: userId, email: emailId, "auth-token": token } }));
    }, [dispatch, userId, emailId, token]);


    // useEffect(() => {
       
    //     const interval = setInterval(() => {
    //         theme = sessionStorage.getItem("theme");
           
    //     }, 500);

    //     return () => clearInterval(interval);
    // }, []);

    useEffect(() => {
        if (sessionStorage.getItem("reset") == "true") {
            setTailing(false);
            setLogWindow("search");
           
            // logs = useSelector(selectLogs);
        }
        theme = sessionStorage.getItem("theme");
    });
    return window.screen.width > 900 ? (
        <div>
            {/* {theme == "dark" ? (
                <div
                className="bg-dark-search"
                   
                ></div>
            ) : (
                <img src={background} className="bg-img-search"></img>
            )} */}
              <img src={theme=="dark"?darkbackground:background} className={theme=="dark"?"bg-dark-search":"bg-img-search"} style={{marginTop:selectedCluster === -1 || selectedCluster === undefined ?'-16em':''}}></img>

            {selectedCluster === -1 || selectedCluster === undefined ? (
                <Grid
                    className="no-subscription-messagebox"
                    style={{ textAlign: "center", marginLeft: "40%", color:theme=="dark"?'white':'black' , position:'relative'}}
                >
                    No active Clusters!
                    <br />
                    <a
                        style={{ color: "#DD0C4E" }}
                        onClick={() => navigate(VERBIAGE.NAVIGATION.PATHS.CRMDASHBOARD)}
                    >
                        Click here &nbsp;
                    </a>
                     to create cluster
                </Grid>
            ) : (
                <>
                    <Grid
                        container
                        spacing={2}
                        paddingTop={"0.5rem"}
                        // paddingRight={"0.5rem"}
                        marginLeft={"5em"}
                        marginTop={"10px"}
                        sx={{ width: "fit-content" }}
                    >
                        <Grid item sm={10}>
                            <Box ref={node}>
                                <QueryForm
                                    fromTimeRecord={fromTimeRecord}
                                    toTimeRecord={toTimeRecord}
                                    query={query}
                                    tailing={tailing}
                                    filters={filters}
                                    labels={labels}
                                    onTailing={() => {
                                        setTailing(true);
                                        setLogWindow("tail");
                                        sessionStorage.setItem("tailPaused", "connected");
                                        sessionStorage.setItem("reset", "false");
                                    }}
                                    onStopTailing={() => {
                                        setTailing(false);
                                        setLogWindow("search");
                                        sessionStorage.setItem("tailPaused", "disconnected");
                                    }}
                                    onpauseTailing={() => {
                                        setLogWindow("tail");
                                        setTailPause(!tailPause);
                                        !tailPause
                                            ? sessionStorage.setItem("tailPaused", "paused")
                                            : sessionStorage.setItem("tailPaused", "resumed");
                                    }}
                                    onResumeTailing={() => {
                                        setLogWindow("tail");
                                        setTailPause(true);
                                    }}
                                    onQueryChange={(change) => dispatch(onQueryChange(change))}
                                    onFromDateChange={(change) => {
                                        dispatch(onFromDateTimeRangeChange(change));
                                    }}
                                    onToDateChange={(change) =>
                                        dispatch(onToDateTimeRangeChange(change))
                                    }
                                    onSearchClicked={() => {
                                        
                                        (sessionStorage.setItem("reset","false"));
                              
                                       if (logWindow === "tail" || tailing) {
                                            setTailing(false);
                                            setLogWindow("search");
                                        }

                                        dispatch(
                                            fetchLogs({
                                                auth: {
                                                    uid: userId,
                                                    "auth-token": token,
                                                    email: emailId,
                                                },
                                                clusterId: selectedCluster,
                                                start: fromTimeRecord.epoch,
                                                end: toTimeRecord.epoch,
                                                query: query,
                                                limit: 1000,
                                            }),
                                        );
                                        
                                    
                                   
                                    }}
                                />
                            </Box>
                            {loading && <LinearProgress sx={{ width: "90vw" }} />}

                            {/* <Divider sx={{marginTop: '0.5rem'}}/> */}
                            {logWindow === "search" && (
                                <>
                                    {/* {console.log(sessionStorage.getItem("reset")==null)} */}
                                    {
                                        // sessionStorage.getItem("reset") != "true" ? (
                                        //     <Box
                                        //         marginTop={"0.5rem"}
                                        //         className="box-graph-logs"
                                        //         sx={{
                                        //             maxHeight: '75%',
                                        //             overflow: "auto",
                                        //             borderWidth: 1,
                                        //             borderColor: "black",
                                        //             borderStyle: "solid",
                                        //             paddingLeft: 1,
                                        //             paddingTop: 5,
                                        //         }}
                                        //         height={logTableHeight}
                                        //     >
                                        //         <StatsGraph logs={logs} />
                                        //         <LogsTableVIew logs={logs} />
                                        //     </Box>
                                        // ) :

                                        sessionStorage.getItem("reset") == null ? (
                                            <NoLogs />
                                        ) : logs?.length > 0 &&
                                          sessionStorage.getItem("reset") != "true" ? (
                                            
                                            <Box
                                                marginTop={"0.5rem"}
                                                className="box-graph-logs"
                                                sx={{
                                                   
                                                    overflow: "auto",
                                                    zIndex:150,
                                                    position:'relative',
                                                    borderWidth: 1,
                                                    borderColor: "black",
                                                    borderStyle: "solid",
                                                    paddingLeft: -1,
                                                    paddingTop: 5,
                                                    background: "none",
                                                }}
                                                 height={logTableHeight}
                                            >
                                              
                                                <StatsGraph logs={logs} />
                                                
                                                <LogsTableVIew logs={logs} />
                                               
                                            </Box>
                                        ) : (
                                           
                                            <Box
                                                marginTop={"0.5rem"}
                                                className="box-graph-logs"
                                                sx={{
                                                    maxHeight: "75%",
                                                    // overflow:'scroll',
                                                    borderWidth: 2,
                                                    borderColor: "#1c1850",
                                                    borderStyle: "solid",
                                                    paddingLeft: 1,
                                                    paddingTop: 5,
                                                    backgroundColor:theme=="dark"?'#D0D0D0':''
                                                }}
                                                height={logTableHeight}
                                            >
                                                <StatsGraph logs={[]} />
                                                <LogsTableVIew logs={[]} />
                                            </Box>
                                        )
                                    }
                                </>
                            )}
                            {logWindow === "tail" && sessionStorage.getItem("reset") != "true" && (
                                <>
                                    <Tail
                                        maxHeight={logTableHeight}
                                        query={reconstructQuery(filters, query)}
                                        token={token}
                                        userId={userId}
                                        emailId={emailId}
                                    />
                                </>
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
            {/* <QuerySaveModal userId={userId} open={saveQueryModal} query={query} filters={filters}
                            onSave={(queryId, query, queryName, userId, shared) => {
                                dispatch(addQuery({
                                    auth: {uid: uid, email: email, 'auth-token': token}, userQuery: {
                                        query: query,
                                        queryName: queryName,
                                        userId: userId,
                                        shared: shared,
                                        tags: "",
                                    }
                                }))
                            }} onClose={() => {
                setOpenQuerySaveModal(false)
            }}/> */}
        </div>
    ) : (
        <div className="no-logs-card">
            <Card title={" "}>
                <p>Use desktop to start your search </p>
            </Card>
        </div>
    );
}
