export function reconstructQuery(filters, queryExpression) {
    let query = "";
    let extracted = extractFilters(filters, queryExpression, 0);
    let localFilters = extracted.filters;
    let expression = extracted.expression;
    let value = [],
        keys = [];
    // let reset=false;
    let operation = "=";

    if (localFilters === undefined) {
        return queryExpression;
    }
    for (let filterIndex = 0; filterIndex < localFilters.length; filterIndex++) {
        if (filterIndex === 0) {
            query = query + "{";
        } else {
            query = query + ",";
        }
        let filter = localFilters[filterIndex];
        keys.push(filter.filterKey);
        value.push(filter.filterValue);

        let x = new Set(keys);
        if (x.size == keys.length) {
            query = query + `${filter.filterKey}${filter.op}"${filter.filterValue}"`;
            // console.log("😎if", keys);
        } else if (
            keys[keys.length - 1].includes("__app__") ||
            keys[keys.length - 1].includes("__tenant__")
        ) {
            query = "";
            //    reset=true;
            keys = [];
            value = [];
            query = `{${filter.filterKey}${filter.op}"${filter.filterValue}"`;

            keys.push(filter.filterKey);
            value.push(filter.filterValue);
        } else if (filter.op == "!=") {
            let x = query.split(",");
            // sliced part of the query
            let y = "";
            // final query
            let z = "";
            x.map((item, index) =>
                item.includes(`${keys[keys.length - 1]}!`) &&
                !item.includes(`${keys[keys.length - 1]}=`)
                    ? index == 0
                        ? ((y = item.slice(keys[keys.length - 1].length + 2, item.length)),
                          y.includes("~")
                              ? (z = item.replace(
                                    y,
                                    `~${item.slice(
                                        keys[keys.length - 1].length + 3,
                                        item.length - 1,
                                    )}|${value[value.length - 1]}\"`,
                                ))
                              : (z = item.replace(
                                    y,
                                    `~${item.slice(
                                        keys[keys.length - 1].length + 3,
                                        item.length - 1,
                                    )}|${value[value.length - 1]}\"`,
                                )),
                          (query = query.replace(item, z)))
                        : ((y = item.slice(keys[keys.length - 1].length + 1, item.length)),
                          y.includes("~")
                              ? (z = item.replace(
                                    y,
                                    `~${item.slice(
                                        keys[keys.length - 1].length + 2,
                                        item.length - 1,
                                    )}|${value[value.length - 1]}\"`,
                                ))
                              : (z = item.replace(
                                    y,
                                    `~${item.slice(
                                        keys[keys.length - 1].length + 2,
                                        item.length - 1,
                                    )}|${value[value.length - 1]}\"`,
                                )),
                          (query = query.replace(item, z)))
                    : item.includes(`${keys[keys.length - 1]}=`) &&
                      !query.includes(`${keys[keys.length - 1]}!`)
                    ? (
                      (query += `${filter.filterKey}${filter.op}"${filter.filterValue}"`))
                    : console.log("😎else-90"),
            );

            keys.pop();
            value.pop();
            x = new Set(keys);
        } else {
            let x = query.split(",");
            // sliced part of the query
            let y = "";
            // final query
            let z = "";
            x.map((item, index) =>
                item.includes(`${keys[keys.length - 1]}=`) && !item.includes(`${keys[keys.length - 1]}!`)
                    ? index == 0
                        ? ((y = item.slice(keys[keys.length - 1].length + 2, item.length)),
                          y.includes("~")
                              ? (z = item.replace(
                                    y,
                                    `~${item.slice(
                                        keys[keys.length - 1].length + 3,
                                        item.length - 1,
                                    )}|${value[value.length - 1]}\"`,
                                ))
                              : (z = item.replace(
                                    y,
                                    `~\"${item.slice(
                                        keys[keys.length - 1].length + 3,
                                        item.length - 1,
                                    )}|${value[value.length - 1]}\"`,
                                )),
                          (query = query.replace(item, z)))
                        : (
                          (y = item.slice(keys[keys.length - 1].length + 1, item.length)),
                          y.includes("~")
                              ? (z = item.replace(
                                    y,
                                    `~${item.slice(
                                        keys[keys.length - 1].length + 2,
                                        item.length - 1,
                                    )}|${value[value.length - 1]}\"`,
                                ))
                              : (z = item.replace(
                                    y,
                                    `~\"${item.slice(
                                        keys[keys.length - 1].length + 2,
                                        item.length - 1,
                                    )}|${value[value.length - 1]}\"`,
                                )),
                          (query = query.replace(item, z)))
                    : item.includes(`${keys[keys.length - 1]}!`) &&
                      !query.includes(`${keys[keys.length - 1]}=`)
                    ? (console.log("😎else-149"),
                      (query += `${filter.filterKey}${filter.op}"${filter.filterValue}"`))
                    : console.log("😎else-150"),
            );

            // (query = query + z)
            keys.pop();
            value.pop();
            x = new Set(keys);
        }
        if (filterIndex === localFilters.length - 1) {
            query = query + "}";
        }
        operation = filter.op;
    }
    query = query + expression;

    if (query.endsWith(",}")) {
        console.log(query);
        // removes }
        query = query.substring(0, query.length - 1);
        query = query.slice(0, -1);
        console.log(query);
        while (query.endsWith(",")) {
            query = query.substring(0, query.length - 1);
            query.slice(0, -1);
        }
        while (query.includes(",,")) query = query.replace(",,", ",");
        return `${query}}`;
    } else if (query.includes(",,")) {
        while (query.includes(",,")) query = query.replace(",,", ",");

        return query;
    } else {
        while (query.includes(",,")) query = query.replace(",,", ",");
        return query;
    }
}

export function extractFilters(filters, query, pos) {
    let labelStart = false;
    let inQuotes = false;
    let localFilters = [...filters];
    let expression = "";
    for (let index = pos; index < query.length; index++) {
        let char = query.charAt(index);
        switch (char) {
            case "{": {
                if (index === 0 && char === "{") {
                    labelStart = true;
                }
                if (labelStart) {
                    let { filter, skipIndex } = interpolateFilter(query, index);
                    if (skipIndex !== -1 && filter !== undefined) {
                        if (
                            localFilters.find((value) => {
                                return value === filter;
                            }) !== undefined
                        ) {
                            localFilters.push(filter);
                        }

                        index = skipIndex;
                    }
                }
                break;
            }
            case "}": {
                if (labelStart && !inQuotes) {
                    labelStart = false;
                    if (index !== query.length - 1) {
                        expression = query.substring(index + 1, query.length);
                        index = index + expression.length;
                    }
                }
                break;
            }
            case ",": {
                let { filter, skipIndex } = interpolateFilter(query, index);
                if (skipIndex !== -1 && filter !== undefined) {
                    if (
                        localFilters.find((value) => {
                            return value === filter;
                        }) !== undefined
                    ) {
                        localFilters.push(filter);
                    }
                    index = skipIndex;
                    continue;
                }
                break;
            }

            default:
                switch (char) {
                    case " ":
                    case "|":
                    case "!":
                        if (index === 0) {
                            expression = query;
                        }
                }
        }
    }
    if (localFilters === undefined) {
        return { filters: undefined, expression: expression };
    }
    return { filters: localFilters, expression: expression };
}

export function tokenize(filters, query) {
    let extracted = extractFilters(filters, query, 0);
    let expression = extracted.expression;
    if (expression.length > 1) {
        let tokens = expression.split(/\W+/);
        return tokens.filter((value) => {
            return value.length > 0;
        });
    }
    return [];
}

export function interpolateFilter(query, pos) {
    let inQuotes = false;
    for (let index = pos; index < query.length; index++) {
        let char = query.charAt(index);

        // eslint-disable-next-line default-case
        switch (char) {
            case '"': {
                inQuotes = !inQuotes;
                break;
            }
            case "=":
                if (!inQuotes) {
                    index = index + 1;
                    let value = extractLabelValue(query, pos, index - 1, index);

                    return value;
                }
            case "~":
            case "!": {
                if (!inQuotes) {
                    index = index + 1;
                    let value = extractLabelValue(query, pos, index - 1, index + 1);

                    return value;
                }
                break;
            }
        }
    }
    return { index: -1 };
}

function indexOf(query, pattern, pos) {
    for (let i = pos; i < query.length; i++) {
        let lengthCheck = query.length - i - pattern.length;
        if (lengthCheck > 0) {
            let subQuery = query.substring(i, i + pattern.length);
            if (subQuery === pattern) {
                return i;
            }
        }
    }
    return -1;
}

function extractLabelValue(query, pos, opStart, opEnd = opStart + 1) {
    console.log(
        `${query.substring(0, opStart)} op: ${query.substring(
            opStart,
            opEnd,
        )} value: ${query.substring(opEnd + 1, query.indexOf('"', opEnd + 1))}`,
    );
    let skipIndex = query.indexOf('"', opEnd);
    if (skipIndex === -1) {
        skipIndex = indexOf(query, '"', opEnd);
    }
    let subQuery = query.substring(opEnd, query.length);
    // console.log(subQuery)
    // console.log(skipIndex)
    return {
        filter: {
            op: query.substring(opStart, opEnd),
            filterKey: query.substring(pos + 1, opStart),
            filterValue: query.substring(opEnd + 1, query.indexOf('"', opEnd + 1)),
        },
        skipIndex: skipIndex,
    };
}
