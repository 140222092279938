import { Table, Tabs } from "antd";
import React, { useEffect, useState, useContext } from "react";
// import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { BarChartOutlined, DatabaseOutlined } from "@ant-design/icons";
import _ from "lodash";
import Chart from "./Charts";
import CpanelActions from "../../../redux/CpanelActions";
// import AuthContext from "../../CRM/authentication/views/components/Auth";

import Data from "./Data";
import "./data.scss";
import DashboardActions from "../../CRM/dashboard/redux/dashboardActions";
// let AuthContext
// (async () => {
//     (location.pathname.includes('spike'))?
//       AuthContext  = await import('../../CRM/authentication/views/components/Auth'):
//      AuthContext  = await import('../../CRM/authentication/views/components/Auth_Pulse')
//   })();
  
let dataNew = [{'report_time':20220211091000,'log_reader_rps':15139}];
let data=[];
let data2 = [];
let data3 = [];
let data4 = [];
const obj = {};
const obj2 = {};
const obj3 = {};
const obj4 = {};
const dataSource = [];

const { TabPane } = Tabs;

const ServerDetails = ({ serverDetails, details, clusterDetails, history,getClusterDetail }) => {
    // const { token, emailId, userId } = useContext(AuthContext);
    // let header = {
    //     token,
    //     userId,
    //     emailId,
    // };
    const id = useParams();
    const [clientId, setClientId] = useState("");
    const [env, setEnv] = useState("");
    const [release_version, setVersion] = useState("");
    const [start_ts, setStart_ts] = useState("");
    const [end_ts, setEnd_ts] = useState("");
    const navigate=useNavigate();

    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
        },
        { title: "Client Id", dataIndex: "client_id", key: "client_id" },
        { title: "Environment", dataIndex: "env", key: "env" },
        {
            title: "Release version",
            dataIndex: "release_version",
            key: "release_version",
        },
        { title: "Start time stamp", dataIndex: "start_time", key: "start_time" },
        { title: "Last time stamp", dataIndex: "last_time", key: "last_time" },
    ];

    useEffect(() => {
        if (location.pathname.includes("/spike")) {
            // serverDetails(id, "spike");
            console.log(id)
            getClusterDetail(id.id)
        } else {
            serverDetails( id, "pulse");
        }

        aboutCluster();
    }, []);

    const aboutCluster = () => {
        !_.isEmpty(clusterDetails) &&
            clusterDetails.map((item, index) =>
                item.id == id.id
                    ? (setClientId(item.client_id),
                      setEnv(item.env),
                      setVersion(item.release_version),
                      setStart_ts(item.start_time),
                      setEnd_ts(item.last_time))
                    : "",
            );

        // return (<Table pagination={false}  columns={columns} dataSource={dataSource}/>);

        // return ();
    };

    const getData = () => {
        (data = []), (data2 = []), (data3 = []), (data4 = []);
        // setTimeout(()=>{
        !_.isEmpty(details) &&
            details.indexerInfo.map((item, index) =>
                data.length <= index
                    ? ((obj["log_reader_rps"] = item.log_reader_rps),
                      (obj["report_time"] = item.report_time),
                      (obj["index"] = index),
                      data.push({ ...obj, obj }))
                    : "",
            ),
            !_.isEmpty(details.cpanelInfo) &&
                details.cpanelInfo.map((item1, index2) =>
                    data2.length <= index2
                        ? ((obj2["log_input_rps"] = item1.log_input_rps),
                          (obj2["log_ingest_rps"] = item1.log_ingest_rps),
                          (obj2["report_time"] = item1.report_time),
                          data2.push({ ...obj2, obj2 }),
                          // graph 2
                          (obj3["log_lag"] = item1.log_lag),
                          (obj3["report_time"] = item1.report_time),
                          data3.push({ ...obj3, obj3 }))
                        : "",
                );

        //Graph 3
        !_.isEmpty(details.gatewayInfo) &&
            details.gatewayInfo.map((i, index2) =>
                data4.length <= index2
                    ? ((obj4["searches_0sto5s_count"] = i.searches_0sto5s_count),
                      (obj4["searches_5sto10s_count"] = i.searches_5sto10s_count),
                      (obj4["searches_10sto20s_count"] = i.searches_10sto20s_count),
                      (obj4["searches_20sto40s_count"] = i.searches_20sto40s_count),
                      (obj4["searches_40s_plus_count"] = i.searches_40s_plus_count),
                      (obj4["report_time"] = i.report_time),
                      data4.push({ ...obj4, obj4 }))
                    : "",
            );
    };

    return (
        <Col md={12}>
            {getData()}
            <button onClick={() => navigate(-1)} className="back-button-to-dashboard">
                Back to dashboard
            </button>
            <Card style={{ marginLeft: '2em',backgroundColor:'white', backgroundImage:'none' }}>
                <CardHeader className="Header-details-card">
                    <p className="details-cluster" style={{ fontSize: 22 }}>
                        Cluster Details
                    </p>
                    <table>
                        <tbody>
                            <tr>
                                <td className="details-cluster"> Client Id: </td>
                                <td className="details-cluster-value">{clientId}</td>
                            </tr>
                            <tr>
                                <td className="details-cluster"> Environment: </td>
                                <td className="details-cluster-value">{env}</td>
                            </tr>
                            <tr>
                                <td className="details-cluster"> Release Version: </td>
                                <td className="details-cluster-value">{release_version}</td>
                            </tr>
                            <tr>
                                <td className="details-cluster"> Start Timestamp:</td>
                                <td className="details-cluster-value">{start_ts}</td>
                            </tr>
                            <tr>
                                <td className="details-cluster"> End Timestamp: </td>
                                <td className="details-cluster-value">{end_ts}</td>
                            </tr>
                        </tbody>
                    </table>
                </CardHeader>
                <CardBody>
                    {/* {aboutCluster()} */}
                    {/* <Card></Card> */}
                    <Tabs defaultActiveKey="1" centered>
                        <TabPane
                            tab={
                                <span style={{ fontFamily: "Poppins", fontSize: 14 }}>
                                    <BarChartOutlined />
                                    Visuals
                                </span>
                            }
                            key="1"
                        >
                            {getData()}
                            <Chart
                                data={data}
                                data2={data2}
                                data4={data3}
                                data5={data4}
                                len={data.length}
                                len2={data2.length}
                                len3={data3.length}
                                len4={data4.length}
                            />
                        </TabPane>
                        <TabPane
                            tab={
                                <span style={{ fontFamily: "Poppins", fontSize: 14 }}>
                                    <DatabaseOutlined />
                                    Data
                                </span>
                            }
                            key="2"
                        >
                            <Data />
                        </TabPane>
                    </Tabs>
                </CardBody>
            </Card>
        </Col>
    );
};

const mapStateToProps = (state) => ({
    details: state.cpanel.ServerDetail,
    clusterDetails: state.cpanel.liveClusterDetails,
    clusterDetails:state.dashboard.clusterList
});

const mapDispatchToProps = (dispatch) => ({
    serverDetails: ( id, name) => dispatch(CpanelActions.ServerDetails( id, name)),
    getClusterDetail:(id)=>dispatch(DashboardActions.getClusterDetail(id))
});

export default connect(mapStateToProps, mapDispatchToProps)((ServerDetails));
