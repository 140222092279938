import React, { useState, useEffect, useContext} from "react";
// import { withRouter } from "react-router-dom";
// import { faSave, faPencilAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Form } from "antd";
import _ from "lodash";
import { connect } from "react-redux";
import Typography from '../../../components/lib/typography/typography';
import Input from '../../../components/lib/input/input';
import Icon from '../../../components/lib/icon/icon';

import VERBIAGE from "../../../../../utils/VERBIAGE";
import DashboardActions from '../../redux/dashboardActions';
import Dialog from "../../../components/lib/dialogs/SimpleDialog";
import moment from "moment";
import AuthContext from "../../../authentication/views/components/Auth";
import "../styles/profile.scss";
import AuthenticationActions from "../../../authentication/redux/AuthenticationActions";

// const { Option } = Select;

const ProfileDetails = props => {
  const { authentication, dashboard, getCredentials, updateProfileDetails, resetFetched,userProfile } = props;
  const {token,emailId,userId}=useContext(AuthContext)
  let header={
       token,
      userId,
       emailId
  }
  const [isEditMode, setEditMode] = useState(false);
  const [form] = Form.useForm();

  const closeModal = () => {
    resetFetched();
    setEditMode(false)
  };


  const onFinish = (values) => {
    updateProfileDetails( { ...values } );
  };

  const setMode = () => {
    setEditMode(prev => !prev);
  }

  const submitForm = () => {
    form.submit();
    getCredentials(header)
    setMode()
    setEditMode(false)
  }

   useEffect(() => getCredentials(header), [isEditMode])



  return (
    <React.Fragment>
      <div className={ProfileDetails.styles.container}>
        <div className={ProfileDetails.styles.header}
          data-testid="profileDetails"
        >
          <Typography className={ProfileDetails.styles.title}>
            {VERBIAGE.TITLE.PROFILE_DETAILS}
          </Typography>
          {!isEditMode && <Button className={ProfileDetails.styles.button} 
          
            icon={ <Icon name="EditOutlined" type={Icon.type.antd} />}
          onClick={setMode}
            data-testid="edit"
          >
            {VERBIAGE.BUTTONS.EDIT}
          </Button>}
          {isEditMode && <div className={ProfileDetails.styles.buttonContainer}>
            <Button className={ProfileDetails.styles.button} 
              icon={ <Icon name="SaveOutlined" type={Icon.type.antd} />}
            
            onClick={submitForm}
              data-testid="save"
            >
              {VERBIAGE.BUTTONS.SAVE}
            </Button>
            <Button className={`${ProfileDetails.styles.button} ${ProfileDetails.styles.cancelButton}`}  
            icon={ <Icon name="CloseSquareOutlined" type={Icon.type.antd} />}
            
            onClick={setMode}
              data-testid="cancel"
            >
              {VERBIAGE.BUTTONS.CANCEL}
            </Button>
          </div>
          }
        </div>

        {!isEditMode && userProfile!=undefined && <div className={ProfileDetails.styles.table}
          data-testid="notEditMode"
        >
          <Typography className={ProfileDetails.styles.label}>
            {VERBIAGE.CAPS_LABELS.NAME}
          </Typography>
          <Typography className={ProfileDetails.styles.value}>
            {userProfile.userName}
          </Typography>
          <div className={ProfileDetails.styles.lineBreak}></div>

          <Typography className={ProfileDetails.styles.label}>
            {VERBIAGE.CAPS_LABELS.USERID}
          </Typography>
          <Typography className={ProfileDetails.styles.value}>
            {userProfile.userId}
          </Typography>
          <div className={ProfileDetails.styles.lineBreak}></div>

          <Typography className={ProfileDetails.styles.label}>
            {VERBIAGE.CAPS_LABELS.ROLE}
          </Typography>
          <Typography className={ProfileDetails.styles.value}>
            {userProfile.userRole}
          </Typography>
          <div className={ProfileDetails.styles.lineBreak}></div>


          <Typography className={ProfileDetails.styles.label}>
            {VERBIAGE.CAPS_LABELS.EMAIL}
          </Typography> 
       
          <Typography className={ProfileDetails.styles.value}>
            {userProfile.emailId}        
          </Typography>
          
          
          <div className={ProfileDetails.styles.lineBreak}></div>

          <Typography className={ProfileDetails.styles.label}>
            {VERBIAGE.CAPS_LABELS.MOBILE}
          </Typography>
          <Typography className={ProfileDetails.styles.value}>
            {userProfile.contactNo==""?"NA":userProfile.contactNo}
          </Typography>
          <div className={ProfileDetails.styles.lineBreak}></div>

          <Typography className={ProfileDetails.styles.label}>
            {VERBIAGE.CAPS_LABELS.START_DATE}
          </Typography>
          <Typography className={ProfileDetails.styles.value}>
            {moment(userProfile.createdAt).format(VERBIAGE.DATE.FORMAT)}
          </Typography>
          <div className={ProfileDetails.styles.lineBreak}></div>

          <Typography className={ProfileDetails.styles.label}>
            {VERBIAGE.CAPS_LABELS.UPDATED_DATE}
          </Typography>
          <Typography className={ProfileDetails.styles.value}>
            {moment(userProfile.updatedAt).format(VERBIAGE.DATE.FORMAT)}
          </Typography>
          <div className={ProfileDetails.styles.lineBreak}></div>

        </div>}

        {isEditMode && <Form layout="vertical" className={ProfileDetails.styles.form} onFinish={onFinish} form={form}
          initialValues={{
            emailId: userProfile.emailId,
            userName: userProfile.userName,
            contactNo: userProfile.contactNo,
            userRole: userProfile.userRole,
            userId:userProfile.userId,
            createdAt:userProfile.createdAt

          }}>
          <Form.Item className={ProfileDetails.styles.formLabel} wrapperCol={{ span: 24 }} name="userName" label={VERBIAGE.LABELS.NAME} rules={[{ required: true, message: 'Name is required' }]}>
            <Input
              placeholder={VERBIAGE.PLACEHOLDERS.NAME}
              className={ProfileDetails.styles.inputText}
            />
          </Form.Item>

          <Form.Item className={ProfileDetails.styles.formLabel} wrapperCol={{ span: 24 }} name="emailId" label={VERBIAGE.LABELS.EMAIL} rules={[{ required: true, message: 'Email is required' }]}>
            <Input disabled={true}
              placeholder={VERBIAGE.PLACEHOLDERS.EMAIL}
              className={ProfileDetails.styles.inputText}
            />
          </Form.Item>
          <Form.Item className={ProfileDetails.styles.formLabel} wrapperCol={{ span: 24 }} name="contactNo" label={VERBIAGE.LABELS.MOBILE} rules={[{ len: 10, message: 'please enter valid mobile no.' }]}>
            <Input
              placeholder={VERBIAGE.PLACEHOLDERS.MOBILE}
              className={ProfileDetails.styles.inputText}
            />
          </Form.Item>
          
        </Form>}
      </div>
      {!dashboard.profileUpdateDialog.fetching && dashboard.profileUpdateDialog.showDialog && <Dialog
        isModalVisible={!dashboard.profileUpdateDialog.fetching && dashboard.profileUpdateDialog.showDialog}
        onClose={closeModal}
        title={dashboard.profileUpdateDialog.fetched ? VERBIAGE.TEXT.UPDATE_SUCCESS : VERBIAGE.TEXT.UPDATE_FAILED}
        content={dashboard.profileUpdateDialog.fetched ? VERBIAGE.TEXT.UPDATE_PROFILE_SUCCESS : VERBIAGE.TEXT.GENERAL_FAILURE_MESSAGE}
        variant={dashboard.profileUpdateDialog.fetched ? "success" : "error"}
        actionText={dashboard.profileUpdateDialog.fetched ? VERBIAGE.BUTTONS.CONTINUE : VERBIAGE.BUTTONS.TRY_AGAIN}
        onActionClick={closeModal}
      />}
    </React.Fragment>
  );
};

ProfileDetails.propTypes = {

};

ProfileDetails.styles = {
  container: "profile-dts-container",
  header: "profile-dts-container__header",
  title: "profile-dts-container__header__title",
  buttonContainer: "profile-dts-container__header__btn-container",
  button: "profile-dts-container__header__btn-container__btn",
  cancelButton: "profile-dts-container__header__btn-container__cancel-btn",
  table: "profile-dts-container__dts-tbl",
  label: "profile-dts-container__dts-tbl__label",
  value: "profile-dts-container__dts-tbl__value",
  lineBreak: "profile-dts-container__dts-tbl__line-break",
  form: "profile-dts-container__form",
  formLabel: "profile-dts-container__form__label",
}

const mapStateToProps = state => ({
  authentication: state.authentication,
  dashboard: state.dashboard,
  userProfile: state.authentication.profileDetails,
});

const mapDispatchToProps = dispatch => ({
  getCredentials: (header) => {
    dispatch(AuthenticationActions.getCredentials(header));
  },
  updateProfileDetails: (payload) => {
    dispatch(DashboardActions.updateProfileDetails(payload));
  },
  resetFetched: (payload) => {
    dispatch(DashboardActions.resetFetched());
  }

});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((ProfileDetails));
