//@flow

// export const SPIKE_HOST = "pulse.episilia.net"
// export const TAIL_HOST = "ingress.episilia.net"
// window.location.href.includes("staging")?
// export const SPIKE_HOST = "dev-pulse.episilia.net";
// export const TAIL_HOST = "dev-ingress.episilia.net"

import UrlBuilder from "../../../../../../../utils/Url_builder";


export const SPIKE_HOST= window.location.href.includes("spike.episilia")?"pulse.episilia.net":"dev-pulse.episilia.net"
export const TAIL_HOST= window.location.href.includes("spike.episilia")?"ingress.episilia.net":"dev-ingress.episilia.net"

const allowHttps = true;

let protocol = `http${allowHttps ? "s" : ""}`;

let extractApiData = async (json) => {
    if ("success" in json && json.success) {
        if ("data" in json) {
            return json.data;
        } else {
            return Promise.reject(`expected data property in res ${JSON.stringify(json)}`);
        }
    } else {
        if ("errorId" in json && "message" in json) {
            return Promise.reject(
                `error on api data code: ${json.errorId} message: ${json.message}`,
            );
        } else {
            return Promise.reject(`error on api data ${JSON.stringify(json)}`);
        }
    }
};

function transformLogs(logs) {
    let logBlock = [];
    let result = logs.data.result;

    for (let logIndex in result) {
        let { stream, values } = result[logIndex];
        let logs = [];
        for (const logSection in values) {
            let value = values[logSection];
            logs.push({
                _ts: value[0],
                log: value[1],
            });
        }
        logBlock.push({
            labels: stream,
            logs: logs,
        });
        logBlock.sort((a, b) => {
            return b.logs[0]._ts - a.logs[0]._ts;
        });
        // console.log(logBlock.sort((a,b) => a._ts - b._ts))
    }
    return logBlock;
}

let extractLogsFromApi = async (json) => {
    try {
        let logResponse = await extractApiData(json);
        console.log("###################👿👿", logResponse)
        return transformLogs(logResponse);
    } catch (e) {
        return Promise.reject(e);
    }
};

export const URL = {



    SPIKE: {
        LABELS: "",
        LABEL_VALUES: "",
        QUERY: "",
        AUTH: {
            CURRENT_USER:UrlBuilder.build(`spike/user/current`)
            // CURRENT_USER: `${protocol}://${SPIKE_HOST}/spike/user/current`,
        },
        ALERTS: {
            CONFIG_FOR_CLUSTER_URL: function (clusterId) {
            return UrlBuilder.build(`spike/alert/config/get/${clusterId}`)
                // parseInt(sessionStorage.getItem("clusterId")
                // return `${protocol}://${SPIKE_HOST}/spike/alert/config/get/${clusterId}`;
            },
            // UPDATE_CONFIG_FOR_CLUSTER: `${protocol}://${SPIKE_HOST}/spike/alert/config/update`,
            UPDATE_CONFIG_FOR_CLUSTER:UrlBuilder.build(`spike/alert/config/update`)
        },
        CLUSTER: {
            // LIST: `${protocol}://${SPIKE_HOST}/spike/cluster/list`,
            LIST: UrlBuilder.build(`spike/cluster/list`)
        },
        SEARCH: {
            GET_LABELS_URL: (clusterId) => {
                return sessionStorage.getItem("self") == "true"
                    ? `/loki/api/v1/labels?${new URLSearchParams({
                          clusterId: clusterId,
                      })}`
                    : UrlBuilder.build(`spike/search/labels?${new URLSearchParams({
                        clusterId: clusterId,
                    })}`)
                    // `${protocol}://${SPIKE_HOST}/spike/search/labels?${new URLSearchParams({
                    //       clusterId: clusterId,
                    //   })}`;
            },
            GET_LABEL_VALUES: (clusterId, labelKey) => {
                return sessionStorage.getItem("self") == "true"
                    ? `/loki/api/v1/label/${labelKey}/values?${new URLSearchParams({
                          clusterId: clusterId,
                          labelKey: labelKey,
                      })}`
                    : UrlBuilder.build(`spike/search/labelvalues?${new URLSearchParams({
                        clusterId: clusterId,
                        labelKey: labelKey,
                    })}`)
                    
                    // `${protocol}://${SPIKE_HOST}/spike/search/labelvalues?${new URLSearchParams({
                    //       clusterId: clusterId,
                    //       labelKey: labelKey,
                    //   })}`;
            },
            QUERY_RANGE: (clusterId, query, limit = 100, start, end) => {
                return sessionStorage.getItem("self") == "true"
                    ? `/loki/api/v1/query_range?${new URLSearchParams({
                        clusterId: clusterId,
                        query: query,
                        limit: limit,
                        start: start * 1000000,
                        end: end * 1000000,
                    })}`: UrlBuilder.build(`spike/search?${new URLSearchParams({
                              clusterId: clusterId,
                              query: query,
                              limit: limit,
                              start: start * 1000000,
                              end: end * 1000000,
                          })}`)

                    // : `${protocol}://${SPIKE_HOST}/spike/search?${new URLSearchParams({
                    //       clusterId: clusterId,
                    //       query: query,
                    //       limit: limit,
                    //       start: start * 1000000,
                    //       end: end * 1000000,
                    //   })}`;
            },
        },
        QUERY_MANAGER: {
            // GET_LIST_URL: (userId) => {
            //     return `${protocol}://${SPIKE_HOST}/spike/query/list/${sessionStorage.getItem(
            //         "userId",
            //     )}`;
            // },
            // EDIT: `${protocol}://${SPIKE_HOST}/spike/query/edit`,
            // CREATE: `${protocol}://${SPIKE_HOST}/spike/query/add`,
            // DELETE: `${protocol}://${SPIKE_HOST}/spike/query/remove`,
            GET_LIST_URL: (userId) => {
                return UrlBuilder.build(`spike/query/list/${sessionStorage.getItem(
                    "userId",
                )}`);
            },
            EDIT: UrlBuilder.build(`spike/query/edit`),
            CREATE: UrlBuilder.build(`spike/query/add`),
            DELETE: UrlBuilder.build(`spike/query/remove`),
        },
    },
    GET_TAIL_URL: function (clusterName, query, ts) {
        return `ws${allowHttps ? "s" : ""}://${TAIL_HOST}/?query=${encodeURI(query)}${
            !isNaN(ts) ? "&&start=" + ts : ""
        }`;
    },
};
export const Clients = {
    AUTH: {
        CURRENT_USER: async (auth) => {
            try {
                let userResponse = await fetch(URL.SPIKE.AUTH.CURRENT_USER, {
                    headers: { ...auth },
                    method: "GET",
                });
                let json = await userResponse.json();
                console.log(json);
                let user = await extractApiData(json);
                console.log(user);
                return user;
            } catch (e) {
                return Promise.reject(e);
            }
        },
    },
    CLUSTER: {
        LIST: async (auth) => {
           
            return new Promise(async (resolve, reject) => {
                try {
                    let request = {
                        headers: {
                            ...auth,
                            "Content-Type": "application/json",
                        },
                        method: "GET",
                    };
                    let clusterRes = await fetch(URL.SPIKE.CLUSTER.LIST, request);
                    let clusters = await clusterRes.json();
                   
                    if (Array.isArray(clusters)) {
                        return resolve(clusters);
                    } else {
                        console.log(JSON.stringify(clusters));
                        let error = `expected response is of [] instead found ${JSON.stringify(
                            clusters,
                        )}`;
                        console.error(error);
                        return reject(error);
                    }
                } catch (err) {
                    return reject(err);
                }
            });
        },
    },
    ALERTS: {
        UPDATE: async (auth, alertConfig) => {
            console.log(alertConfig);
            return new Promise(async (resolve, reject) => {
                try {
                    let updateResponse = await fetch(URL.SPIKE.ALERTS.UPDATE_CONFIG_FOR_CLUSTER, {
                        headers: {
                            ...auth,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(alertConfig),
                        method: "POST",
                    });
                    return resolve(await updateResponse.json());
                } catch (err) {
                    return reject(err);
                }
            });
        },
        FETCH: async (auth, clusterId) => {
            return new Promise(async (resolve, reject) => {
                try {
                    let alertConfigResponse = await fetch(
                        URL.SPIKE.ALERTS.CONFIG_FOR_CLUSTER_URL(clusterId),
                        {
                            headers: { ...auth },
                            method: "GET",
                        },
                    );
                    let response = await alertConfigResponse.json();
                    if ("success" in response && response.success && "data" in response) {
                        resolve(response.data);
                    } else {
                        if ("errorId" in response && "message" in response) {
                            reject(response.message);
                        }
                    }
                } catch (e) {
                    reject(e);
                }
            });
        },
    },
    QUERY_MANAGER: {
        LIST: async (auth, userId) => {
            try {
                let input = URL.SPIKE.QUERY_MANAGER.GET_LIST_URL(sessionStorage.getItem("userId"));
              
                let listQueriesResponse = await fetch(input, {
                    headers: { ...auth },
                });
             
                if (listQueriesResponse.ok) {
                    let json = await listQueriesResponse.json();
                    return await extractApiData(json);
                } else {
                    return Promise.reject(`request failed with status ${listQueriesResponse.ok}`);
                }
            } catch (e) {
                return Promise.reject(e);
            }
        },
        ADD: async (auth, searchQuery) => {
         
            let createQueryResponse = await fetch(URL.SPIKE.QUERY_MANAGER.CREATE, {
                headers: { ...auth, "Content-Type": "application/json" },
                method: "POST",
                body: JSON.stringify(searchQuery),
            });
            if (createQueryResponse.ok) {
                let json = await createQueryResponse.json();
                if ("success" in json && json.success) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(
                        `error while adding query to user ${searchQuery} with error ${json.message}`,
                    );
                }
            }
        },
        EDIT: async (auth, searchQuery) => {
            let editQueryResponse = await fetch(URL.SPIKE.QUERY_MANAGER.EDIT, {
                headers: { ...auth, "Content-Type": "application/json" },
                method: "POST",
                body: JSON.stringify(searchQuery),
            });
            if (editQueryResponse.ok) {
                let json = await editQueryResponse.json();
                if ("success" in json && json.success) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(
                        `error while editing query to user ${searchQuery} with error ${json.message}`,
                    );
                }
            }
        },
        REMOVE: async (auth, searchQuery) => {
            let query = { ...searchQuery };
            if ("updatedAt" in query) {
                delete query.updatedAt;
            }
            let request = {
                headers: { ...auth, "Content-Type": "application/json" },
                method: "POST",
                body: JSON.stringify({ ...query }),
            };
            let removeQueryResponse = await fetch(URL.SPIKE.QUERY_MANAGER.DELETE, request);
            if (removeQueryResponse.ok) {
                let json = await removeQueryResponse.json();
                if ("success" in json && json.success) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(
                        `error while removing query to user ${searchQuery} with error ${json.message}`,
                    );
                }
            }
        },
    },
    SEARCH: {
        FETCH_LABELS: async (auth, clusterId) => {
            let labelsResponse = await fetch(URL.SPIKE.SEARCH.GET_LABELS_URL(clusterId), {
                headers: { ...auth },
                method: "GET",
            });
            if (labelsResponse.ok) {
                let json = await labelsResponse.json();
                return await extractApiData(json);
            } else {
                return Promise.reject(labelsResponse.statusText);
            }
        },
        FETCH_LABEL_VALUES: async (auth, clusterId, labelKey) => {
            let labelValueResponse = await fetch(
                URL.SPIKE.SEARCH.GET_LABEL_VALUES(clusterId, labelKey),
                {
                    headers: { ...auth },
                    method: "GET",
                },
            );
            if (labelValueResponse.ok) {
                let json = await labelValueResponse.json();
                return await extractApiData(json);
            } else {
                return Promise.reject(labelValueResponse.statusText);
            }
        },
        SEARCH_QUERY: async (auth, clusterId, query, start, end, limit) => {
            console.log(start,end)
          
            let queryResponse = await fetch(
                URL.SPIKE.SEARCH.QUERY_RANGE(clusterId, query, limit, start, end),
                {
                    headers: { ...auth },
                    method: "GET",
                },
            );
            if (queryResponse.ok) {
               
                let json = await queryResponse.json();
                if(json.success==false)
                {
                    return [];
                }
                return await extractLogsFromApi(json);
            } else {
                return Promise.reject(queryResponse.statusText);
            }
        },
    },
};
