import DashboardActionTypes from "./dashboardActionTypes";
import URLBuilder from "../../../../utils/Url_builder";
import _ from "lodash";

import api from "../../../../utils/API";
import URL from "../../../../utils/URL";
import CpanelActionTypes from "../../../../redux/CpanelActionTypes";
import header from "../../../../utils/Header";

let HeaderOptions;

const DashboardActions = {
    // setLicenseResponse: payload => ({
    //   type: DashboardActionTypes.SET_LICENSE,
    //   payload
    // }),

    // setNewLicenseResponse: payload => ({
    //   type: DashboardActionTypes.SET_NEW_LICENSE,
    //   payload
    // }),

    setIsMobileView: (payload) => ({
        type: DashboardActionTypes.SET_RESPONSIVE_VIEW,
        payload,
    }),

    setProfile: (payload) => ({
        type: DashboardActionTypes.SET_PROFILE_DETAILS,
        payload,
    }),

    resetFetched: () => ({
        type: DashboardActionTypes.CLOSE_DIALOG,
    }),

    // getLicenseKey: () => (dispatch) => {
    //   const { authentication } = store.getState();
    //   let url = URLBuilder.build(URL.DAHSBOARD.PROFILE.GET_LICENSE);
    //   url = url.replace('$token', localStorage.getItem("Token"));
    //   url = url.replace('$uuid', localStorage.getItem("userId"));
    //   api.get(url).then(response => {
    //     if (response.data)
    //       dispatch(DashboardActions.setLicenseResponse(response.data));
    //     else
    //       dispatch(DashboardActions.generateLicenseKey())
    //   }).catch(error => {
    //     dispatch(DashboardActions.generateLicenseKey())
    //   });
    // },

    // generateLicenseKey: () => (dispatch) => {
    //   const { authentication } = store.getState();
    //   let url = URLBuilder.build(URL.DAHSBOARD.PROFILE.GENERATE_LICENSE);
    //   url = url.replace('$token', localStorage.getItem("Token"));
    //   const userId = localStorage.getItem("userId");
    //   api.post(url, {
    //     uuid: userId,
    //     policy: VERBIAGE.PLANS.COMMUNITY_LOWERCASE
    //   }).then(response => {
    //     dispatch(DashboardActions.setNewLicenseResponse(response.data));
    //   });
    // },

    getUsersList: (header) => (dispatch) => {
        // const headers = header();
        // const headeroptions = { headers };
        const headers = { "auth-token": header.token, uid: header.userId, email: header.emailId };
        const headeroptions = { headers };
        HeaderOptions=headeroptions
        let url = URLBuilder.build(URL.DAHSBOARD.GETUSERSLIST);
        api.get(url, headeroptions)
            .then((response) => {
                if (response.data)
                    dispatch({ type: DashboardActionTypes.GET_LIST, payload: response.data.data });
                else console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    },

    getInvitedList: (header) => (dispatch) => {
        const headers = { "auth-token": header.token, uid: header.userId, email: header.emailId };
        const headeroptions = { headers };
        let url = URLBuilder.build(URL.DAHSBOARD.GET_INVITEDLIST);
        api.get(url, headeroptions)
            .then((response) => {
                if (response.data)
                    dispatch({
                        type: DashboardActionTypes.GET_INVITEDLIST,
                        payload: response.data.data,
                    });
                else console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    },

    getCompanyInvitedList: (header) => (dispatch) => {
        const headers = { "auth-token": header.token, uid: header.userId, email: header.emailId };
        const headeroptions = { headers };
        let url = URLBuilder.build(URL.DAHSBOARD.GETCOMPANYLIST);
        api.get(url, headeroptions)
            .then((response) => {
                if (response.data)
                    dispatch({
                        type: DashboardActionTypes.GET_COMAPANYINVITEDLIST,
                        payload: response.data.data,
                    });
                else console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    },

    sendInvite: (payload) => (dispatch) => {
        // const headers = { "auth-token": header.token, uid: header.userId, email: header.emailId };
        // const headeroptions = { headers };
        let url = URLBuilder.build(URL.DAHSBOARD.SENDINVITE);
        api.post(url, payload, HeaderOptions)
            .then((response) => {
                if (response == undefined) {
                    dispatch({ type: DashboardActionTypes.INVITE_SENT_FAILED });
                }
                if (response.data.data.status == "ACTIVE")
                    dispatch({
                        type: DashboardActionTypes.INVITE_SENT_SUCCESS,
                        payload: response.data.data,
                    });

                dispatch(DashboardActions.setProfile(response.data));
            })
            .catch((error) => {
                console.log(error);
            });
    },
    sendCompanyInvite: ( payload) => (dispatch) => {
        // const headers = { "auth-token": header.token, uid: header.userId, email: header.emailId };
        // const headeroptions = { headers };
        let url = URLBuilder.build(URL.DAHSBOARD.COMPANYINVITE);
        //console.log(url)
        api.post(url, payload, HeaderOptions)
            .then((response) => {
                if (response.data.data.inviteToken != "") {
                    dispatch({
                        type: DashboardActionTypes.COMPANY_INVITE_SENT_SUCCESS,
                        payload: response.data.data,
                    });
                } else {
                    dispatch({
                        type: DashboardActionTypes.COMPANY_INVITE_SENT_FAILED,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },
    updateProfileDetails: ( payload) => (dispatch) => {
        // const headers = { "auth-token": header.token, uid: header.userId, email: header.emailId };
        // const headeroptions = { headers };
        dispatch({ type: DashboardActionTypes.UPDATING });
        let url = URLBuilder.build(URL.DAHSBOARD.UPDATEPROFILE);
        api.post(url, payload, HeaderOptions)
            .then((response) => {
                if (response.data.success)
                    dispatch({ type: DashboardActionTypes.PROFILE_UPDATION_SUCCESS });
                else dispatch({ type: DashboardActionTypes.PROFILE_UPDATION_FAILED });
                dispatch(DashboardActions.setProfile(response.data));
            })
            .catch((error) => {
                dispatch({ type: DashboardActionTypes.FETCHING_FAILED });
            });
    },

    //To create a cluster
    createCluster: ( payload) => (dispatch) => {
     
        console.log(payload)
        let url = URLBuilder.build(URL.DAHSBOARD.CREATE_CLUSTER);
        api.post(url, payload, HeaderOptions)
            .then((response) => {
               
                if (response.data.success) {
                    dispatch({
                        type: DashboardActionTypes.CLUSTER_CREATED,
                        payload: response.data,
                    });

                } else {
                    dispatch({
                        type: DashboardActionTypes.CLUSTER_CREATION_FAILED,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },

    // to get live clusters
    getLiveClusters: (header) => (dispatch) => {
        const headers = { "auth-token": header.token, uid: header.userId, email: header.emailId };
        const headeroptions = { headers };
        HeaderOptions=headeroptions
        let url = URLBuilder.build(URL.DAHSBOARD.GET_LIVE_CLUSTERS);
        api.get(url, headeroptions)
            .then((response) => {
                // console.log(response)
                if (response.data) {
                    dispatch({
                        type: DashboardActionTypes.LIVE_CLUSTER_LIST,
                        payload: response.data.data,
                    });
                } else {
                    console.log(response);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },

    // getClustersList: (header) => (dispatch) => {
    //     const headers = { "auth-token": header.token, uid: header.userId, email: header.emailId };
    //     const headeroptions = { headers };
    //     let url = URLBuilder.build(URL.DAHSBOARD.GET_CLUSTERS_LIST);
    //     api.get(url, headeroptions)
    //         .then((response) => {
    //             // console.log(response);
               
    //             // console.log(JSON.parse(response));
    //             if (response.data) {
    //                 dispatch({ type: DashboardActionTypes.CLUSTER_LIST, payload: response.data });
    //             } 
    //             else if(response.errorId.includes("NOT_AUTHENTICATED"))
    //             {
    //                 sessionStorage.setItem("expired",true)
    //             }
    //             else {
    //                 dispatch({
    //                     type: DashboardActionTypes.CLUSTER_LIST_EMPTY,
    //                     payload: response.data,
    //                 });
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // },
    getClustersList: (header) => (dispatch) => {
        const headers = { "auth-token": header.token, uid: header.userId, email: header.emailId };
        const headeroptions = { headers };
        let url = URLBuilder.build(URL.DAHSBOARD.GET_CLUSTERS_LIST);
        api.get(url, headeroptions)
            .then((response) => {
                // console.log(response.data.success);
               
                // console.log(JSON.parse(response));
                
                if(response.data.errorId || response.data.success==false) 
                {
                     console.log("error")
                    sessionStorage.setItem("expired",true)
                }
                else if (response.data) {
                    //  console.log('data')
                    dispatch({ type: DashboardActionTypes.CLUSTER_LIST, payload: response.data });
                } 
                else {
                    dispatch({
                        type: DashboardActionTypes.CLUSTER_LIST_EMPTY,
                        payload: response.data,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },

    getClusterDetail: (id) => (dispatch) => {
        // const headers = { "auth-token": header.token, uid: header.userId, email: header.emailId };
        // const headeroptions = { headers };
        // console.log(id)
        let url = URLBuilder.build(URL.DAHSBOARD.GET_CLUSTER_DETAIL).replace('$id', id);
        
        api.get(url, HeaderOptions)
            .then((response) => {
               
                if (response.data) {
                    dispatch({
                      type: CpanelActionTypes.SERVER_DETAILS_CPANEL,
                      payload: response.data,
                    });
                  } else {
                    console.log("response", response);
                  }
            })
            .catch((error) => {
                console.log(error);
            });
    },


    deleteCluster:(id) => (dispatch) => {
        // const headers = { "auth-token": header.token, uid: header.userId, email: header.emailId };
        // const headeroptions = { headers };
        // console.log(id)
        let url = URLBuilder.build(URL.DAHSBOARD.DELETE_CLUSTER).replace('$clusterName', id);
        console.log(url)
        api.delete(url, HeaderOptions)
            .then((response) => {
               if(response.data.message="cluster deleted")
               {
                dispatch({
                          type: DashboardActionTypes.DELETE_CLUSTER,
                          payload: response.data,
                        });
               }
            })
            .catch((error) => {
                console.log(error);
            });
    },


    //  all the plans subscribed
    getSubscriptionPlanList: (header) => (dispatch) => {
        const headers = { "auth-token": header.token, uid: header.userId, email: header.emailId };
        const headeroptions = { headers };
        HeaderOptions=headeroptions
        let url = URLBuilder.build(URL.DAHSBOARD.SUBSCRIPTION_PLANS);
        api.get(url, headeroptions)
            .then((response) => {
                if (response.data) {
                    dispatch({
                        type: DashboardActionTypes.SUBSCRIPTION_PLANS_LIST,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },

    // get the subscribed plans
    getSubscribedPlans: (header) => (dispatch) => {
        const headers = { "auth-token": header.token, uid: header.userId, email: header.emailId };
        const headeroptions = { headers };
        HeaderOptions=headeroptions;
        let url = URLBuilder.build(URL.DAHSBOARD.SUBSCRIBED_LIST);
        api.get(url, headeroptions)
            .then((response) => {
                if (response.data) {
                    dispatch({
                        type: DashboardActionTypes.SUBSCRIBED_PLANS,
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },

    // subscribe to a plan
    SubscribeToPlan: (id) => (dispatch) => {
        let body = { planId: id };
        // const headers = { "auth-token": header.token, uid: header.userId, email: header.emailId };
        // const headeroptions = { headers };
        let url = URLBuilder.build(URL.DAHSBOARD.SUBSCRIBE_TO_PLAN);
        api.post(url, body, HeaderOptions)
            .then((response) => {
                if (response.data) {
                    dispatch({
                        type: DashboardActionTypes.SUBSCRIBE_A_PLAN,
                        payload: response.data,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },

    navigateToPath: (props, path) => {
        props.history.push(path);
    },
};

export default DashboardActions;
