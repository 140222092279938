import DashboardActionTypes from "./dashboardActionTypes";
import _ from "lodash";

const initialState = {
    active: false,
    key: "",
    policy: "",
    expiry: "",
    userId: "",
    name: "",
    email: "",
    phone: "",
    createdAt: "",
    client_id: "",
    company: "",
    fetching: false,
    fetched: false,

    showDialog_users: false,
    inviteSent: false,

    profileUpdateDialog: {
        fetching: false,
        fetched: false,
        showDialog: false,
    },
    listOfUsers: [],
    invitedUsers: [],
    listOfInvitedUsers: [],
    listOfCompanyInvites: [],

    companyInvite_Token: "",
    companyInvite: false,

    plans_subscribed: [],
    plans_available: [],
    subscribed_plan: [],

    cluster: false,
    clusterDetails: [],
    liveClusterList:[],
    clusters_available:false,
    clusterList:[],
    creation_message:"",
    subscribed_msg:"",
    delete:false,
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case DashboardActionTypes.SET_LICENSE:
            return {
                ...state,
                active: _.get(action, "payload.license.[0].active", ""),
                key: _.get(action, "payload.license.[0].key", ""),
                policy: _.capitalize(_.get(action, "payload.license.[0].policy", "")),
                expiry: _.get(action, "payload.license.[0].expiry", ""),
                userId: _.get(action, "payload.user.uuid", "") ? action.payload.user.uuid : "",
                createdAt: _.get(action, "payload.license.[0].created", ""),
            };
        case DashboardActionTypes.SET_NEW_LICENSE:
            return {
                ...state,
                active: _.get(action, "payload.license.active", ""),
                key: _.get(action, "payload.license.key", ""),
                policy: _.capitalize(_.get(action, "payload.license.policy", "")),
                expiry: _.get(action, "payload.license.expiry", ""),
                userId: _.get(action, "payload.user.uuid") ? action.payload.user.uuid : "",
                createdAt: _.get(action, "payload.license.created", ""),
            };
        case DashboardActionTypes.SET_RESPONSIVE_VIEW:
            return {
                ...state,
                isMobileView: action.payload,
            };
        case DashboardActionTypes.SET_PROFILE_DETAILS:
            return {
                ...state,
                email: _.get(action, "payload.attributes.email", ""),
                name: _.get(action, "payload.attributes.name", ""),
                company: _.get(action, "payload.attributes.company", ""),
                phone: _.get(action, "payload.attributes.phone"),
                client_id: _.get(action, "payload.client_id", ""),
            };
        case DashboardActionTypes.FETCHING:
            return {
                ...state,
                fetching: true,
            };
        case DashboardActionTypes.PROFILE_UPDATING:
            return {
                ...state,
                profileUpdateDialog: {
                    fetching: true,
                },
            };
        case DashboardActionTypes.FETCHING_SUCCESS:
            return {
                ...state,
                fetching: false,
                fetched: true,
                showDialog: true,
            };
        case DashboardActionTypes.PROFILE_UPDATION_SUCCESS:
            return {
                ...state,
                profileUpdateDialog: {
                    fetching: false,
                    fetched: true,
                    showDialog: true,
                },
            };
        case DashboardActionTypes.FETCHING_FAILED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                showDialog: true,
            };
        case DashboardActionTypes.PROFILE_UPDATION_FAILED:
            return {
                ...state,
                profileUpdateDialog: {
                    fetching: false,
                    fetched: false,
                    showDialog: true,
                },
            };
        case DashboardActionTypes.CLOSE_DIALOG:
            return {
                ...state,
                fetching: false,
                fetched: false,
                showDialog: false,
                showDialog_users: false,
                companyInvite: false,
                profileUpdateDialog: {
                    fetching: false,
                    fetched: false,
                    showDialog: false,
                },
            };

        case DashboardActionTypes.GET_LIST:
            return {
                ...state,
                listOfUsers: action.payload,
            };
        case DashboardActionTypes.GET_INVITEDLIST:
            return {
                ...state,
                listOfInvitedUsers: action.payload,
            };

        case DashboardActionTypes.GET_COMAPANYINVITEDLIST:
            return {
                ...state,
                listOfCompanyInvites: action.payload,
            };

        case DashboardActionTypes.INVITE_SENT_SUCCESS:
            return {
                ...state,
                invitedUsers: action.payload,
                inviteSent: true,
                showDialog_users: true,
            };

        case DashboardActionTypes.INVITE_SENT_FAILED:
            return { ...state, inviteSent: false, showDialog_users: true };

        case DashboardActionTypes.COMPANY_INVITE_SENT_SUCCESS:
            // console.log(action.payload.inviteToken);
            return {
                ...state,
                companyInvite: true,
                companyInvite_Token: action.payload.inviteToken,
            };

        case DashboardActionTypes.COMPANY_INVITE_SENT_FAILED:
            return { ...state, companyInvite: false };

        case DashboardActionTypes.CLUSTER_CREATED:
            return { ...state, clusterDetails: action.payload.data, cluster: true, creation_message:action.payload.message };

        case DashboardActionTypes.CLUSTER_CREATION_FAILED:
            return { ...state, cluster: false };
            
        case DashboardActionTypes.LIVE_CLUSTER_LIST:
            return { ...state, liveClusterList: action.payload};

        case DashboardActionTypes.CLUSTER_LIST:
            // console.log(action.payload.length)
            return { ...state, clusterList: action.payload, clusters_available:action.payload.length!=0};

        case DashboardActionTypes.CLUSTER_LIST_EMPTY:
            return { ...state, clusterList: action.payload,clusters_available:false};

        case DashboardActionTypes.SUBSCRIPTION_PLANS_LIST:
            // console.log(action.payload);
            return {
                ...state,
                plans_available  : action.payload,
            };
        case DashboardActionTypes.SUBSCRIBED_PLANS:
            // console.log(action.payload);
            return {
                ...state,
                plans_subscribed: action.payload,
            };
        case DashboardActionTypes.SUBSCRIBE_A_PLAN:
            return {
                ...state,
                subscribed_plan: action.payload.data,
                subscribed_msg:action.payload.message
            };

        case DashboardActionTypes.DELETE_CLUSTER:
            return{
                ...state,
                delete:true
            }

        default:
            return state;
    }
};

export default dashboardReducer;
