import {DateTime, Duration} from "luxon";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import  {Auth, SearchQuery} from "../../lib/url";
import {Clients} from "../../lib/url";
import {reconstructQuery} from "../../lib/queryUtils";
import {TimeUnits} from "../../lib/dateTimeUtils";

let initialStore = {
    userId: "admin",
    query: '',
    filters: [],
    labels: {},
    from: {
        type: "Relative",
        interval: 15,
        unit: TimeUnits[2],
        epoch: DateTime.now().minus(Duration.fromObject({minutes: 15})).toMillis(),
        timer: -1,
    },
    to: {
        type: "Current",
        interval: 0,
        unit: TimeUnits[0],
        epoch: DateTime.now().toMillis(),
        timer: -1,
    },
    fromDateTime: DateTime.now().minus(Duration.fromObject({minutes: 15})).toMillis(),
    toDateTime: DateTime.now().toMillis(),
    logs: [],
    loading: false,
    queries: [],
    tail: [],
    clusters: [],
    clusterId: -1,
    interval: 15,
    type: 'Relative',
    unit: 'minutes'
}

export const fetchClusters = createAsyncThunk(
    'explorer/listClusters',
    async (auth, thunk) => {
        return await Clients.CLUSTER.LIST(auth);
    }
)

export const acquireLabels = createAsyncThunk(
    'explorer/fetchLabels',
    async ({auth, clusterId}, thunk) => {
        let labels = {}
        console.log("Labels $$$$", clusterId,auth)
        let labelKeys = await Clients.SEARCH.FETCH_LABELS(auth, clusterId)
        if ('data' in labelKeys) {
            for (const key of labelKeys.data) {
                let values = await Clients.SEARCH.FETCH_LABEL_VALUES(auth, clusterId, key);
                if ('data' in values)
                    labels[key] = values.data
            }
        }
        return thunk.fulfillWithValue(labels)
    }
)

export const addQuery = createAsyncThunk(
    'explorer/addQuery', async ({auth, userQuery}, thunk) => {
        console.log("line 66 &&&&",auth)
        try {
            await Clients.QUERY_MANAGER.ADD(auth, userQuery)
            let state = thunk.getState();
            let userId = sessionStorage.getItem("userId")
            return await thunk.dispatch(acquireQueries({auth: auth, userId}))
        } catch (e) {
            return thunk.rejectWithValue(e)
        }
    }
)

export const updateQuery = createAsyncThunk(
    'explorer/addOrUpdateQuery',
    async ({auth, userQuery}, thunk) => {
        try {
            await Clients.QUERY_MANAGER.EDIT(auth, userQuery)
            let state = thunk.getState();
            let userId = sessionStorage.getItem("userId")
            return await thunk.dispatch(acquireQueries({auth: auth, userId: sessionStorage.getItem("userId")}))
        } catch (e) {
            return thunk.rejectWithValue(e)
        }
    })

export const acquireQueries = createAsyncThunk(
    "explorer/acquireUserQueries",
    async ({auth, userId}, thunk) => {
        console.log("%%%%",auth)
        try {
            if (sessionStorage.getItem("userId") === undefined) {
                let state = thunk.getState();
                userId = sessionStorage.getItem("userId")
            }
            console.log(`${auth}: ${sessionStorage.getItem("userId")}`)
            return await Clients.QUERY_MANAGER.LIST(auth, sessionStorage.getItem("userId"))
        } catch (e) {
            thunk.rejectWithValue({error: e})
        }
    }
)
export const deleteQuery = createAsyncThunk(
    "explorer/removeQuery",
    async ({auth, userQuery}, thunkAPI) => {
        try {
            await Clients.QUERY_MANAGER.REMOVE(auth, userQuery);
            return await thunkAPI.dispatch(acquireQueries({auth: auth}))
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

function checkFilterEquality(filter, compareTo) {
    return filter.filterKey === compareTo.filterKey && filter.op === compareTo.op && filter.filterValue === compareTo.filterValue
}

export const fetchLogs = createAsyncThunk(
    'explorer/fetchLogs',
    async ({
               auth,
               clusterId,
               start,
               end,
               limit,
               query
           }, thunk) => {
        try {
            // let externalFilters = thunk.getState().explorer.filters
            // console.log(externalFilters)
            // if (externalFilters !== undefined && Array.isArray(externalFilters)) {
            //     query = reconstructQuery(externalFilters, query)
            //     console.log(query)
            // }
            console.log("query in slice ", query)
            let logs = await Clients.SEARCH.SEARCH_QUERY(auth, clusterId, query, start, end, limit);
            console.log("logs in slice ", logs, thunk.fulfillWithValue(logs))
            return thunk.fulfillWithValue(logs)
        } catch (e) {
            return thunk.rejectWithValue(e)
        }

    }
)

const explorerSlice = createSlice({
    name: 'explorer',
    immer: false,
    initialState: initialStore,
    reducers: {
        onQueryChange: (previousState, action) => {
            previousState.query = action.payload
        },
        onFromDateTimeRangeChange: (previousState, action) => {
            console.log(action.payload)
            previousState.from = {...action.payload}
        },
        onToDateTimeRangeChange: (previousState, action) => {
            previousState.to = {...action.payload}
        },
        onLogsFetched: ((state, action) => {
            console.log(action.payload)
            state.logs = action.payload
        }),
        onOpenQuery: ((state, action) => {
            console.log("Explorer slice - line 166 action",action.payload.query)
            state.query = action.payload.query
        }),
        onFiltersAdded: (state, action) => {
            let localFilters = state.filters.filter((value, index) => {
                return value !== action.payload
            })
            localFilters.push(action.payload)
            state.filters = localFilters
        },
        onFiltersRemoved: ((state, action) => {
            let localFilters = state.filters = state.filters.filter((value, index) => {
                return !checkFilterEquality(value, action.payload)
            });
            state.filters = localFilters
        }),
        onClusterSelected: (state, action) => {
            console.log(action.payload)
            state.clusterId = action.payload
        }
    },
    extraReducers: (builder => {
        builder
            .addCase(acquireLabels.fulfilled, (state, action) => {
                state.loading = false
                console.log(action.payload)
                if (Object.keys(action.payload).length === 0) {
                    console.log("ignoring state update")
                    return
                }
                state.labels = action.payload
            }).addCase(acquireLabels.pending, ((state, action) => {
            state.loading = true
        })).addCase(acquireLabels.rejected, ((state, action) => {
            state.loading = false
        }))
        builder.addCase(fetchLogs.fulfilled, ((state, action) => {
            console.log(action.payload)
            sessionStorage.setItem("endTime",DateTime.now().toMillis())
            sessionStorage.setItem("search","completed")
            console.log(action.payload)
            state.logs = action.payload
            state.loading = false
        })).addCase(fetchLogs.pending, ((state, action) => {
            state.loading = true
        })).addCase(fetchLogs.rejected, ((state, action) => {
            state.loading = false
        }))
        builder.addCase(acquireQueries.fulfilled, (state, action) => {
            state.queries = action.payload
            state.loading = false
        }).addCase(acquireQueries.pending, (state, action) => {
            state.loading = true
        }).addCase(acquireQueries.rejected, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteQuery.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteQuery.rejected, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteQuery.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(addQuery.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(addQuery.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(addQuery.rejected, (state, action) => {
            state.loading = false
        })
        builder.addCase(updateQuery.fulfilled, (state, action) => {
            state.loading = false
        }).addCase(updateQuery.pending, (state, action) => {
            state.loading = true
        }).addCase(updateQuery.rejected, (state, action) => {
            state.loading = false
        })
        builder.addCase(fetchClusters.fulfilled, (state, action) => {
            let liveClusters = action.payload.filter((cluster, index) =>{

                return cluster.status!=="STOPPED" && cluster.status!=="ERROR"
            });
            state.clusters = liveClusters
            if (liveClusters.length>0){
                state.clusterId = liveClusters[0]?.cluster_name
            }
            state.loading = false
        })
        builder.addCase(fetchClusters.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchClusters.rejected, (state, action) => {
            state.loading = false
        })

    })

})


export const {
    onQueryChange,
    onFromDateTimeRangeChange,
    onToDateTimeRangeChange,
    onFiltersAdded,
    onFiltersRemoved,
    onOpenQuery, onClusterSelected
} = explorerSlice.actions

export const selectQuery = (state) => state.explorer.query
export const selectFromDate = (state) => state.explorer.fromDateTime
export const selectToDate = (state) => state.explorer.toDateTime
export const selectLabels = (state) => state.explorer.labels
export const selectFilters = (state) => state.explorer.filters
export const selectLogs = (state) => state.explorer.logs
export const selectLoading = (state) => state.explorer.loading
export const selectQueries = (state) => state.explorer.queries
export const selectClusters = (state) => state.explorer.clusters
export const selectClusterId = (state) => state.explorer.clusterId
export const selectInterval = (state) => state.explorer.interval
export const selectType = (state) => state.explorer.type
export const selectUnit = (state) => state.explorer.unit
export const selectFrom = (state) => state.explorer.from
export const selectTo = (state) => state.explorer.to


export default explorerSlice.reducer
