import { enableES5 } from "immer";
enableES5();
console.log(enableES5())
import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { store } from "../../Store";
import App from "../CRM/App";

// import {enableES5} from "immer"
// enableES5()

console.log(store)
ReactDOM.render(
  <React.StrictMode>
    {console.log(store)}
  <Provider store={store}>
    <App />
  </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
