import React from 'react'
// import LogoImg from '../../../../../assets/episilia_logo_edited.webp';
import LogoImg from '../../../../../assets/Final-Logo-03082022.png'
import  "../styles/logo.scss";

const Logo = () => {
  return (
    <div className="logo">
      <img src={LogoImg} alt="No" />
      {/* <img src={LogoImg} alt="No" height="70px" width="120px"/> */}
    </div>
  )
}

export default Logo;
