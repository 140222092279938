import React,{useEffect, useState} from "react";
import {
    Autocomplete,
    Box,
    Button,
    ButtonGroup, Chip,
    Divider,
    MenuItem,
    Popover,
    Select,
    Stack,
    TextField,
    Typography,
    IconButton
} from "@mui/material";
// import {AddSharp,Close} from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import AddSharpIcon from '@mui/icons-material/AddSharp';

function FilterForm({
    labels = {},
    fromDate,
    toDate,
    query,
    onFilterAddClicked,
  }) {
    const options = [
      {
        op: "=",
        name: "equal to",
      },
      {
        op: "!=",
        name: "not equal to",
      },
      {
        op: "=~",
        name: "matches pattern",
      },
      {
        op: "!~",
        name: "not matches",
      },
    ];
  
    const [selectedLabel, setSelectedLabel] = useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const [filterValue, setFilterValue] = useState("");
  
    return (
      <>
        <Box display={"flex"}>
          <Select
            label={"Label"}
            value={selectedLabel}
            sx={{ flexGrow: 1 }}
            size={"small"}
            required
            onChange={(event, value) => {
              setSelectedLabel(event.target.value);
            }}
          >
            {Object.keys(labels).map((value, index) => {
              console.log(value);
              return (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>


          <Select
            sx={{ marginLeft: "10px", minWidth: "160px" }}
            value={selectedOption}
            size={"small"}
            required
            onChange={(event, value) => {
              setSelectedOption(event.target.value);
            }}
          >
            {options.map((value, index) => {
              return (
                <MenuItem key={index} value={value.op}>
                  {value.name}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
  


        {selectedLabel !== "" && labels[selectedLabel] !== undefined ? (
          <Autocomplete
            sx={{ marginTop: "10px" }}
            value={filterValue}
            size={"small"}
            freeSolo
            options={labels[selectedLabel]}
            onChange={(event, value) => {
              setFilterValue(value);
            }}
            renderInput={(params) =>
               <TextField {...params} label="Filter" />
              }
          />
        ) : (
          <TextField
            sx={{ marginTop: "10px" }}
            size={"small"}
            fullWidth
            label={"filter"}
            value={filterValue}
            onChange={(event) => {
              setFilterValue(event.target.value);
            }}
          />
        )}
  
        <Divider sx={{ marginTop: "10px" }} />
        <ButtonGroup
          variant={"contained"}
          size={"small"}
          sx={{ marginTop: "10px" }}
        >
          <Button
            color={"primary"}
            sx={{ backgroundImage:"linear-gradient( to top right, #29C2B6, #2CD97B )",}}
            onClick={() => {
              let filter = {
                filterKey: selectedLabel,
                filterValue: `${filterValue}`,
                op: selectedOption,
              };
              console.log(filter);
              onFilterAddClicked(filter);
            }}
          >
            Apply to Filters
          </Button>
        </ButtonGroup>
      </>
    );
  }
  
  export default function LabelForm({
    labels,
    filters,
    onFilterAdded,
    onFilterDeleted,
  }) {
    const [anchorEl, setAnchorEL] = useState(null);
    let theme=sessionStorage.getItem("theme")
    return (
      <>
        
        <Box>
          <Button
            size={"small"}
            sx={{ textTransform: "capitalize",  
            width:'100px',
            border: "1px solid #DD0C4E ",
            backgroundColor: theme=="dark"?'#DCDCDC':'#FFF',
            color: "#000",
            fontFamily: "Poppins",
            borderWidth: "1.5px",
            borderStyle: "solid",
            borderImage:
                "linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);",
            borderImageSlice: 1,
            ':hover': {
              backgroundColor:  "#1C1850" ,
              color:  "#FFF" ,
            },
            boxShadow:'none',marginLeft:1 }}
            onClick={(event) => {
              setAnchorEL(event.target);
            }}
            variant={"contained"}
            endIcon={<AddSharpIcon />}
          >
            Filters
          </Button>
        </Box>
        {/* <Box display={"block"}>
                  <Stack direction={"row"}  spacing={1}>
                      {filters.map((filter, index) => {
                          let filterLabel = `${filter.filterKey}${filter.op}${filter.filterValue}`
                          return (
                              <Chip key={index} onClick={() => {
                                  onFilterDeleted(filter)
                              }} label={`${filterLabel}`}/>
                          )
                      })}
                  </Stack>
              </Box> */}
  
        <Popover
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEL(null);
          }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          anchorEl={anchorEl}
        >
          <IconButton
            style={{
              cursor: "pointer",
              float: "right",
              marginTop: "5px",
              marginRight: "2px",
              width: "20px",
            }}
            onClick={() => {
              setAnchorEL(null);
            }}
          >
            <CloseIcon />
          </IconButton>
  
          <Box sx={{ width: "550px", padding: "1rem" }}>
            <Typography variant={"h5"}>Add Filters</Typography>
            <Box width={"100%"}>
              <FilterForm
                labels={labels}
                onFilterAddClicked={(filter) => {
                  onFilterAdded(filter);
                  setAnchorEL(null);
                }}
              />
            </Box>
          </Box>
        </Popover>
      </>
    );
  }
  