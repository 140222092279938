import {
    AppBar,
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Toolbar,
    Grid,
    Tooltip
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteQuery,
    fetchClusters,
    onClusterSelected,
    onOpenQuery,
    selectClusterId,
    selectClusters,
    selectQueries,
    selectQuery,
    updateQuery,
} from "../features/explorer/explorerSlice";
import AddAlertSharpIcon from "@mui/icons-material/AddAlertSharp";
// import { AddAlertSharp, DataArraySharp, SaveSharp } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../../../../authentication/views/components/Auth";
import { useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { Card } from "antd";

const useStyles = makeStyles({
    customOutline: {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DD0C4E",
            borderWidth: "1.5px",
            borderRadius:'2px',
                                                borderStyle: "solid",
                                                borderImage:
                                                    "linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);",
                                                borderImageSlice: 1,
        },
    },
    select: {
        '&.Mui-disabled':{
            // position:'relative',
            "-webkit-text-fill-color":'#DCDCDC !important',
            color: `#DCDCDC !important`
        },
        '&.MuiSelect-nativeInput':{
            backgroundColor:'red'
        }
      },
});

export default function Navigation({ userId2, auth }) {
    const classes = useStyles();
    const { token, emailId, userId } = useContext(AuthContext);
    const dispatch = useDispatch();
    const queries = useSelector(selectQueries);
    const query = useSelector(selectQuery);
    const [openQuerySaveModal, setOpenQuerySaveModal] = useState(false);
    const [openQueryRepoDrawer, setOpenQueryRepoDrawer] = useState(false);
    const clusters = useSelector(selectClusters);
    const selectedCluster = useSelector(selectClusterId);
    const navigate = useNavigate();
    const location = useLocation();
    const [theme, setTheme] = useState(sessionStorage.getItem("theme"));

    useEffect(() => {
        if (
            userId === undefined ||
            userId === "" ||
            emailId === undefined ||
            emailId === "" ||
            token === undefined ||
            token === ""
        ) {
        } else {
            dispatch(fetchClusters({ uid: userId, email: emailId, "auth-token": token }));
        }
    }, [userId, token, emailId, selectedCluster]);

    useEffect(() => {
        dispatch(fetchClusters({ uid: userId, email: emailId, "auth-token": token }));
    }, [theme]);

    return window.screen.width > 900 ? (
        <Box className="box-navigation" sx={{ flexGrow: 1, marginTop: "1em" }}>
            {sessionStorage.setItem("cluster_name", selectedCluster)}
            {/* <AppBar className="box-navigation" position={"static"} color={"transparent"}>
                <Toolbar className="box-navigation" sx={{backgroundColor: "pink",width:270}} title={"Spike"}> */}

            {location.pathname !== "/login" && (
                <Box
                    className="box-navigation"
                    sx={{ flexGrow: 2, display: "flex", justifyContent: "flex-end", width: 270 }}
                >
                    <FormControl
                    style={{marginTop:'-0.3em',position:'relative'}}
                        size={"small"}
                        classes={{ root: classes.customOutline }}
                        className="cluster-drop-down-select"
                    >
                        {/* <Button
                            //  style={{border: "1px #41E799 solid",width:'40px',height:'40px'}}
                            // className="theme-button"
                            //  style={{position:'absolute',border: "1px #41E799 solid",width:'20px',height:'40px',marginLeft:'68em',paddingLeft:-3,paddingRight:-4}}
                            onClick={() => (
                                sessionStorage.getItem("theme") == "dark"
                                    ? sessionStorage.setItem("theme", "light")
                                    : sessionStorage.setItem("theme", "dark"),
                                setTheme(sessionStorage.getItem("theme")),
                                window.location.reload()
                            )}
                        >
                            {/* {sessionStorage.getItem("theme") == "dark" ? "Light " : "Dark "}Mode 
                            {sessionStorage.getItem("theme") == "dark" ? (
                                <Tooltip title="Light Mode" placement="bottom">
                                    <LightModeIcon
                                        style={{ color: "#DCDCDC",height:'40px',width:'40px' }}
                                         className="theme-button"
                                    />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Dark mode" placement="bottom">
                                    <DarkModeIcon
                                        style={{ color: "black",height:'40px',width:'40px',marginTop:'1.75em' }}
                                        className="theme-button"
                                    />
                                </Tooltip>
                            )}
                        </Button> */}

                        <InputLabel
                            sx={{
                                color: "#A9A9A9",
                                marginTop: "-0.05em",
                                zIndex: 126,
                            }}
                            style={{ color: "#E0245f", fontSize:'17px' }}
                        >
                            Clusters
                        </InputLabel>
                        {/* backgroundColor: theme=="dark"?'#DCDCDC':'#FFF' */}
                        <Select
                            sx={{
                                // color: "#A9A9A9",
                                color:theme=="dark"?'#FFF':'#000',
                                zIndex: 16,
                                borderColor: "green",
                                backgroundColor: "transparent",
                                
                            }}
                            className={classes.select}
                            classes={{disabled:classes.select}}
                            fullWidth
                            value={clusters.length === 0 ? -1 : clusters[0]?.cluster_name}
                            label="Clusters"
                            disabled={clusters.length === 0}
                            onChange={(event, child) => {
                                dispatch(onClusterSelected(event.target.value));
                            }}
                        >
                            {clusters.length === 0 && (
                                <MenuItem disabled value={-1} style={{color:theme=="dark"?'#FFF':'#000',   "-webkit-text-fill-color": `#FFF !important`,
                                }}>
                                    No Clusters Found
                                </MenuItem>
                            )}
                            {clusters?.map((cluster, index) => {
                                return (
                                    <MenuItem key={index} value={cluster.cluster_name}>
                                        {cluster.cluster_name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Box>
            )}
            {/* {!location.pathname.includes("/alerts") && clusters.length > 0 ? (
                <div
                    className="button-alert-config"
                    style={{
                        zIndex: 17,
                        backgroundColor: "transparent",
                        // border: "1px solid #DD0C4E",
                        borderRadius: 4,
                        border:'none',
                        // borderWidth: "1.5px",
                        //                         borderStyle: "solid",
                        //                         borderImage:
                        //                             "linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);",
                        //                         borderImageSlice: 1,
                        position: "relative",
                    }}
                >
                    {console.log(theme)}
                    <Button
                        sx={{
                            // marginRight: 1,
                            // marginLeft: 1,
                            fontSize: 11,
                            // color: "#000",
                            zIndex: 17,
                            // border:'1px solid #41E799',
                            backgroundColor: "transparent",
                            height:'40px',
                            marginTop:'-0.5em',
                            color: "#A9A9A9",
                            borderWidth: "1.5px",
                                                borderStyle: "solid",
                                                borderImage:
                                                    "linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);",
                                                borderImageSlice: 1,
                                                ":hover": {
                                                    backgroundColor: "#1C1850" ,
                                                    color:"#FFF" ,
                                                },
                        }}
                        startIcon={<AddAlertSharpIcon />}
                        size={"small"}
                        onClick={(event) => {
                            navigate("/spike/explorer/alerts", { replace: true });
                        }}
                    >
                        Alerts Config
                    </Button>
                </div>
            ) : (
                clusters.length > 0 && (
                    <div
                        className="button-alert-config-logs"
                        style={{ backgroundColor: "transparent" }}
                    >
                        <Button
                            sx={{
                                // marginRight: 1,
                                // marginLeft: 1,
                                color: "#A9A9A9",
                                border: "1px solid #DD0C4E",
                                width: "100px",
                                height: "38px",
                                zIndex: 15,
                                backgroundColor: "transparent",

                                // backgroundImage: "linear-gradient( to top right, #29C2B6, #2CD97B )",
                            }}
                            size={"small"}
                            onClick={(event) => {
                                navigate("/spike/explorer", { replace: true });
                            }}
                        >
                            Logs
                        </Button>
                    </div>
                )
            )} */}
        </Box>
    ) : (
        <></>
    );
}
