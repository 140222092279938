import PropTypes from "prop-types";

const oneOfProperties = (propertyObject, isRequired) => {
    if (isRequired) {
        return PropTypes.oneOf(Object.keys(propertyObject).map(key => propertyObject[key]));
    }
    else {
        return PropTypes.oneOf(Object.keys(propertyObject).map(key => propertyObject[key]));
    }
}

export {
    oneOfProperties
}