import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, Cell } from "recharts";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
// import { setTwoToneColor } from "@ant-design/icons";
let color = "";

export function extractStats(logs) {
    let stats = {};
    let stats_error = {};
    let stats_warn = {};
    let result = [];
    let obj = {};

    logs.forEach((logSection, index) => {
        logSection.logs.forEach((log, index) => {
            let ts = log._ts / 1000000;

            if (log.log.startsWith("INFO")) {
                if (ts in stats) {
                    stats[ts] = stats[ts] + 1;
                } else {
                    stats[ts] = 1;
                }
            } else if (log.log.includes("WARN")) {
                if (ts in stats) {
                    stats_warn[ts] = stats_warn[ts] + 1;
                } else {
                    stats_warn[ts] = 1;
                }
            } else if (log.log.includes("ERROR")) {
                if (ts in stats_error) {
                    stats_error[ts] = stats_error[ts] + 1;
                } else {
                    stats_error[ts] = 1;
                }
            } else {
                if (ts in stats) {
                    stats[ts] = stats[ts] + 1;
                } else {
                    stats[ts] = 1;
                }
            }
        });
    });

    let stats_combined = {};
    stats_combined["info"] = { ...stats };
    stats_combined["error"] = { ...stats_error };
    stats_combined["warn"] = { ...stats_warn };
    let stat_dates = { ...stats, ...stats_error, ...stats_warn };
    let flag_info = false;
    let flag_error = false;
    let flag_warn = false;
    let temp_var,
        temp_sec = 0,
        temp_min = 0;
    Object.keys(stat_dates).map((item, index) => {
        // console.log(item);
        // if(index<150)

        if (index == 0) {
            (temp_var = parseInt(
                DateTime.fromMillis(parseInt(item)).toFormat("yyyy/MM/dd HH:mm:ss").slice(8, 11),
            )),
                (temp_sec = 0),
                (temp_min = 0);
        }

        // console.log(
        //     "MINUTES   ",
        //     DateTime.fromMillis(parseInt(item)).toFormat("yyyy/MM/dd HH:mm:ss").slice(14, 16),
        //     temp_min,
        //     parseInt(
        //         DateTime.fromMillis(parseInt(item)).toFormat("yyyy/MM/dd HH:mm:ss").slice(14, 16),
        //     ) - temp_min,
        // );
        // console.log(
        //     "SECONDS   ",
        //     DateTime.fromMillis(parseInt(item)).toFormat("yyyy/MM/dd HH:mm:ss").slice(17, 19),
        //     temp_sec,
        //     parseInt(
        //         DateTime.fromMillis(parseInt(item)).toFormat("yyyy/MM/dd HH:mm:ss").slice(17, 19),
        //     ) - temp_sec,
        // );
        // console.log(
        //     temp_var,
        //     parseInt(
        //         DateTime.fromMillis(parseInt(item)).toFormat("yyyy/MM/dd HH:mm:ss").slice(8, 11),
        //     ),
        //     temp_var !=
        //         parseInt(
        //             DateTime.fromMillis(parseInt(item))
        //                 .toFormat("yyyy/MM/dd HH:mm:ss")
        //                 .slice(8, 11),
        //         ),
        // );

        if (
            index == 0 ||
            temp_var !=
                parseInt(
                    DateTime.fromMillis(parseInt(item))
                        .toFormat("yyyy/MM/dd HH:mm:ss")
                        .slice(8, 11),
                ) ||
            parseInt(
                DateTime.fromMillis(parseInt(item)).toFormat("yyyy/MM/dd HH:mm:ss").slice(14, 16),
            ) -
                temp_min >=
                1 ||
            parseInt(
                DateTime.fromMillis(parseInt(item)).toFormat("yyyy/MM/dd HH:mm:ss").slice(17, 19),
            ) -
                temp_sec >
                50
        ) {
            obj = {};
            flag_info = false;
            flag_error = false;
            flag_warn = false;
            obj["date"] = DateTime.fromMillis(parseInt(item)).toFormat("yyyy/MM/dd HH:mm:ss");

            obj["raw_date"] = item;

            // if(item)
            Object.keys(stats_combined["info"]).map((i) => {
                if (!flag_info) {
                    if (i == item) {
                        obj["count_info"] = stats_combined["info"][i];
                        flag_info = true;
                    }
                }
            });
            Object.keys(stats_combined["error"]).map((e) => {
                if (!flag_error) {
                    if (e == item) {
                        obj["count_error"] = stats_combined["error"][e];
                        flag_error = true;
                    }
                }
            });
            Object.keys(stats_combined["warn"]).map((e) => {
                if (!flag_warn) {
                    if (e == item) {
                        obj["count_warn"] = stats_combined["warn"][e];
                        flag_warn = true;
                    }
                }
            });

            if (!flag_info) {
                obj["count_info"] = 0;
            }
            if (!flag_error) {
                obj["count_error"] = 0;
            }
            if (!flag_warn) {
                obj["count_warn"] = 0;
            }
            result.push({ ...obj });
        }
        temp_min = parseInt(
            DateTime.fromMillis(parseInt(item)).toFormat("yyyy/MM/dd HH:mm:ss").slice(14, 16),
        );
        temp_sec = parseInt(
            DateTime.fromMillis(parseInt(item)).toFormat("yyyy/MM/dd HH:mm:ss").slice(17, 19),
        );
       
        if (
            temp_var !=parseInt(DateTime.fromMillis(parseInt(item)).toFormat("yyyy/MM/dd HH:mm:ss").slice(8, 11)))
             {
                temp_var=parseInt(DateTime.fromMillis(parseInt(item)).toFormat("yyyy/MM/dd HH:mm:ss").slice(8, 11))
            }
    });

    

    result.sort((a, b) => {
        return a.raw_date - b.raw_date;
    });

    return result;

}

export default function StatsGraph({ logs }) {
    const [stats, setStats] = useState([]);
    useEffect(() => {
        let computedStats = extractStats(logs);
        setStats(computedStats);
    }, [logs]);
    
    let theme = sessionStorage.getItem("theme")

    return (
        <div style={{backgroundColor:'transparent',position:'relative'}}>
           <ResponsiveContainer
                className={"responsive-container-graph"}
                width={"100%"}
                minHeight={"200px"}
                maxHeight={"200px"}
            >
              { logs.length >0 ?
              
              <BarChart
                    className="bar-graph"
                    margin={{ top: 0, left: -10, right: 2 }}
                    width={500}
                    height={300}
                    data={stats}
                >
                    
                    {stats.length > 0 && (
                        <XAxis
                            minTickGap={4}
                            dataKey="date"
                            // domain={[
                            //     stats[0].date,
                            //     stats[stats.length - 1].date < 4 ? 4 : stats[stats.length - 1].date,
                            // ]}
                            scale="band"
                            tickMargin={3}
                            tickCount={5}
                            width={5}
                            
                            tick={{ fontSize: 13, fill:theme=="dark"?"#DCDCDC":'#3a3a3a'}}
                            tickFormatter={(val) => {
                                let date_val = stats[0].date.toString().slice(0, 11);
                                let display_val;
                                if (date_val == val.toString().slice(0, 11)) {
                                    display_val = val.toString().slice(11);
                                } else {
                                    display_val = val;
                                }

                                return display_val;
                            }}
                        />
                     )} 
                    {/* /> */}

                    <YAxis  tick={{  fill:theme=="dark"?"#DCDCDC":'#3a3a3a'}} tickCount={4} allowDecimals={false} />
                    {/* <YAxis  orientation='right' /> */}
                    <Legend dataKey={"raw_data"} />
                    <Tooltip />
                    <Bar name="Error" dataKey="count_error" stackId="error" fill="red" />
                    <Bar name="Warn" dataKey="count_warn" stackId="warn" fill="#F1F41A" />
                    <Bar name="Info" dataKey="count_info" stackId="info" fill="#6BB660" />
                </BarChart> :
                <BarChart>
                     <XAxis dataKey={"date"}  domain={[0,1]} tickCount={4} allowDecimals={false} />
                     <YAxis dataKey="count_info" color="green" domain={[0,1]} tickCount={4} allowDecimals={false} />
                    
                    </BarChart>}
            </ResponsiveContainer>
        </div>
    );
}
