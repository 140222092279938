class UrlBuilder {
    static baseUrl() {
      return process.env.URL_ENDPOINT;
    }
  
    
    static  build(path) {
      const url = UrlBuilder.baseUrl();
      return `${url}/${path}`;
    }
  }
  
  export default UrlBuilder;
  