const url = {
  AUTHENTICATION: {
    VERIFY:'spike/user/current',
    REGISTER:'spike/user/register',
    REGISTER_WITH_INVITE:'spike/user/registerbyinvite',
    VERIFY_INVITE:'spike/user/invite/verify/$token',
    VERIFY_COMPANY_INVITE:'spike/company/invite/verify/$token'
  },
  DAHSBOARD: {
    CLUSTER:'pulse/cluster' ,
    SERVER:'pulse/cluster/',
    UPDATEPROFILE:'spike/user/update',
    GETUSERSLIST:'spike/user/list',
    SENDINVITE:'spike/user/invite',
    GET_INVITEDLIST:'spike/user/invite/list',
    COMPANYINVITE:'spike/company/invite',
    VERIFY_COMPANY_INVITE:'spike/company/invite/verify/$token',
    GETCOMPANYLIST:'spike/company/invite/list',
    
    CREATE_CLUSTER:'spike/cluster/create',
    GET_LIVE_CLUSTERS:'spike/cluster/live',
    GET_CLUSTERS_LIST:'spike/cluster/list',
    GET_CLUSTER_DETAIL:'spike/cluster/get/$id',
    DELETE_CLUSTER:'spike/cluster/delete/$clusterName',

    SUBSCRIBED_LIST:'spike/plan/subscriptions',
    SUBSCRIPTION_PLANS :'spike/plan/list',
    SUBSCRIBE_TO_PLAN:'spike/plan/subscribe'
  },

  // SUBSCRIPTION:{
  //   CREATE_SUBSCRIPTION:"v1/subscription/create/auth/$token/user/$uuid",
  //   CHARGE_SUBSCRIPTION:"v1/subscription/charge/user/$uuid",
  //   GET_SUBSCRIPTION:"v1/subscription/auth/$token/user/$uuid",
  //   GET_ALL_SUBSCRIPTION:"v1/subscription/auth/$token/user/$uuid/all",
  //   GET_PAYMENT:"v1/subscription/payment/auth/$token/user/$uuid",
  //   GET_ALL_PAYMENT:"v1/subscription/payment/auth/$token/user/$uuid/all",
  //   CANCEL_CHARGE:"v1/subscription/charge/auth/$token/user/$uuid/paymentId/$paymentId/cancel",
  //   CANCEL_SUBSCRIPTION:"v1/subscription/auth/$token/user/$uuid/cancel"

    
  // }
};

export default url;
