import React, { useContext, useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "../Layout/index";
import MainWrapper from "./MainWrapper";
import PrivateRoute from "./PrivateRoute";

import Dashboard from "../CRM/dashboard/views/components/Dashboard/Dashboard";
import Profile from "../CRM/dashboard/views/components/Profile";
import PlanAndPricing from "../CRM/dashboard/views/components/PlanAndPricing";
import Support from "../CRM/dashboard/views/components/Support/Support";
import VERBIAGE from "../../utils/VERBIAGE";
import { Login, Registration } from "../CRM/authentication/views";
import Explorer from "../CRM/dashboard/views/components/Search/features/explorer/Explorer";
import AlertManager from "../CRM/dashboard/views/components/Search/features/alerts/AlertManager";
import ManageUsers from "../CRM/dashboard/views/components/ManageUsers/ManageUsers";
import ServerDetails from "../Pulse/components/ServerDetails";
import AuthContext from "../CRM/authentication/views/components/Auth";
import Navigation from "../CRM/dashboard/views/components/Search/component/Navigation";
import { getAuth } from "firebase/auth";
import AuthenticationActions from "../CRM/authentication/redux/AuthenticationActions";
import Logo from "../../assets/Final-Moth.png";
import { async } from "regenerator-runtime";
import Loading from "../CRM/dashboard/views/components/Loading";
let auth = getAuth();
let access_tkn = "";
let tokenExpired = false;
let timer = 0;

// const WrappedRoutes = () => (
//     <div>
//         <Layout />

//         <Route path={VERBIAGE.NAVIGATION.PATHS.SUPPORT} element={<Support />} />
//     </div>
// );

const Router = (props) => {
    const { dashboard, getCredentials, history, authentication } = props;
    let { token, emailId, userId } = useContext(AuthContext);
    let navigate = useNavigate();
    let url = location.pathname;
    access_tkn = token;

    // For invites
    useEffect(() => {
        if (location.pathname.includes("/joincompany")) {
            let segments = url.split("/");
            let id = segments[segments.length - 1];
            localStorage.setItem("inviteToken", id);
            localStorage.setItem("tokenType", "company");
        } else if (url.includes("/join/")) {
            let segments = url.split("/");
            let id = segments[segments.length - 1];
            localStorage.setItem("inviteToken", id);
            localStorage.setItem("check", "yes");
            localStorage.setItem("tokenType", "user");
        }
    });

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            sessionStorage.setItem("Name", user.displayName);
            if (sessionStorage.getItem("logout") == "false") {
                token = await user.getIdToken();
                emailId = user.email;
                userId = user.uid;
                access_tkn = token;
                let header = {
                    token,
                    emailId,
                    userId,
                };
                getCredentials(header);
            }
        });
    }, [auth]);

    useEffect(() => {
        const interval = setInterval(() => {
            auth.onAuthStateChanged(async (user) => {
                token = await user.getIdToken();
                emailId = user.email;
                userId = user.uid;
                access_tkn = token;

                let header = {
                    token,
                    emailId,
                    userId,
                };
                console.log(header);
                getCredentials(header);
                // }
            });
        }, 180000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            if (sessionStorage.getItem("logout") == "false") {
                // token == null ? (token = user.accessToken) : token;
                // emailId == null ? (emailId = user.email) : emailId;
                // userId == null ? (userId = user.uid) : userId;

                token = await user.getIdToken();
                emailId = user.email;
                userId = user.uid;
                access_tkn = token;
                let header = {
                    token,
                    emailId,
                    userId,
                };
                getCredentials(header);
                if (authentication.autheticated) {
                    if (url == "/") {
                        navigate("/spike/dashboard");
                    }
                }
            }
            // else if(sessionStorage.getItem("email") != "true"){
            //     console.log("crm---else")
            //     navigate("/");
            //     sessionStorage.clear();
            //     localStorage.clear();
            //     auth.signOut();
            //     window.location.reload();
            // }
        });
    }, []);

    return (
        <MainWrapper>
            {/* {timerCalc()} */}
            <div>
                {location.pathname.includes("spike") && (
                    <img
                        style={{
                            position: "fixed",
                            width: "3.65em",
                            height: "3em",
                            zIndex: 105,
                            marginLeft: "0.3rem",
                            marginTop: location.pathname.includes("spike/dashboard")?"0.85rem":"-0.5rem",
                            cursor: "pointer",
                            //  transform:"rotate(-10deg)"
                        }}
                        onClick={() => navigate("/spike/dashboard")}
                        src={Logo}
                        width={45}
                        height={30}
                        size="contain"
                    />
                )}
            </div>
            {location.pathname.includes("spike") ? <Layout /> : ""}
            {location.pathname.includes("explorer") ? <Navigation /> : ""}

            <Routes>
                <Route exact path="/" element={<Login />} />

                <Route path="/join/*" exact element={<Login />}></Route>
                <Route path="/joincompany/*" exact element={<Login />}></Route>
                <Route path={VERBIAGE.NAVIGATION.PATHS.REGISTER} element={<Registration />} />

                {/* <Route
                    path={"/spike"}
                    element={
                        <PrivateRoute>
                            <Loading />
                        </PrivateRoute>
                    }
                /> */}
                <Route
                    path={VERBIAGE.NAVIGATION.PATHS.CRMDASHBOARD}
                    element={
                        <PrivateRoute>
                            <Dashboard />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={VERBIAGE.NAVIGATION.PATHS.PLAN}
                    element={
                        <PrivateRoute>
                            <PlanAndPricing />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={VERBIAGE.NAVIGATION.PATHS.SUPPORT}
                    element={
                        <PrivateRoute>
                            <Support />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={VERBIAGE.NAVIGATION.PATHS.MANAGEUSERS}
                    element={
                        <PrivateRoute>
                            <ManageUsers />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={VERBIAGE.NAVIGATION.PATHS.PROFILE}
                    element={
                        <PrivateRoute>
                            <Profile />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/spike/servers/:id"
                    element={
                        <PrivateRoute>
                            <ServerDetails />
                        </PrivateRoute>
                    }
                />
                {/* <Navigation userId={uid} auth={{"auth-token": token, email: emailId, uid: userId}}/> */}
                <Route
                    path={"/spike/explorer"}
                    element={
                        <PrivateRoute>
                            <Explorer />
                        </PrivateRoute>
                    }
                ></Route>

                <Route
                    path={"/spike/explorer/alerts"}
                    element={
                        <PrivateRoute>
                            <AlertManager />
                        </PrivateRoute>
                    }
                ></Route>
            </Routes>
        </MainWrapper>
    );
};

const mapStateToProps = (state) => ({
    authentication: state.authentication,
    dashboard: state.dashboard,
});

const mapDispatchToProps = (dispatch) => ({
    getCredentials: (header) => {
        dispatch(AuthenticationActions.getCredentials(header));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);

// export default Router;
