import React, { useEffect, useState } from "react";
import VERBIAGE from "../../../utils/VERBIAGE";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Tooltip, IconButton, Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SearchIcon from "@mui/icons-material/Search";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PersonIcon from "@mui/icons-material/Person";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LogoutIcon from "@mui/icons-material/Logout";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";



const SidebarContent = () => {
    const url = location.pathname;
    // const forceUpdate = useForceUpdate();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    let navigate = useNavigate();
    const [pulse, setPulse] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => setPulse(url.includes("/cluster")));

    const logOut = () => {
        location.pathname.includes("spike")
            ? sessionStorage.setItem("logout", "spike")
            : sessionStorage.setItem("logout", "pulse");

        // auth.signOut();
        localStorage.clear();
        sessionStorage.clear();
        navigate("/");
        window.location.reload();
    };

   
    return (
        <div className="sidebar__content">
            {sessionStorage.getItem("app") == "pulse" ? (
                <ul className="sidebar__block">
                    {/* <SidebarLink
                        // icon={<AccountBookOutlined/>}
                        icon="AppstoreOutlined"
                        title="All Clusters"
                        route="/cluster"
                        onClick={onClick}
                    />
                    <SidebarLink
                        icon="LogoutOutlined"
                        title="Logout"
                        route={VERBIAGE.NAVIGATION.PATHS.LOGIN}
                        onClick={() => (logOut(), window.location.reload())}
                    /> */}
                    <Tooltip title="Dashboard" placement="right">
                        <IconButton onClick={() => navigate("/cluster")}>
                            <DashboardIcon
                                className={
                                    url.includes("cluster") ? "active-icon-menu" : "icon-menu"
                                }
                            />
                        </IconButton>
                    </Tooltip>
                    <div style={{ height: screen.availHeight > 1000 ? "32em" : "25em" }} />
                    {/* <div style={{ height:screen.availHeight<700 ? "50vh":screen.availHeight<900 ? "35vh" :screen.availHeight>900&&screen.availHeight<1100 ?"50vh":"48vh"}} /> */}
                    <Tooltip title="Logout" placement="right">
                        <IconButton onClick={() => logOut()}>
                            <LogoutIcon className="icon-menu" />
                        </IconButton>
                    </Tooltip>
                </ul>
            ) : (
                //  url.includes("/spike") ? (
                // //     <Popover content={"hello"} title="Title">
                // //     <Button >Hover me</Button>
                // //   </Popover>
                // ) :
                <ul className="sidebar__block">
                    <Tooltip title="Dashboard" placement="right">
                        <IconButton
                            onClick={() => (window.scrollTo(0, 0),navigate(VERBIAGE.NAVIGATION.PATHS.CRMDASHBOARD))}
                        >
                            <DashboardIcon
                                className={
                                    url.includes("dashboard") ? "active-icon-menu" : "icon-menu"
                                }
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Search" placement="right">
                        <IconButton onClick={() => (window.scrollTo(0, 0),navigate(VERBIAGE.NAVIGATION.PATHS.SEARCH))}>
                            <SearchIcon
                                className={
                                    url.includes("explorer") ? "active-icon-menu" : "icon-menu"
                                }
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Plan and Pricing" placement="right">
                        <IconButton onClick={() => (window.scrollTo(0, 0),navigate(VERBIAGE.NAVIGATION.PATHS.PLAN))}>
                            <MonetizationOnIcon
                                className={url.includes("plan") ? "active-icon-menu" : "icon-menu"}
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Manage Users" placement="right">
                        <IconButton onClick={() => (window.scrollTo(0, 0),navigate(VERBIAGE.NAVIGATION.PATHS.MANAGEUSERS))}>
                            <PersonAddAltIcon
                                className={url.includes("users") ? "active-icon-menu" : "icon-menu"}
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Profile" placement="right">
                        <IconButton onClick={() => (window.scrollTo(0, 0),navigate(VERBIAGE.NAVIGATION.PATHS.PROFILE))}>
                            <PersonIcon
                                className={
                                    url.includes("profile") ? "active-icon-menu" : "icon-menu"
                                }
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Support" placement="right">
                        <IconButton onClick={() =>(window.scrollTo(0, 0), navigate(VERBIAGE.NAVIGATION.PATHS.SUPPORT))}>
                            <SupportAgentIcon
                                className={
                                    url.includes("support") ? "active-icon-menu" : "icon-menu"
                                }
                            />
                        </IconButton>
                    </Tooltip>

                    <div
                        style={{
                            height:
                                screen.availHeight < 700
                                    ? "11vh"
                                    : screen.availHeight < 900
                                    ? "22vh"
                                    : screen.availHeight < 1100
                                    ? "25vh"
                                    : "46.5vh",
                        }}
                    />
                    {window.innerWidth>900 && <Button
                        //  style={{border: "1px #41E799 solid",width:'40px',height:'40px'}}
                        // className="theme-button"
                        //  style={{position:'absolute',border: "1px #41E799 solid",width:'20px',height:'40px',marginLeft:'68em',paddingLeft:-3,paddingRight:-4}}
                        onClick={() => {
                            sessionStorage.getItem("theme") == "dark"
                                ? sessionStorage.setItem("theme", "light")
                                : sessionStorage.setItem("theme", "dark");
                            forceUpdate;
                            navigate('/spike/');
                            navigate(-1);
                            //    window.location.reload()
                        }}
                    >
                        {sessionStorage.getItem("theme") == "dark" ? (
                            <Tooltip title="Light Mode" placement="right">
                                <LightModeIcon
                                    style={{ color: "#1C1850", marginLeft: "-0.5em" }}
                                    // className="theme-button"
                                />
                            </Tooltip>
                        ) : (
                            <Tooltip title="Dark mode" placement="right">
                                <DarkModeIcon
                                    style={{
                                        color: "#1C1850",
                                        marginLeft: "-0.5em",
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Button>}
                    <Tooltip title={sessionStorage.getItem("Name")} placement="right">
                        <IconButton onClick={() => (window.scrollTo(0, 0),navigate(VERBIAGE.NAVIGATION.PATHS.PROFILE))}>
                            <Avatar
                                sx={{ width: 30, height: 30, border:"1.5px #1C1850 solid" }}
                                alt="My details"
                                src={sessionStorage.getItem("photoUrl")}
                            />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Logout" placement="right">
                        <IconButton onClick={() => logOut()}>
                            <LogoutIcon className="icon-menu" />
                        </IconButton>
                    </Tooltip>
                    {/* <SidebarLink
                        title="Dashboard"
                        icon="AppstoreOutlined"
                        route={VERBIAGE.NAVIGATION.PATHS.CRMDASHBOARD}
                        onClick={(onClick, window.scrollTo(0, 0))}
                    />

                    <SidebarLink
                        title="Searchlogs"
                        icon="SearchOutlined"
                        route={"/spike/explorer"}
                        onClick={(onClick, window.scrollTo(0, 0))}
                    />
                    <SidebarLink
                        icon="DollarOutlined"
                        title="Plan and Pricing"
                        route={VERBIAGE.NAVIGATION.PATHS.PLAN}
                        onClick={(onClick, window.scrollTo(0, 0))}
                    />
                    <SidebarLink
                        icon="UserAddOutlined"
                        title="Manage Users"
                        route={VERBIAGE.NAVIGATION.PATHS.MANAGEUSERS}
                        onClick={(onClick, window.scrollTo(0, 0))}
                    />
                    <SidebarLink
                        icon="UserOutlined"
                        title="Profile"
                        route={VERBIAGE.NAVIGATION.PATHS.PROFILE}
                        onClick={(onClick, window.scrollTo(0, 0))}
                    />
                    <SidebarLink
                        icon="CustomerServiceOutlined"
                        title="Support"
                        route={VERBIAGE.NAVIGATION.PATHS.SUPPORT}
                        onClick={(onClick, window.scrollTo(0, 0))}
                    />

                    <SidebarLink
                        icon="LogoutOutlined"
                        title="Logout"
                        route={VERBIAGE.NAVIGATION.PATHS.LOGIN}
                        onClick={() => logOut()}
                    /> */}
                </ul>
            )}
        </div>
    );
};

// SidebarContent.propTypes = {
//      onClick: PropTypes.func.isRequired,
//      sidebarCollapse: PropTypes.bool,
// };

// SidebarContent.defaultProps = {
//      sidebarCollapse: false,
// };

export default SidebarContent;
