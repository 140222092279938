const DashboardActionTypes = {
  SET_LICENSE: "authentication/GET_LICENSE_KEY",
  SET_NEW_LICENSE: "authentication/SET_NEW_LICENSE",
  SET_RESPONSIVE_VIEW: "appearance/SET_RESPONSE_VIEW",
  SET_PROFILE_DETAILS: "authentication/SET_PROFILE",
  FETCHING: "authentication/FETCHING",
  FETCHING_SUCCESS:"authentication/FETCHING_SUCCESS",
  FETCHING_FAILED:"authentication/FETCHING_FAILED",
  UPDATING: "authentication/UPDATING",
  PROFILE_UPDATION_SUCCESS:"authentication/PROFILE_UPDATION_SUCCESS",
  PROFILE_UPDATION_FAILED:"authentication/PROFILE_UPDATION_FAILED",
  CLOSE_DIALOG:"authentication/CLOSE_DIALOG",

  INVITE_SENT_SUCCESS:"dashboard/INVITE_SENT_SUCCESS",
  INVITE_SENT_FAILED:"dashboard/INVITE_SENT_FAILED",

  GET_LIST:"dashboard/GET_LIST",
  GET_INVITEDLIST:"dashboard/GET_INVITEDLIST",
  GET_COMAPANYINVITEDLIST:"dashboard/GET_COMAPANYINVITEDLIST",

  COMPANY_INVITE_SENT_SUCCESS:"dashboard/COMPANY_INVITE_SENT_SUCCESS",
  COMPANY_INVITE_SENT_FAILED:"dashboard/COMPANY_INVITE_SENT_FAILED",

  CLUSTER_CREATED:"dashboard/CLUSTER_CREATED",
  CLUSTER_CREATION_FAILED:"dashboard/CLUSTER_CREATION_FAILED",
  LIVE_CLUSTER_LIST:"dashboard/LIVE_CLUSTER_LIST",
  DELETE_CLUSTER:"dashboard/DELETE_CLUSTER",

  SUBSCRIPTION_PLANS_LIST:"dashboard/SUBSCRIPTION_PLANS_LIST",
  SUBSCRIBED_PLANS:"dashboard/ SUBSCRIBED_PLANS",
  SUBSCRIBE_A_PLAN:"dashboard/SUBSCRIBE_A_PLAN",
  CLUSTER_LIST:"dashboard/CLUSTER_LIST",
  CLUSTER_LIST_EMPTY:"dashboard/CLUSTER_LIST_EMPTY"

};

export default DashboardActionTypes;
