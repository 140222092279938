import React, { useState, useEffect, useContext } from "react";
import "./dashboard.scss";
import { Card, Divider, Table, Typography, Spin, List, Select } from "antd";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { withRouter } from "react-router-dom";
import _ from "lodash";
import { Form, Modal, Radio, Input, Button, Switch, Tag } from "antd";
let filterlist = [];
let uniqueFilteredList = [];
import {
    CopyOutlined,
    FilterOutlined,
    DeleteOutlined,
    StopOutlined,
    LoadingOutlined,
    EditOutlined,
} from "@ant-design/icons";
import VERBIAGE from "../../../../../../utils/VERBIAGE";
// import Log2 from "../../../../../../assets/Logs.svg";

import UIButton from "../../../../components/lib/button/Button";
import Dialog from "../../../../components/lib/dialogs/SimpleDialog";

import Multiselect from "multiselect-react-dropdown";
import AuthContext from "../../../../authentication/views/components/Auth";

import DashboardActions from "../../../redux/dashboardActions";
import Footer from "../../../../authentication/views/common/Footer";
import AuthenticationActions from "../../../../authentication/redux/AuthenticationActions";
import { getAuth } from "firebase/auth";
import url from "../../../../../../utils/URL";
let auth = getAuth();
let obj = {};
let dataSource = [];
let column = [];
let currentDataSource = [];
let selectedFilterList = [];
let id_cluster;
let create = "";
let deleted = false;
let live = false;
let error = false;
let s3Config = {};
let currentConfig = [];
let theme = sessionStorage.getItem("theme");
const Dashboard = ({
    authentication,
    createCluster,
    history,
    getLiveClusters,
    getClustersList,
    clusterList,
    dashboard,
    getClusterDetail,
    getCredentials,
    deleteCluster,
}) => {
    let { token, emailId, userId } = useContext(AuthContext);
    const MINUTE_MS = 5000;
    let navigate = useNavigate();
    let header = {
        token,
        userId,
        emailId,
    };
    const [modalVisible, setModalVisible] = useState(false);
    const [configModal, setConfigModal] = useState(false);
    const [filter, setFilter] = useState("none");
    const [clusterCreationModal, setClusterCreationModal] = useState(false);
    const [listLength, setListlength] = useState(clusterList.length);
    const [formNo, setForm] = useState(1);
    // const [create,setCreate]= useState(true)
    const [form] = Form.useForm();
    const [formStorage] = Form.useForm();
    const [tenantId, setTenantId] = useState("");
    const [tenantkey, setTenantkey] = useState("");
    const [appId, setappId] = useState("");
    const [appKey, setappKey] = useState("");
    const [loading, setLoading] = useState(true);
    const [subscription, setSubscription] = useState(true);
    const [storage, setStorage] = useState(true);
    const [edit, setEdit] = useState(false);
    const [themeloading,setThemeloading] = useState(true)

    useEffect(
        () => (
            getCredentials(header),
            getLiveClusters(header),
            getClustersList(header),
            getDataSource()
        ),
        [],
    );

    useEffect(() => {
        if (sessionStorage.getItem("expired") == "true") {
            getLoadingDetails();
            sessionStorage.removeItem("expired");
        }
        const interval = setInterval(() => {
            getLoadingDetails();
        }, 600000);
        return () => clearInterval(interval);
    });
    useEffect(
        () => (
            
            (theme==sessionStorage.getItem("theme"))?
            
                ( 
                    // (themeloading)?( setTimeout(() => setThemeloading(false), 100)):'',
                theme = sessionStorage.getItem("theme"),setThemeloading(false))
            :
           ( theme = sessionStorage.getItem("theme"),
           setThemeloading(false))
            
        ),
    );
    // useEffect(() => (theme = sessionStorage.getItem("theme")));
    useEffect(() => {
        getClustersList(header);
        getLiveClusters(header);
        getCredentials(header);
        const interval = setInterval(() => {
            if (sessionStorage.getItem("expired") == "true" || token == "" || token == null) {
                getLoadingDetails();
                sessionStorage.removeItem("expired");
                // sessionStorage.removeItem("expired")
            } else {
                getClustersList(header);
                if (clusterList.length > 0) {
                    clusterList.map((item, index) => {
                        if (
                            item.status == "QUEUED" ||
                            item.status == "STARTING" ||
                            item.status == "ERROR"
                        ) {
                            getClustersList(header);
                            getLiveClusters(header);
                            getCredentials(header);
                        }
                    });
                }
            }

            getDataSource();
        }, MINUTE_MS);

        return () => clearInterval(interval);
    }, []);

    const submitStorage = () => {
        formStorage.validateFields().then((values) => {
            Storage == undefined ? true : storage;
            s3Config = {
                "**datastore.stores.final.s3.accesskey": values.accesskey,
                "**datastore.stores.final.s3.secretkey": values.secretkey,
                "datastore.stores.final.s3.region": values.region,
                "datastore.stores.final.s3.endpoint.url":
                    values.endpoint_url == undefined || values.endpoint_url == ""
                        ? ""
                        : values.endpoint_url,
                "datastore.stores.final.s3.bucket": values.bucket,
                "datastore.stores.final.s3.sign.payload":
                    values.sign_payload == undefined ? "true" : values.sign_payload.toString(),
                "datastore.stores.final.s3.folder": values.folder,
                "datastore.stores.final.s3.work.folder": values.work_folder,
                "datastore.stores.final.s3.url.prefix": values.url_prefix==undefined?'s3://': values.url_prefix,
            };

            console.log(s3Config)
        });
    };

    const submit = () => {
        {
            form.validateFields().then((values) => {
                let config = {};
                config = {
                    //values.appId_fixed, values.appId_keys,  values.tenantId_fixed,  values.tenantId_keys
                    "indexer.schema.appid.fixed": appId == "" ? "defaultApp" : appId,
                    "indexer.schema.appid.keys": appKey,
                    "indexer.schema.tenantid.fixed": tenantId == "" ? "defaultTenant" : tenantId,
                    "indexer.schema.tenantid.keys": tenantkey,
                    "indexer.schema.exclude": values.exclude,
                    "indexer.schema.message.key": values.message_keys,
                    "indexer.schema.timestamp.formats": values.timeStamp_format,
                    "indexer.schema.timestamp.key": values.timeStamp_keys,
                    "search.labels.exclude": values.labels_exclude,
                };

                let completeconfig = { ...config, ...s3Config };

                let payload = {
                    clientId: authentication.companyDetails
                        ? authentication.companyDetails.companyId
                        : authentication.profileDetails.companyId,
                    env: values.env,
                    envType: values.envType,
                    config: JSON.stringify(completeconfig),
                };

                createCluster(payload);
                !clusterCreationModal && create != "no" ? setClusterCreationModal(true) : "";
              
                // setTimeout(()=>(),1000)
            });
        }
    };

    const details = (id) => {
        if (location.pathname.includes("/cluster")) navigate(`/cluster/serverDetails/${id}`);
        else {
            getClusterDetail(id);
            navigate(`/spike/servers/${id}`);
        }
    };

    const getCard = () => {
        return (
            <div
                className="no-subscription-messagebox"
                style={{ color: theme == "dark" ? "#FFF" : "#000" }}
            >
                <Card title={"No Clusters "} style={{ color: theme == "dark" ? "#FFF" : "#000" }}>
                    <p>You don't have any cluster running. </p>
                    <p>Click on Add cluster to start a server</p>
                    <div className="add-cluster-button-home">
                        <Button
                            onClick={() => {
                                clusterList.length == 0 || (deleted && !live)
                                    ? setModalVisible(true)
                                    : setSubscription(false);
                                deleted = false;
                                live = false;
                                error = false;

                               
                            }}
                        >
                            + Add Cluster
                        </Button>
                    </div>
                </Card>
            </div>
        );
    };

    const getDataSource = () => {
        (column = []), (dataSource = []), (obj = {});
        column = [
            {
                title: "Id",
                dataIndex: "id",
                key: "id",
                defaultSortOrder: "descend",
                sorter: (a, b) => a.id - b.id,

                render: (id) => {
                    return (
                        <Tag
                            key={id}
                            // className="server-id-button"
                            style={{ color: theme == "dark" ? "#DD0C4E" : "#DD0C4E" }}
                            // onClick={() => details(id)}
                        >
                            <span>{id}</span>
                        </Tag>
                    );
                },
            },
            { title: "Cloud", dataIndex: "cloud", key: "cloud" },
            {
                title: "Cluster Name",
                dataIndex: "cluster_name",
                key: "cluster_name",
                render: (cluster_name) => {
                    return (
                        <Tag
                            key={cluster_name}
                            style={{
                                backgroundColor: "transparent",
                                color: theme == "dark" ? "#DCDCDC" : "#3a3a3a",
                            }}
                        >
                            <span>{cluster_name.toLowerCase()}</span>
                        </Tag>
                    );
                },
            },
            { title: "Env Name", dataIndex: "env", key: "env" },
            {
                title: "Env Type",
                dataIndex: "env_type",
                key: "env_type",
                render: (env_type) => {
                    return (
                        <Tag
                            key={env_type}
                            style={{
                                backgroundColor: "transparent",
                                color: theme == "dark" ? "#DCDCDC" : "#3a3a3a",
                            }}
                        >
                            <span>{env_type.toUpperCase()}</span>
                        </Tag>
                    );
                },
            },
            // { title: "Start ts", dataIndex: "start_ts", key: "start_ts" },
            { title: "Start timestamp", dataIndex: "start_time", key: "start_time" },
            // { title: "Last ts", dataIndex: "last_ts", key: "last_ts" },
            { title: "Last timestamp", dataIndex: "last_time", key: "last_time" },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (status) => {
                    // active_state=status
                    return (
                        <Tag
                            key={status}
                            style={{
                                height: 30,
                                textAlign: "center",
                                justifyContent: "center",
                                paddingTop: 5,
                            }}
                            color={
                                theme == "dark"
                                    ? "transparent"
                                    : // status == "ERROR"
                                    //     ? "#000"
                                    //     : status == "QUEUED"
                                    //     ? "#000"
                                    //     : status == "LIVE"
                                    //     ? "#000"
                                    //     : "#000"
                                    status == "ERROR"
                                    ? "#FDEDEC"
                                    : status == "QUEUED"
                                    ? "#F6E2FF"
                                    : status == "LIVE"
                                    ? "#D5F5E3"
                                    : "#D1E8D7"
                            }
                        >
                            <div>
                                <span
                                    style={{
                                        color:
                                            theme == "dark"
                                                ? status == "ERROR"
                                                    ? "#FDEDEC"
                                                    : status == "QUEUED"
                                                    ? "#B93BF0"
                                                    : status == "LIVE"
                                                    ? "#4DF284"
                                                    : "#5A75F9"
                                                : status == "ERROR"
                                                ? "#CB4335"
                                                : status == "QUEUED"
                                                ? "#D16FFF"
                                                : status == "LIVE"
                                                ? "#239B56"
                                                : "#5479FB",

                                        fontSize: 15,

                                        fontWeight: 600,
                                        paddingTop: 15,
                                        justifyContent: "center",
                                    }}
                                >
                                    {status}
                                </span>
                                {status == "STOPPED" ? (deleted = true) : ""}
                                {status == "LIVE" ? (live = true) : ""}
                                {status == "ERROR" ? (error = true) : (error = false)}
                            </div>
                        </Tag>
                    );
                },
            },
            { title: "Release version", dataIndex: "release_version", key: "release_version" },
            {
                title: "Config",
                dataIndex: "id",
                key: "id",
                render: (id) => {
                    return (
                        <Tag
                            className="server-config-button"
                            key={id}
                            onClick={() => (setConfigModal(true), (id_cluster = id))}
                        >
                            <span style={{ color: theme == "dark" ? "#DD0C4E" : "#DD0C4E" }}>
                                {" "}
                                View config{" "}
                            </span>
                        </Tag>
                    );
                },
            },
            // {
            //     title: "Storage",
            //     dataIndex: "id",
            //     key: "id",
            //     render: (id) => {
            //         return (
            //             <Tag
            //                 className="server-config-button"
            //                 key={id}
            //                 onClick={() => console.log(id)}
            //             >
            //                 {/* View Storage */}
            //                 {/* <Select
            //                     defaultValue="Episilia"
            //                     style={{ width: 120 }}
            //                     onChange={(value) => console.log(`selected ${value}`)}
            //                 >
            //                     <Option value="own">Own</Option>
            //                     <Option value="Episilia">Episilia</Option>
            //                 </Select> */}
            //                 {/* <span style={{ color: theme == "dark" ? "#41E799" : "#3d36f9" }}>
            //                    Use your own storage 
            //                 </span> */}
            //             </Tag>
            //         );
            //     },
            // },
            {
                title: "",
                dataIndex: "cluster_name",
                key: "cluster_name",
                render: (cluster_name, cluster) => {
                    // console.log(cluster_name,cluster.status)
                    return (
                        <Tag
                            className="cluster-delete-button"
                            key={cluster_name}
                            style={{ color: theme == "dark" ? "#DCDCDC" : "#3a3a3a" }}
                        >
                            {cluster.status == "LIVE" || cluster.status == "ERROR" ? (
                                <DeleteOutlined
                                    style={{ color: theme == "dark" ? "#DCDCDC" : "#3a3a3a" }}
                                    className="delete-outlined"
                                    onClick={() => (
                                        deleteCluster(cluster_name),
                                        window.location.reload(),
                                        getClustersList(header)
                                    )}
                                />
                            ) : cluster.status != "STOPPED" ? (
                                <LoadingOutlined
                                    style={{ color: theme == "dark" ? "#DCDCDC" : "#3a3a3a" }}
                                />
                            ) : (
                                <StopOutlined
                                    style={{ color: theme == "dark" ? "#DCDCDC" : "#3a3a3a" }}
                                />
                            )}
                        </Tag>
                    );
                },
            },
        ];

        !_.isEmpty(clusterList) &&
            clusterList.length > 0 &&
            clusterList.map((item, index) => {
                filter != "done"
                    ? ((obj["id"] = item.id),
                      (obj["cloud"] = item.cloud),
                      (obj["cluster_name"] = item.cluster_name),
                      (obj["env"] = item.env),
                      (obj["env_type"] = item.env_type),
                      (obj["status"] = item.status),
                      (obj["release_version"] = item.release_version),
                      (obj["start_ts"] = item.start_ts),
                      (obj["start_time"] = item.start_time),
                      (obj["last_ts"] = item.last_ts),
                      (obj["last_time"] = item.last_time),
                      dataSource.push({ ...obj, obj }))
                    : "";
            });
    };

    const onSelect = (selectedList, selectedItem) => {
        selectedFilterList.push(selectedItem);

        clusterList.map((item, index) => {
            item.env_type == selectedItem.name ? currentDataSource.push(item) : "";
        });

        if (filter == "done") {
            currentDataSource = [];
            setFilter("none");
            selectedFilterList = [];
        }
    };
    const onRemove = (selectedList, selectedItem) => {
        if (filter == "none")
            clusterList.map((item, index) => {
                item.env_type == selectedItem.name ? "" : currentDataSource.pop(item);
            });
    };

    const getList = () => {
        currentDataSource = [];
        uniqueFilteredList.map((item) => onRemove(uniqueFilteredList, item));
        selectedFilterList = [];
    };

    const getFilter = () => {
        filterlist = [];
        uniqueFilteredList = [];
        //  filterlist.push({text:"Demo", value:"demo"})
        clusterList.length > 0 && clusterList.map((item, index) => filterlist.push(item.env_type));

        filterlist.slice(0, filterlist.length);

        uniqueFilteredList = Array.from(new Set(filterlist));

        filterlist = [];
        uniqueFilteredList.map((entry, index) => filterlist.push({ name: entry, id: index + 1 }));

        filterlist.slice(filterlist.length / 2);

        filterlist = [...new Set(filterlist)];
    };

    const setLoadingDetails = () => {
        setTimeout(() => setLoading(false), 100);
    };
    const getLoadingDetails = () => {
        auth.onAuthStateChanged(async (user) => {
            if (sessionStorage.getItem("logout") == "false") {
                // console.log(user);
                // (token = user.accessToken) ;
                token = await user.getIdToken();
                emailId = user.email;
                userId = user.uid;

                header = {
                    token,
                    emailId,
                    userId,
                };
                getCredentials(header);
                getClustersList(header);
                getLiveClusters(header);
            }
            // else {
            //     sessionStorage.clear();
            //     localStorage.clear();
            //     auth.signOut();
            //     navigate("/");
            //     window.location.reload();
            // }
        });

        if (sessionStorage.getItem("expired") == "true") {
            sessionStorage.removeItem("expired");
        }
        //     }
        // }
    };
    return (
        // <div  style={{backgroundColor:'#000',marginTop:'-29em',height:'100vh',position:'relative'}}>

        <div
            className="div-dashboard-all-clusters"
            style={{ backgroundColor: theme == "dark" ? "#282828" : "transparent" }}
        >
            {loading ? (getClustersList(header), setLoadingDetails()) : ""}
            {getDataSource()}
            {getFilter()}
            {/* {console.log(filter)} */}
            <span style={{ color: "yellow" }}>filter</span>
            {token == null || sessionStorage.getItem("expired") == "true" || token == ""
                ? getLoadingDetails()
                : ""}

            {authentication.plans.length > 0
                ? authentication.plans[0] == "SAAS-1" ||
                  authentication.plans[1] == "SAAS-1" ||
                  authentication.plans[2] == "SAAS-1"
                    ? sessionStorage.setItem("saas", "true")
                    : console.log("Else")
                : console.log(authentication.plans)}

            {authentication.subscribed && sessionStorage.getItem("saas") == "true" && !themeloading ? (
                <div style={{marginTop:'7em'}}>
                    {!dashboard.clusters_available && !loading && getCard()}
                    <div className="modal-create-cluster">
                        <Modal
                            className="modal-create-cluster"
                            // width={"52%"}
                            style={{ marginLeft: "auto", marginRight: "auto" }}
                            visible={modalVisible}
                            title={"Create Cluster"}
                            onCancel={() => (setModalVisible(false), form.resetFields(), formStorage.resetFields())}
                            footer={null}
                        >
                           
                            {formNo == 1 && (
                                <Form className="form-storage" form={formStorage}>
                                    <Form.Item
                                        name="Storage"
                                        label="Own storage space"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input your env name!",
                                            },
                                        ]}
                                    >
                                        <Switch
                                            defaultChecked={storage}
                                            onChange={() => setStorage(!storage)}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Enter accesskey!",
                                            },
                                        ]}
                                        name="accesskey"
                                        label="Access key"
                                    >
                                        <Input
                                            className="input-component"
                                            style={{ marginLeft: "4em" }}
                                            disabled={!storage}
                                            placeholder="Enter the access key"
                                        ></Input>
                                    </Form.Item>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Enter secretkey!",
                                            },
                                        ]}
                                        name="secretkey"
                                        label="Secret keys"
                                    >
                                        <Input
                                            style={{ marginLeft: "3.75em" }}
                                            disabled={!storage}
                                            placeholder="Enter the secret key"
                                        ></Input>
                                    </Form.Item>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Enter the region!",
                                            },
                                        ]}
                                        name="region"
                                        label="Region"
                                    >
                                        <Input
                                            style={{ marginLeft: "5.9em" }}
                                            disabled={!storage}
                                            placeholder="Enter the region"
                                        ></Input>
                                    </Form.Item>
                                    <Form.Item
                                        rules={[
                                            {
                                                // required: true,
                                                message: "Enter the endpoint.url!",
                                            },
                                        ]}
                                        name="endpoint_url"
                                        label="URL Endpoint"
                                    >
                                        <Input
                                            disabled={!storage}
                                            style={{ marginLeft: "3em" }}
                                            placeholder="Enter the endpoint.url"
                                        ></Input>
                                    </Form.Item>

                                    <Form.Item
                                        name="sign_payload"
                                        label="Sign Payload"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input your env name!",
                                            },
                                        ]}
                                    >
                                        <Switch
                                            style={{ marginLeft: "3em" }}
                                            disabled={!storage}
                                            defaultChecked
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Enter the bucket name!",
                                            },
                                        ]}
                                        name="bucket"
                                        label="Bucket"
                                    >
                                        <Input
                                            style={{ marginLeft: "5.85em" }}
                                            disabled={!storage}
                                            placeholder="episilia-bucket"
                                        ></Input>
                                    </Form.Item>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Enter the folder name!",
                                            },
                                        ]}
                                        name="folder"
                                        label="Folder"
                                    >
                                        <Input
                                            style={{ marginLeft: "6em" }}
                                            disabled={!storage}
                                            placeholder="episilia-folder"
                                        ></Input>
                                    </Form.Item>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Enter the work folder name!",
                                            },
                                        ]}
                                        name="work_folder"
                                        label="Work folder"
                                    >
                                        <Input
                                            style={{ marginLeft: "3.3em" }}
                                            disabled={!storage}
                                            placeholder="work-folder"
                                        ></Input>
                                    </Form.Item>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Enter the url prefix!",
                                            },
                                        ]}
                                        name="url_prefix"
                                        label="URL prefix"
                                    >
                                        <Input
                                            style={{ marginLeft: "4.4em" }}
                                            disabled={!storage || !edit}
                                            placeholder="s3://"
                                        ></Input>
                                        <EditOutlined style={{marginLeft:'5px'}} onClick={()=>setEdit(true)}/>
                                    </Form.Item>

                                    

                                    

                                    <Form.Item
                                        wrapperCol={{
                                            offset: 8,
                                            span: 16,
                                        }}
                                    >
                                        <div className="submit-button-create-cluster">
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                style={{ width: "85px" }}
                                                onClick={() => (
                                                    setForm(2),
                                                    setClusterCreationModal(false),
                                                    submitStorage()
                                                )}
                                                // disabled={clusterCreationModal}
                                            >
                                                Next
                                            </Button>
                                        </div>
                                        <div className="cancel-button-create-cluster">
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                disabled={clusterCreationModal}
                                                onClick={() => (
                                                    setModalVisible(false),
                                                    formStorage.resetFields()
                                                )}
                                            >
                                                Cancel
                                            </Button>
                                            <div className="reset-to-config">
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    onClick={() => formStorage.resetFields()}
                                                >
                                                    Reset
                                                </Button>
                                            </div>
                                        </div>
                                    </Form.Item>
                                </Form>
                            )}
                            {formNo == 2 && (
                                <Form
                                    name="basic"
                                    labelCol={{
                                        span: 8,
                                    }}
                                    form={form}
                                    wrapperCol={{
                                        span: 16,
                                    }}
                                    initialValues={{
                                        exclude: "",
                                        labels_exclude: "",
                                        // remember: true,
                                    }}
                                    // onFinish={onFinish}
                                    autoComplete="off"
                                >
                                    <Form.Item
                                        name="env"
                                        label="Environment Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input your env name!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            maxLength={12}
                                            placeholder="Enter your env name (12 characters)"
                                        ></Input>
                                    </Form.Item>

                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please choose your env type!",
                                            },
                                        ]}
                                        name="envType"
                                        label="Environment Type"
                                    >
                                        <Radio.Group className="send-invite-radio">
                                            <Radio value="dev">Development</Radio>
                                            <Radio value="qa">QA</Radio>

                                            <Radio value="uat">UAT</Radio>
                                            <Radio value="prod">Production</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item
                                        rules={[
                                            {
                                                required: appKey == "",
                                                message: "Enter app id!",
                                            },
                                        ]}
                                        name="appId_fixed"
                                        label="App Id Fixed"
                                    >
                                        <Input
                                            placeholder="Enter your app id fixed (32 characters)"
                                            name="appId_fixed"
                                            maxLength={32}
                                            disabled={appKey != ""}
                                            onChange={(e) => setappId(e.target.value)}
                                        ></Input>
                                    </Form.Item>
                                    {/* <p>OR</p> */}

                                    <Form.Item
                                        rules={[
                                            {
                                                required: appId == "",
                                                message: "Enter app id!",
                                            },
                                        ]}
                                        name="appId_keys"
                                        label="App Id keys"
                                    >
                                        <Input
                                            placeholder="Enter your app id keys"
                                            name="appId_keys"
                                            disabled={appId != ""}
                                            onChange={(e) => setappKey(e.target.value)}
                                        ></Input>
                                    </Form.Item>

                                    <Form.Item
                                        // valuePropName="checked"
                                        name="tenantId_fixed"
                                        label="Tenant Id fixed"
                                        rules={[
                                            {
                                                required: tenantkey == "",
                                                message: "Enter tenant id!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Enter your tenant id fixed (32 characters)"
                                            maxLength={32}
                                            disabled={tenantkey != ""}
                                            onChange={(e) => setTenantId(e.target.value)}
                                        ></Input>
                                        {/* <Switch /> */}
                                    </Form.Item>

                                    <Form.Item
                                        rules={[
                                            {
                                                required: tenantId == "",
                                                message: "Enter your tenant id!",
                                            },
                                        ]}
                                        name="tenantId_keys"
                                        label="Tenant Id keys"
                                    >
                                        <Input
                                            placeholder="Enter your tenant id keys"
                                            disabled={tenantId != ""}
                                            onChange={(e) => setTenantkey(e.target.value)}
                                        ></Input>
                                    </Form.Item>

                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Enter the message key!",
                                            },
                                        ]}
                                        name="message_keys"
                                        label="Message key"
                                    >
                                        <Input placeholder="Enter your message keys"></Input>
                                    </Form.Item>

                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Enter the timestamp format!",
                                            },
                                        ]}
                                        name="timeStamp_format"
                                        label="Timestamp format"
                                    >
                                        <Input placeholder=" %Y-%m-%dT%H:%M:%S "></Input>
                                    </Form.Item>

                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Enter timestamp keys!",
                                            },
                                        ]}
                                        name="timeStamp_keys"
                                        label="Timestamp keys"
                                    >
                                        <Input placeholder="Enter the timestamp keys"></Input>
                                    </Form.Item>

                                    <Form.Item name="exclude" label="Keys to be excluded">
                                        <Input placeholder="Labels excluded from index and data files"></Input>
                                    </Form.Item>
                                    <Form.Item
                                        name="labels_exclude"
                                        label="Search label to be excluded"
                                    >
                                        <Input placeholder="Lables excluded from grafana dropdown GUI"></Input>
                                    </Form.Item>

                                    <Form.Item
                                        wrapperCol={{
                                            offset: 8,
                                            span: 16,
                                        }}
                                    >
                                        <div className="back-button-create-cluster">
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                onClick={() => setForm(1)}
                                                disabled={clusterCreationModal}
                                            >
                                                Back
                                            </Button>
                                        </div>
                                        <div className="submit-button-create-cluster">
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                onClick={() => submit()}
                                                disabled={clusterCreationModal}
                                            >
                                                Submit
                                            </Button>
                                        </div>

                                        <div className="cancel-button-create-cluster">
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                disabled={clusterCreationModal}
                                                onClick={() => (
                                                    setModalVisible(false), form.resetFields(), formStorage.resetFields()
                                                )}
                                            >
                                                Cancel
                                            </Button>
                                            <div className="reset-to-config">
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    onClick={() => (
                                                        form.resetFields(),
                                                        formStorage.resetFields(),
                                                        setappId(""),
                                                        setappKey(""),
                                                        setTenantId(""),
                                                        setTenantkey("")
                                                    )}
                                                >
                                                    Reset
                                                </Button>
                                            </div>
                                        </div>
                                    </Form.Item>
                                </Form>
                            )}

                            {clusterCreationModal && !dashboard.cluster && (
                                <div className="loading-cluster-creation">
                                    <Spin tip="Creating..." />
                                   
                                </div>
                            )}
                        </Modal>
                    </div>
                    <Modal
                        visible={configModal}
                        title={"Config details"}
                        onCancel={() => setConfigModal(false)}
                        footer={null}
                        className="config-details-modal-values"
                    >
                        <div>
                            {clusterList.length > 0 &&
                                clusterList.map(
                                    (item, index) =>
                                        item.id == id_cluster ? (
                                            <div>
                                                <table  className="indexer-table">
                                                <thead><h5>Cluster Config</h5></thead>
                                                    <tr >
                                                        <td>indexer.schema.appid.fixed:</td>
                                                        <td >
                                                            {
                                                                item.config[
                                                                    "indexer.schema.appid.fixed"
                                                                ]
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>indexer.schema.appid.keys:</td>
                                                        <td>
                                                            {
                                                                item.config[
                                                                    "indexer.schema.appid.keys"
                                                                ]
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>indexer.schema.tenantid.fixed:</td>
                                                        <td>
                                                            {" "}
                                                            {
                                                                item.config[
                                                                    "indexer.schema.tenantid.fixed"
                                                                ]
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>indexer.schema.tenantid.keys:</td>
                                                        <td>
                                                            {
                                                                item.config[
                                                                    "indexer.schema.tenantid.keys"
                                                                ]
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>indexer.schema.exclude:</td>
                                                        <td>
                                                            {item.config["indexer.schema.exclude"]}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>indexer.schema.message.key:</td>
                                                        <td>
                                                            {
                                                                item.config[
                                                                    "indexer.schema.message.key"
                                                                ]
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>indexer.schema.timestamp.formats:</td>
                                                        <td>
                                                            {
                                                                item.config[
                                                                    "indexer.schema.timestamp.formats"
                                                                ]
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>indexer.schema.timestamp.key:</td>
                                                        <td>
                                                            {
                                                                item.config[
                                                                    "indexer.schema.timestamp.key"
                                                                ]
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>search.labels.exclude:</td>
                                                        <td>
                                                            {item.config["search.labels.exclude"]}
                                                        </td>
                                                    </tr>
                                                </table>
                                              
                                                {item.config[
                                                    "datastore.stores.final.s3.bucket"
                                                ] != undefined && 
                                                        <table>
                                                            <thead><h5>Storage Config</h5></thead>
                                                            {/* <tr>
                                                                <td>
                                                                    datastore.stores.final.s3.accesskey:
                                                                </td>
                                                                <td style={{ wordBreak: "break-all"}}>
                                                                    {
                                                                        item.config[
                                                                            "**datastore.stores.final.s3.accesskey"
                                                                        ]
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    **datastore.stores.final.s3.secretkey:
                                                                </td>
                                                                <td style={{ wordBreak: "break-all"}}>
                                                                    {
                                                                        item.config[
                                                                            "**datastore.stores.final.s3.secretkey"
                                                                        ]
                                                                    }
                                                                </td>
                                                            </tr> */}

                                                            <tr>
                                                                <td>
                                                                    datastore.stores.final.s3.bucket:
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.config[
                                                                            "datastore.stores.final.s3.bucket"
                                                                        ]
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    datastore.stores.final.s3.endpoint.url:
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.config[
                                                                            "datastore.stores.final.s3.endpoint.url"
                                                                        ]
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    datastore.stores.final.s3.folder:
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.config[
                                                                            "datastore.stores.final.s3.folder"
                                                                        ]
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    datastore.stores.final.s3.sign.payload:
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.config[
                                                                            "datastore.stores.final.s3.sign.payload"
                                                                        ]
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    datastore.stores.final.s3.region:
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.config[
                                                                            "datastore.stores.final.s3.region"
                                                                        ]
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    datastore.stores.final.s3.url.prefix:
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.config[
                                                                            "datastore.stores.final.s3.url.prefix"
                                                                        ]
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    datastore.stores.final.s3.work.folder:
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.config[
                                                                            "datastore.stores.final.s3.work.folder"
                                                                        ]
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    }       
                                            </div>
                                        ) : (
                                            <div />
                                        ),
                                    // </div>
                                )}
                        </div>
                    </Modal>

                    {location.pathname.includes("/spike") && dashboard.clusters_available ? (
                        <div className={"add-cluster-button-home-top-right"}>
                            <Button
                                onClick={() => {
                                    clusterList.length == 0 || (deleted && !live)
                                        ? setModalVisible(true)
                                        : setSubscription(false);
                                    deleted = false;
                                    live = false;
                                    error = false;

                                    formStorage.resetFields();
                                    setEdit(false)
                                }}
                            >
                                + Add Cluster
                            </Button>
                        </div>
                    ) : (
                        <div />
                    )}

                    {!_.isEmpty(clusterList) && (
                        <div
                            className="cluster-list-table"
                            style={{ backgroundColor: theme == "dark" ? "#282828" : "transparent" }}
                        >
                            {clusterList.length > 0 && window.screen.width > 900 && (
                                <div className="apply-filter-button-dashboard">
                                    <Button
                                        onClick={() => (setFilter("done"), getDataSource())}
                                        style={{
                                            backgroundColor:
                                                theme == "dark" ? "#282828" : "transparent",
                                            color: theme == "dark" ? "#DCDCDC" : "#3A3A3A",
                                        }}
                                    >
                                        Apply Filter
                                    </Button>
                                </div>
                            )}
                            {clusterList.length > 0 && window.screen.width > 900 && (
                                <div className="clear-filter-button-dashboard">
                                    {" "}
                                    <Button
                                        style={{
                                            backgroundColor:
                                                theme == "dark" ? "#282828" : "transparent",
                                            color: theme == "dark" ? "#DCDCDC" : "#3A3A3A",
                                        }}
                                        onClick={() => (
                                            setFilter("none"), getList(), (filterlist = [])
                                        )}
                                    >
                                        Clear Filter
                                    </Button>
                                </div>
                            )}

                            {filter == "done" && (
                                <div className="selected-env-types">
                                    {selectedFilterList.length > 0
                                        ? selectedFilterList.map((item, index) => (
                                              <span key={index}>{item.name}</span>
                                          ))
                                        : ""}
                                </div>
                            )}

                            {filter != "done" ? (
                                window.screen.width > 900 ? (
                                    <div
                                        className="dropdown-select-env-type"
                                        style={{
                                            backgorundColor:
                                                theme == "dark" ? "000" : "transparent",
                                        }}
                                    >
                                        <FilterOutlined className="filter-icon" />
                                        <Multiselect
                                            style={{ width: 400 }}
                                            className="dropdown-select-env"
                                            options={filterlist}
                                            placeholder="Search an environment"
                                            displayValue="name"
                                            clearable={true}
                                            onSelect={(selectedList, selectedItem) =>
                                                onSelect(selectedList, selectedItem)
                                            }
                                            onRemove={(selectedList, removedItem) =>
                                                onRemove(selectedList, removedItem)
                                            }
                                        />
                                        <Table
                                            className={theme == "dark" ? "dark-table" : ""}
                                            scroll={{ x: 500 }}
                                            rowKey={"id"}
                                            columns={column}
                                            // scroll={{ x: true }}
                                            dataSource={dataSource}
                                            showSorterTooltip={false}
                                            rowClassName={(record, index) =>
                                                index % 2 == 0 ? "light" : "dark"
                                            }
                                        />
                                        <h4
                                            style={{color:'#888888'
                                                // color: theme == "dark" ? "#DCDCDC" : "3A3A3A",marginTop:'-10px'
                                            }}
                                        >
                                            Note:{" "}
                                        </h4>
                                        <div
                                            className="logs-url"
                                            style={{color:'#888888'
                                                // color: theme == "dark" ? "#DCDCDC" : "3A3A3A"
                                                ,marginTop:'-10px'
                                            }}
                                        >
                                            Push your logs to{" "}
                                            <span className="url-link">
                                                http://ingest-1.episilia.net/ingest
                                                <CopyOutlined
                                                    style={{color:'#888888'
                                                        // color:
                                                        //     theme == "dark" ? "#DCDCDC" : "3A3A3A",
                                                    }}
                                                    className="copy-outlined-button"
                                                    onClick={() =>
                                                        navigator.clipboard.writeText(
                                                            "http://ingest-1.episilia.net/ingest",
                                                        )
                                                    }
                                                />
                                            </span>{" "}
                                            <span className="doc-link">
                                                For more details, refer to{" "}
                                                <a
                                                    href="https://docs.episilia.com/product-docs/saas-setup"
                                                    target="_blank"
                                                >
                                                    doc
                                                </a>
                                            </span>{" "}
                                        </div>
                                        {/* </div> */}
                                    </div>
                                ) : (
                                    <div>
                                        <h5>List of clusters</h5>
                                        <List
                                            bordered
                                            itemLayout="horizontal"
                                            dataSource={dataSource}
                                            renderItem={(item) => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        //   avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                                        title={`${item.id} - ${item.cluster_name}`}
                                                        description={`Env- ${item.env} , Env type- ${item.env_type}, Status- ${item.status}`}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                        {window.screen.width < 900 ? (
                                            <span style={{ marginTop: 5, fontFamily: "Poppins" }}>
                                                For more details, View this website in a desktop
                                                browser
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                )
                            ) : (
                                <div />
                            )}
                            {filter == "done" && (
                                <div className="dropdown-select-env">
                                    {window.screen.width > 900 && (
                                        <>
                                            {" "}
                                            <FilterOutlined className="filter-icon" />
                                            <Multiselect
                                                style={{ width: 400 }}
                                                className="dropdown-select-env"
                                                options={filterlist}
                                                placeholder="Search an environment"
                                                displayValue="name"
                                                onSelect={(selectedList, selectedItem) =>
                                                    onSelect(selectedList, selectedItem)
                                                }
                                                onRemove={(selectedList, removedItem) =>
                                                    onRemove(selectedList, removedItem)
                                                }
                                            />
                                        </>
                                    )}

                                    {/* <div> */}
                                    <Table
                                        className={theme == "dark" ? "dark-table" : ""}
                                        style={{ marginTop: "2.5em" }}
                                        scroll={{ x: 500 }}
                                        rowKey={"id"}
                                        columns={column}
                                        dataSource={currentDataSource}
                                        showSorterTooltip={false}
                                        rowClassName={(record, index) =>
                                            index % 2 == 0 ? "light" : "dark"
                                        }
                                    />
                                   <div
                                            className="logs-url"
                                            style={{color:'#888888'
                                                // color: theme == "dark" ? "#DCDCDC" : "3A3A3A"
                                                ,marginTop:'10px'
                                            }}
                                        >
                                            Push your logs to{" "}
                                            <span className="url-link">
                                                http://ingest-1.episilia.net/ingest
                                                <CopyOutlined
                                                    style={{color:'#888888'
                                                        // color:
                                                        //     theme == "dark" ? "#DCDCDC" : "3A3A3A",
                                                    }}
                                                    className="copy-outlined-button"
                                                    onClick={() =>
                                                        navigator.clipboard.writeText(
                                                            "http://ingest-1.episilia.net/ingest",
                                                        )
                                                    }
                                                />
                                            </span>{" "}
                                            <span className="doc-link">
                                                For more details, refer to{" "}
                                                <a
                                                    href="https://docs.episilia.com/product-docs/saas-setup"
                                                    target="_blank"
                                                >
                                                    doc
                                                </a>
                                            </span>{" "}
                                        </div>
                                    {/* </div> */}
                                </div>
                            )}
                        </div>
                    )}

                    {dashboard.cluster && (
                        <div>
                             {clusterCreationModal&& modalVisible &&  dashboard.creation_message.includes("created")
                                    ? setModalVisible(false):console.log("")}
                        <Dialog
                            isModalVisible={
                                dashboard.cluster && clusterCreationModal
                                //&& listLength != clusterList.length
                            }
                            onClose={() => (setClusterCreationModal(false), setModalVisible(false))}
                            content={dashboard.creation_message.includes("created")?"Cluster creation in progress":dashboard.creation_message}
                            title={
                                dashboard.creation_message.includes("created")
                                    ? "Provisioning cluster"
                                    : "Error"
                            }
                            variant={
                                dashboard.creation_message.includes("created") ? "success" : "error"
                            }
                            actionText={
                                dashboard.creation_message.includes("created")
                                    ? VERBIAGE.BUTTONS.CONTINUE
                                    : VERBIAGE.BUTTONS.TRY_AGAIN
                            }
                            onActionClick={() =>
                                dashboard.creation_message.includes("created")
                                    ? (form.resetFields(),
                                      setClusterCreationModal(false),
                                      setModalVisible(false),
                                      setListlength(listLength + 1))
                                    : (setClusterCreationModal(false))
                            }
                        />
                        </div>
                    )}

                    {!subscription && (
                        <Dialog
                            isModalVisible={!subscription}
                            onClose={() => {
                                setSubscription(!subscription);
                                navigate(VERBIAGE.NAVIGATION.PATHS.CRMDASHBOARD);
                            }}
                            content={
                                "You have created one cluster. Contact the support team to create more clusters"
                            }
                            title={"Cluster Limit Exceeded"}
                            variant={"error"}
                            actionText={VERBIAGE.BUTTONS.CONTACT}
                            onActionClick={() => {
                                navigate(VERBIAGE.NAVIGATION.PATHS.SUPPORT);
                            }}
                        />
                    )}

                    {/* <div
                        className={
                            !dashboard.clusters_available
                                ? "footer-dashboard-nocluster"
                                : "footer-dashboard-cluster-list"
                        }
                    >
                        <Footer />
                    </div> */}
                </div>
            ) : (
                // setTimeout(()=>(
                <div>
                    <div className="no-subscription-messagebox">
                        <Card title={"No Active Subscriptions"}>
                            {sessionStorage.getItem("saas") == null &&
                            sessionStorage.getItem("self") == null ? (
                                <h5
                                    style={{
                                        color: theme == "dark" ? "#DCDCDC" : "3A3A3A",
                                    }}
                                >
                                    You don't have any active subscription.{" "}
                                </h5>
                            ) : (
                                <h5
                                    style={{
                                        color: theme == "dark" ? "#DCDCDC" : "3A3A3A",
                                    }}
                                >
                                    You don't have any active SaaS plan
                                </h5>
                            )}
                            <Button
                                style={{ marginTop: 15 }}
                                onClick={() => navigate(VERBIAGE.NAVIGATION.PATHS.PLAN)}
                            >
                                Click here to subscribe
                            </Button>
                        </Card>
                    </div>

                    {/* <div className="footer-no-subscription">
                        <Footer />
                    </div> */}
                </div>

                // ),1000)
            )}
        </div>
        // </div>
    );
};

const mapStateToProps = (state) => ({
    authentication: state.authentication,
    dashboard: state.dashboard,
    clusterList: state.dashboard.clusterList,
});

const mapDispatchToProps = (dispatch) => ({
    createCluster: (credential) => {
        dispatch(DashboardActions.createCluster(credential));
    },
    getLiveClusters: (header) => {
        dispatch(DashboardActions.getLiveClusters(header));
    },
    getClustersList: (header) => {
        dispatch(DashboardActions.getClustersList(header));
    },
    getClusterDetail: (id) => {
        dispatch(DashboardActions.getClusterDetail(id));
    },
    getCredentials: (header) => {
        dispatch(AuthenticationActions.getCredentials(header));
    },
    deleteCluster: (id) => {
        dispatch(DashboardActions.deleteCluster(id));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
