import _ from "lodash";
import api from "../utils/API";
import CpanelActionTypes from "./CpanelActionTypes";
import URLBuilder from "../utils/Url_builder";
import URL from "../utils/URL";
import header from "../utils/Header";



let Headeroptions;
const CpanelActions = {
    liveClusterDetails: (header) => (dispatch) => {
    //   console.log(headers)

      const headers= {"auth-token":header.token,"uid":header.userId,"email":header.emailId}
      const headeroptions =  {headers} ;
        // console.log(headeroptions)
        Headeroptions=headeroptions;
        api.get(URLBuilder.build(`${URL.DAHSBOARD.CLUSTER}`),  headeroptions )
            // api.get("https://pulse-api.episilia.com/pulse/cluster",headeroptions)
            .then((response) => {
                if (response.data) {
                    dispatch({
                        type: CpanelActionTypes.LIVE_CLUSTER_DETAILS,
                        payload: response.data,
                    });
                } else {
                    console.log("response", response);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },

    ServerDetails: (id, name) => (dispatch) => {
        console.log("server");
        // const headers=header()
        const num = id;

        console.log(name);

        api.get(URLBuilder.build(`${name}${URL.DAHSBOARD.SERVER}${num.id}`), Headeroptions)
            //  api.get(`https://pulse-api.episilia.com/pulse/cluster/${num.id}`,{headers})
            .then((response) => {
                console.log(response.data);
                if (response.data) {
                    dispatch({
                        type: CpanelActionTypes.SERVER_DETAILS_CPANEL,
                        payload: response.data,
                    });
                } else {
                    console.log("response", response);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },

    navigateToPath: (props, path) => {
        props.history.push(path);
    },
};

export default CpanelActions;
