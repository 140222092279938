import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import UIRow from "../grid/Row";
import UICol from "../grid/Col";
import UIBaseCard from "../card/BaseCard";
import { oneOfProperties } from "../../utils/propTypes";

import "./menuItem.scss";

const handleClick = (onSelect, id, isDisabled) => () => {
  if (!isDisabled) {
    onSelect(id);
  }
};

const getClassNames = (isSelected, isDisabled) => classNames([
  { "menu-item": true },
  { "menu-item-selected": isSelected },
  { "menu-item-disabled": isDisabled },
  { "margin-small": !isSelected }
]);

const UIMenuItem = ({ icon, iconAlign, text, id, isDisabled, isSelected, onSelect, textClassName, iconClassName}) => {
  if (isSelected) {
    return <UIBaseCard
      margin={UIBaseCard.margin.none}
      classnames={getClassNames(true, isDisabled)}
      content={
        <div
          style={{
            display: "flex",
            direction: iconAlign === UIMenuItem.iconAlign.left ? "row" : "row-reverse",
            width: "100%"
          }}
          onClick={handleClick(onSelect, id, isDisabled)}
        >
          
          { icon ? (
            <>
              <div className={iconClassName}>
                {icon}
              </div>
              <div style={{ width: "10px" }} />
            </>
          ): null }
          <div className={textClassName}>
            {text}
          </div>
        </div>
      }
    />
  }

  return <div className={getClassNames(false, isDisabled)}>
      <div
        style={{
          display: "flex",
          direction: iconAlign === UIMenuItem.iconAlign.left ? "row" : "row-reverse",
          width: "100%"
        }}
        onClick={handleClick(onSelect, id, isDisabled)}
      >
        <div className={iconClassName}>
          {icon}
        </div>
        { icon ? <div style={{ width: "10px" }} /> : null }
        <div className={textClassName}>
          {text}
        </div>
      </div>
  </div>
}

UIMenuItem.iconAlign = {
  left: "left",
  right: "right"
};

UIMenuItem.defaultProps = {
  iconAlign: UIMenuItem.iconAlign.left,
  isDisabled: false,
  isSelected: false
}

UIMenuItem.propTypes = {
  icon: PropTypes.element,
  iconAlign: oneOfProperties(UIMenuItem.iconAlign),
  text: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  id: PropTypes.string.isRequired
}

export default UIMenuItem;
