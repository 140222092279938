import { enableES5 } from "immer";

enableES5();

import authentication from './containers/CRM/authentication/redux/AuthenticationReducer';
import dashboard from './containers/CRM/dashboard/redux/dashboardReducer';
import cpanel from './redux/CpanelReducer';
import explorerReducer from '../src/containers/CRM/dashboard/views/components/Search/features/explorer/explorerSlice'
import tailSlice from './containers/CRM/dashboard/views/components/Search/features/explorer/TailSlice';
import alertSlice from './containers/CRM/dashboard/views/components/Search/features/alerts/alertsSlice';
import { configureStore } from "@reduxjs/toolkit";

console.log("store")
export const store = configureStore({
    reducer: {
    dashboard,
    authentication,
    cpanel,
    explorer: explorerReducer,
    alert: alertSlice,
    tail: tailSlice
        
    },
});

// export default configureStore({
//     reducer:{ dashboard,
//     authentication,
//     cpanel,
//     explorer: explorerReducer,
//     // auth: authSlice,
//     alert: alertSlice,
//     tail: tailSlice}})