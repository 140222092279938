import React, { useEffect, useState, useContext } from "react";
import { Card } from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import "../styles/profile.scss";
import ProfileLayout from "../layouts/ProfileLayout";
import PricingCard from "./pricingCard";
import Dialog from "../../../components/lib/dialogs/SimpleDialog";
import DashboardActions from "../../redux/dashboardActions";
import VERBIAGE from "../../../../../utils/VERBIAGE";
import { UITypography } from "../../../components/lib";
import "../styles/planandpricing.scss";
import AuthenticationActions from "../../../authentication/redux/AuthenticationActions";
import AuthContext from "../../../authentication/views/components/Auth";
import Footer from "../../../authentication/views/common/Footer";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
let auth = getAuth();
let theme = sessionStorage.getItem("theme");

const PlanAndPricing = ({
    dashboard,
    userProfile,
    companyProfile,
    getSubscriptionPlanList,
    getSubscribedPlans,
    history,
    authentication,
    getCredentials,
}) => {
    let { token, emailId, userId } = useContext(AuthContext);
    let header = {
        token,
        userId,
        emailId,
    };
    let navigate = useNavigate();
    const [isLicenseDetailsVisible, setLicenseDetailsVisible] = useState(false);
    const [modal, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    // useEffect(() => {
    //     if (loading) {
    //         setTimeout(() => setLoading(false), 100);
    //     }
    // });

    useEffect(
        () => (getSubscriptionPlanList(header), getSubscribedPlans(header), getCredentials(header)),
        [],
    );

    const checkActivePlan = (planId) => {
        let no_of_subscriptions = 0;
        if (authentication.subscribed) {
            authentication.plans.map((item, index) => {
                if (item == planId) {
                    no_of_subscriptions++;
                }
            });
        }

        if (no_of_subscriptions > 0) return true;
        return false;
    };

    useEffect(() => {
        if (token == null) {
            getLoadingDetails();
        }
        const interval = setInterval(() => {
            getLoadingDetails();
        }, 1800000);
        return () => clearInterval(interval);
    });
    useEffect(() =>
        theme == sessionStorage.getItem("theme")
            ? (
                // loading ? setTimeout(() => setLoading(false), 150) : "",
                setLoading(false),
              console.log(theme, sessionStorage.getItem("theme")),
              (theme = sessionStorage.getItem("theme")))
            : ((theme = sessionStorage.getItem("theme")),
            loading ? setTimeout(() => setLoading(false), 150) : "",
              console.log(theme, sessionStorage.getItem("theme")),
              setLoading(false)),
    );
    const getLoadingDetails = () => {
        auth.onAuthStateChanged(async (user) => {
            if (sessionStorage.getItem("logout") == "false") {
                token = await user.getIdToken();
                emailId = user.email;
                userId = user.uid;
                header = {
                    token,
                    emailId,
                    userId,
                };

                getCredentials(header);
                getSubscriptionPlanList(header);
                getSubscribedPlans(header);
            } else {
                sessionStorage.clear();
                localStorage.clear();
                auth.signOut();
                navigate("/");
                window.location.reload();
            }
        });
        if (sessionStorage.getItem("expired") == "true") {
            sessionStorage.removeItem("expired");
        }
    };

    return (
        // <ProfileLayout
        //     selectedMenuItem={"plansAndPricing"}
        //     leftColumn={
        <div
        style={{
            backgroundColor: theme == "dark" ? "#282828" : "transparent",
            marginTop: "-4em",
            marginLeft: "-2em",
        }}
        >
            {/* {getLoadingDetails()} */}
            {token == null || sessionStorage.getItem("expired") == "true" || token == ""
                ? getLoadingDetails()
                : ""}

            {!loading && (
                <div
                    className={PlanAndPricing.styles.container}
                    style={{ backgroundColor: theme == "dark" ? "#282828" : "transparent" }}
                >
                    <div className={PlanAndPricing.styles.bottom}>
                        <div className={PlanAndPricing.styles.bottomTopTitle}>
                            <div className={PlanAndPricing.styles.bottomTopTitleChildren}>
                                <div data-testid="service">
                                    <UITypography className={PlanAndPricing.styles.bottomTitle}>
                                        {VERBIAGE.TITLE.SERVICE}
                                    </UITypography>
                                </div>
                            </div>
                            <div
                                data-testid="subTitleCaption"
                                className={PlanAndPricing.styles.subTitle}
                                style={{ color: theme == "dark" ? "#DCDCDC" : "#3a3a3a" }}
                            >
                                {VERBIAGE.SUB_TITLE.PRICING}
                            </div>
                        </div>

                        <div className={PlanAndPricing.styles.cardContainer}>
                            {!_.isEmpty(dashboard.plans_available) &&
                                dashboard.plans_available.map((item, index) => (
                                    <Card
                                        className="category-pricing"
                                        style={{
                                            backgroundColor:
                                                theme == "dark" ? "#282828" : "transparent",
                                            color: theme == "dark" ? "#DCDCDC" : "#3a3a3a",
                                        }}
                                    >
                                        <PricingCard
                                            planId={item.planId}
                                            ongoingPlan={
                                                item.planId == "SAAS-1" ? "SaaS" : item.planName
                                            }
                                            // sizew={window.screen.availWidth < 1600? 330:375}
                                            sizew={
                                                window.screen.availWidth < 900
                                                    ? "18.75em"
                                                    : "19.75em"
                                            }
                                            sizeh={200}
                                            isActive={checkActivePlan(item.planId)}
                                            detail={
                                                item.planId == "SAAS-1"
                                                    ? "Episilia Managed, Episilia Cloud"
                                                    : item.planId == "SELF-1"
                                                    ? "Customer Managed, Customer Cloud"
                                                    : "Episilia Managed, Customer Cloud"
                                            }
                                            cost={
                                                item.planId == "SAAS-1"
                                                    ? "0.20"
                                                    : item.planId == "SELF-1"
                                                    ? "0.10"
                                                    : "Custom"
                                            }
                                            detailCost={
                                                item.planId == "SAAS-1"
                                                    ? "Free upto 30GB/per month"
                                                    : item.planId == "SELF-1"
                                                    ? "$99 for upto 2TB/month*"
                                                    : "Contact Sales For More Details"
                                            }
                                        />
                                    </Card>
                                ))}
                        </div>

                        <Dialog
                            isModalVisible={modal}
                            onClose={() => setIsModalVisible(false)}
                            title={
                                modal ? "Mail to support@episilia.com" : "Error! Please try again!!"
                            }
                            variant={modal ? "success" : "error"}
                            actionText={
                                modal ? VERBIAGE.BUTTONS.CONTINUE : VERBIAGE.BUTTONS.TRY_AGAIN
                            }
                            //removed history.goBack()
                            onActionClick={() => {
                                setIsModalVisible(false);
                            }}
                        />
                    </div>

                    <div className="common-features-table">
                        <table className={theme == "dark" ? "dark-table-plan" : ""}>
                            <thead
                                style={{
                                    height: 45,
                                    // backgroundImage:"linear-gradient( to top right, #29C2B6, #2CD97B )",
                                    backgroundImage:
                                        "linear-gradient(180deg, #DD0C4E 20%, #1C1850 100%)",
                                    color: "white",
                                }}
                            >
                                <th style={{ paddingLeft: 45 }}>FEATURES</th>
                                <th className="table-data" style={{ paddingLeft: 10 }}>
                                    SAAS
                                </th>
                                <th className="table-data" style={{ paddingLeft: 10 }}>
                                    SELF HOSTED
                                </th>
                                <th className="table-data" style={{ paddingLeft: 20 }}>
                                    MANAGED SERVICES
                                </th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td
                                        style={{
                                            paddingLeft: window.screen.width < 900 ? 20 : 40,
                                        }}
                                    >
                                        Unlimited logs ingestion
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            paddingLeft: window.screen.width < 900 ? 20 : 40,
                                        }}
                                    >
                                        Unlimited retention of logs
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            fontSize: screen.availWidth > 1900 ? 18 : "",
                                        }}
                                    >
                                        30 days included *
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            paddingLeft: window.screen.width < 900 ? 20 : 40,
                                        }}
                                    >
                                        Unlimited users
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            paddingLeft: window.screen.width < 900 ? 20 : 40,
                                        }}
                                    >
                                        Unlimited search queries
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            paddingLeft: window.screen.width < 900 ? 20 : 40,
                                        }}
                                    >
                                        Unlimited live tail of logs
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            paddingLeft: window.screen.width < 900 ? 20 : 40,
                                        }}
                                    >
                                        Unlimited log alerts{" "}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                </tr>

                                <tr>
                                    <td
                                        style={{
                                            paddingLeft: window.screen.width < 900 ? 20 : 40,
                                        }}
                                    >
                                        Clusters deployed and operated by Episilia team
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CloseOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            paddingLeft: window.screen.width < 900 ? 20 : 40,
                                        }}
                                    >
                                        Independent clusters for each client{" "}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            paddingLeft: window.screen.width < 900 ? 20 : 40,
                                        }}
                                    >
                                        Option to store data in client's S3 bucket only
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                </tr>
                                <tr
                                    style={{
                                        height: 45,
                                        backgroundImage:
                                            "linear-gradient(180deg, #DD0C4E 20%, #1C1850 100%)",
                                        // backgroundColor: "#016775",
                                        color: "white",
                                    }}
                                >
                                    <td
                                        style={{
                                            color: "white",
                                            paddingLeft: 40,
                                            fontWeight: 700,
                                        }}
                                    >
                                        SUPPORT
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            paddingLeft: window.screen.width < 900 ? 20 : 40,
                                        }}
                                    >
                                        Slack and email support
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            paddingLeft: window.screen.width < 900 ? 20 : 40,
                                        }}
                                    >
                                        Priority support
                                    </td>
                                    <td style={{ textAlign: "center" }}></td>
                                    <td style={{ textAlign: "center" }}></td>
                                    <td style={{ textAlign: "center" }}>
                                        <CheckOutlined />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <li style={{ color: theme == "dark" ? "#DCDCDC" : "#000" }}>
                            30 Days retention is provided by default in SAAS plan. If you need to
                            extend, you will be charged $0.02/GB per month
                        </li>
                        <li style={{ color: theme == "dark" ? "#DCDCDC" : "#000" }}>
                            In Self-Hosted plan, first 2TB/month will be charged $99. Post that it
                            will be on pro-rata basis i.e. $0.10/GB Ingested
                        </li>
                    </div>
                </div>
            )}
            {window.screen.width > 900 && !loading && (
                <div
                    className="footer-plan-pricing"
                    style={{ color: theme == "dark" ? "#DCDCDC" : "000" }}
                >
                    <Footer />
                </div>
            )}
        </div>
        //     }
        // />
    );
};

PlanAndPricing.propTypes = {};

const mapStateToProps = (state) => ({
    authentication: state.authentication,
    dashboard: state.dashboard,
    userProfile: state.authentication.profileDetails,
    companyProfile: state.authentication.companyDetails,
});

const mapDispatchToProps = (dispatch) => ({
    // getLicenseKey: (credentials) => {
    //   dispatch(DashboardActions.getLicenseKey());
    // },
    getSubscriptionPlanList: (header) => {
        dispatch(DashboardActions.getSubscriptionPlanList(header));
    },
    getSubscribedPlans: (header) => {
        dispatch(DashboardActions.getSubscribedPlans(header));
    },
    getCredentials: (header) => {
        dispatch(AuthenticationActions.getCredentials(header));
    },
});

PlanAndPricing.styles = {
    bottomTopTitleChildren: "bottomTopTitle-children",
    bottomTopTitle: "bottomTopTitle",
    rightColumn: "rightColumn",
    header: "headerPricing",
    subHeader: "subHeaderPricing",
    lineBreak: "line-break",
    container: "pricing-dsc-container",
    top: "pricing-dsc-container__top-content",
    bottom: "pricing-dsc-container__bottom-content",
    topTitle: "pricing-dsc-container__top-content__title",
    topSubTitle: "pricing-dsc-container__top-content__sub-title",
    bottomTitle: "pricing-dsc-container__bottom-content__title",
    bottomTitleOne: "pricing-dsc-container__bottom-content__title__one",
    subTitle: "pricing-dsc-container__bottom-content__subtitle",
    cardContainer: "pricing-dsc-container__bottom-content__card-container",
    buttonContainer: "toggle-btn-container",
    toggleButton: "toggle-btn-container__btn",
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanAndPricing);
