const CpanelActionTypes = {
  CLIENT_INFO: "cpanel/CLIENT_INFO",
  LIVE_CLUSTER: "cpanel/LIVE_CLUSTER",
  METRICS: "cpanel/METRICS",
  EVENTS: "cpanel/EVENTS",
  CONFIG: "cpanel/CONFIG",
  LIVE_SERVERS: "cpanel/LIVE_SERVERS",
  HISTORY_SERVERS: "cpanel/HISTORY_SERVERS",
  SERVER_DETAILS: "cpanel/SERVER_DETAILS",

  LIVE_CLUSTER_DETAILS: "pulse/LIVE_CLUSTER_DETAILS",
  SERVER_DETAILS_CPANEL: "pulse/SERVER_DETAILS",
  INDEXER_SERVER_DETAILS: "pulse/INDEXER_SERVER_DETAILS",
  KAFKA_SERVER_DETAILS: "pulse/KAFKA_SERVER_DETAILS",
  SEARCH_SERVER_DETAILS: "pulse/SEARCH_SERVER_DETAILS",
  SEARCH_GATEWAY_DETAILS: "pulse/SEARCH_GATEWAY_DETAILS",
  OPTIMIZER_DETAILS: "pulse/OPTIMIZER_DETAILS",
};

export default CpanelActionTypes;
