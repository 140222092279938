import React from "react";
import { UIBaseCard } from "../../../../../components/lib";
import { Card } from "antd";
import background from "../../../../../../../assets/BG.jpg"


export default function NoLogs() {
    return (
        <>
            <div className="no-logs-card"  >
                <Card title={" "}>
                    <p>Start your search by filtering the labels or using search query </p>
                </Card>
            </div>
        </>
    );
}
