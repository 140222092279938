import React, { useState, useEffect, useContext } from "react";
// import { withRouter } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserOutlined, LockOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Dropdown, Form, Select, Menu } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import _ from "lodash";

import PropTypes from "prop-types";
import { connect } from "react-redux";
// import UIButton from '../../../../components/lib/button/Button';
import Typography from "../../../components/lib/typography/typography";
import Input from "../../../components/lib/input/input";
import Wrapper from "./Wrapper";
import { DownOutlined } from "@ant-design/icons";

import VERBIAGE from "../../../../../utils/VERBIAGE";
import AuthenticationActions from "../../redux/AuthenticationActions";

import Logo from "../common/Logo";
import Footer from "../common/Footer";
import Dialog from "../../../components/lib/dialogs/SimpleDialog";

import "../styles/registration.scss";
import DashboardActions from "../../../dashboard/redux/dashboardActions";
import AuthContext from "./Auth";
const Registration = (props) => {
    let navigate=useNavigate()
    const { token, emailId, userId } = useContext(AuthContext);
    const {
        authentication,
        registerUser,
        resetFetched,
        getCredentials,
        registerUserWithInvite,
        history
        //invitedUser
    } = props;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [render, setRender] = useState(false);
    let header = {
        token,
        userId,
        emailId,
    };
    
    const closeModal = () => {
        resetFetched();
        navigate(VERBIAGE.NAVIGATION.PATHS.CRMDASHBOARD);
        setEditMode(false);
    };

    const onFinish = async (values) => {
        let newval;
console.log(authentication.userRole)
        if (authentication.userRole == ""||authentication.userRole==undefined) {
            if (values.companyName != "") {
                newval = { ...values, userRole: "admin" };
                await registerUser({ ...newval }, props);
            } else {
                newval = { ...values, userRole: "Devops" };
                await registerUser({ ...newval }, props);
            }
        } else {
            newval = {
                ...values,
                userRole: authentication.userRole,
                inviteToken:
                    localStorage.getItem("inviteToken") || authentication.inviteDetails.inviteToken,
                tokenType: localStorage.getItem("tokenType")
                    ? localStorage.getItem("tokenType")
                    : authentication.inviteverified
                    ? "user"
                    : "company",
            };

            await registerUserWithInvite({ ...newval }, props);
        }
    };

    useEffect(() => getCredentials(header), []);

    useEffect(() => {
        // const interval = setInterval(() => {
            if(authentication.authenticated)
           { 
               if(authentication.registered)
               {
                navigate(VERBIAGE.NAVIGATION.PATHS.CRMDASHBOARD)
               }
           }
        // }, 500);

        // return () => clearInterval(interval);
    }, []);

    // useEffect(()=>console.log(authentication.authenticated),[])


    useEffect(
        () =>
            localStorage.getItem("inviteToken")  ? validation() :'' ,
        [localStorage.getItem("inviteToken")],
    );
    
    useEffect(() =>{ (authentication.authenticated
        ? authentication.registered
            ? navigate(VERBIAGE.NAVIGATION.PATHS.CRMDASHBOARD)
            : ""
        : '')},

);
    const closeDialog = () => {
        setIsModalVisible(false);
    };
    const validation = () => {
    //  if(localStorage.getItem("inviteToken"))
        {if (
            localStorage.getItem("inviteToken") != null ||
            localStorage.getItem("inviteToken") != "" 
        ) {
            getCredentials(header)
            if (authentication.orgUrl == undefined || authentication.orgName == undefined ) {
                console.log("***********")
                setIsModalVisible(true);
            }
        }}
    };
    const modal = () => {
        return (
            <Dialog
                isModalVisible={isModalVisible}
                onClose={closeDialog}
                title="Error"
                content="Invalid Token"
                variant="error"
                actionText={VERBIAGE.BUTTONS.TRY_AGAIN}
                onActionClick={() => navigate(VERBIAGE.NAVIGATION.PATHS.LOGIN)}
            />
        );
    };
    useEffect(() => {
        !render
            ? setTimeout(() => {
                  setRender(true);
              }, 500)
            : "";
    });

    return (
        <Wrapper>
            {render ? (
                <div className={Registration.styles.container}>
                    {authentication.inviteverified && (
                        <Dialog
                            isModalVisible={header.emailId != authentication.inviteDetails.emailId}
                            onClose={closeDialog}
                            title="Error"
                            content="Invalid Email id"
                            variant="error"
                            actionText={VERBIAGE.BUTTONS.TRY_AGAIN}
                            onActionClick={() =>
                                
                                (authentication.companyinviteverified?navigate(`/joincompany/${localStorage.getItem("inviteToken")}`):
                                navigate(`/join/${localStorage.getItem("inviteToken")}`),localStorage.setItem("check", "no"))
                            }
                        />
                    )}
                    {console.log(sessionStorage.getItem("company"),sessionStorage.getItem("company")=="registered")}
                   { sessionStorage.getItem("company")=="registered" && <Dialog
                            isModalVisible={sessionStorage.getItem("company")=="registered"}
                            onClose={()=>sessionStorage.removeItem("company")}
                            title="Error"
                            content="This company is already registered, please contact your admin to add to users list"
                            variant="error"
                            actionText={VERBIAGE.BUTTONS.TRY_AGAIN}
                            onActionClick={() => (navigate(VERBIAGE.NAVIGATION.PATHS.LOGIN),sessionStorage.removeItem("company"))
                            }
                        />}
                    <div className={Registration.styles.leftSection}>
                        <div className="logo-registration">
                            <Logo />
                        </div>

                        {modal()}
                        <div className={Registration.styles.header} data-testid="header">
                            <Typography level="h5" className={Registration.styles.title}>
                                {VERBIAGE.TITLE.CONTACT_DETAILS}
                            </Typography>
                        </div>

                        <div className={Registration.styles.Inputform}>
                            <Form
                                layout="vertical"
                                className={Registration.styles.form}
                                onFinish={onFinish}
                                initialValues={{
                                    email: header.emailId,
                                    userName: "",
                                    companyUrl: authentication.orgUrl,
                                    companyName: authentication.orgName,
                                    contactNumber: "",
                                    userRole: "",
                                }}
                            >
                                <Form.Item
                                    className={Registration.styles.label}
                                    wrapperCol={{ span: 24 }}
                                    name="userName"
                                    required={false}
                                    label={VERBIAGE.LABELS.NAME}
                                    rules={[{ required: true, message: "Name is required" }]}
                                >
                                    <Input
                                        placeholder={VERBIAGE.PLACEHOLDERS.NAME}
                                        className={Registration.styles.inputText}
                                        data-testid="name"
                                    />
                                </Form.Item>

                                <Form.Item
                                    className={Registration.styles.label}
                                    wrapperCol={{ span: 24 }}
                                    required={false}
                                    name="email"
                                    label={VERBIAGE.LABELS.EMAIL}
                                    rules={[{ required: true, message: "Email is required" }]}
                                >
                                    <Input
                                        disabled={true}
                                        suffix={<LockOutlined />}
                                        placeholder={VERBIAGE.PLACEHOLDERS.EMAIL}
                                        className={Registration.styles.inputText}
                                        data-testid="email"
                                    />
                                </Form.Item>

                                <Form.Item
                                    className={Registration.styles.label}
                                    wrapperCol={{ span: 24 }}
                                    name="contactNumber"
                                    label={VERBIAGE.LABELS.MOBILE}
                                    rules={[{ len: 10, message: "please enter valid mobile no." }]}
                                >
                                    <Input
                                        placeholder={VERBIAGE.PLACEHOLDERS.MOBILE}
                                        className={Registration.styles.inputText}
                                        data-testid="phone"
                                    />
                                </Form.Item>

                                <Form.Item
                                    className={Registration.styles.label}
                                    wrapperCol={{ span: 24 }}
                                    name="companyName"
                                    label={VERBIAGE.LABELS.COMPANY_NAME}
                                    rules={[{ required: true, message: "Company Name is required" }]}
                                >
                                    <Input
                                        disabled={authentication.orgName? authentication.orgName!= ""?true:false:false}
                                        placeholder={VERBIAGE.PLACEHOLDERS.COMPANY_NAME}
                                        className={Registration.styles.inputText}
                                        data-testid="companyName"
                                    />
                                </Form.Item>

                                <Form.Item
                                    className={Registration.styles.label}
                                    wrapperCol={{ span: 24 }}
                                    name="companyUrl"
                                    label={VERBIAGE.LABELS.COMPANY_URL}
                                    rules={[{ required: true, message: "Company URL is required" }]}
                                >
                                    <Input
                                        disabled={authentication.orgUrl? authentication.orgUrl!= ""?true:false:false}
                                        placeholder={VERBIAGE.PLACEHOLDERS.COMPANY_URL}
                                        className={Registration.styles.inputText}
                                        data-testid="companyUrl"
                                    />
                                </Form.Item>

                                <Form.Item wrapperCol={{ span: 24 }}>
                                    <Button
                                        className={Registration.styles.button}
                                        htmlType="submit"
                                        data-testid="save"
                                    >
                                        {VERBIAGE.BUTTONS.SAVE}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Footer />
                        </div>
                    </div>
                </div>
            ) : (
                <LoadingOutlined size={50} style={{ marginLeft: "50%", marginTop: "50%" }} />
            )}
        </Wrapper>
    );
};

Registration.propTypes = {
    validateCredentials: PropTypes.func.isRequired,
    getCredentials: PropTypes.func.isRequired,
};

Registration.styles = {
    container: "registration-page-container",
    leftSection: "registration-page-container__left-section",
    header: "registration-page-container__left-section__header",
    title: "registration-page-container__left-section__header__title",
    subTitle: "registration-page-container__left-section__header__sub-title",
    Inputform: "registration-page-container__left-section__form",
    form: "registration-page-container__left-section__form__form-container",
    label: "registration-page-container__left-section__form__label",
    inputText: "registration-page-container__left-section__form__text-field",
    button: "registration-page-container__left-section__form__btn",
    noAcc: "registration-page-container__left-section__form__na-acc",
    noAccText: "registration-page-container__left-section__form__na-acc__text",
    noAccLink: "registration-page-container__left-section__form__na-acc__link",
};

const mapStateToProps = (state) => ({
    authentication: state.authentication,
    //  details:state.authentication.authDetails,
    dashboard: state.dashboard,
});

const mapDispatchToProps = (dispatch) => ({
    validateCredentials: () => {
        dispatch(AuthenticationActions.validateCredentials());
    },
    getCredentials: (header) => {
        dispatch(AuthenticationActions.getCredentials(header));
    },
    registerUser: (payload, props) => {
        dispatch(AuthenticationActions.registerUser(payload, props));
    },
    resetFetched: (payload) => {
        dispatch(AuthenticationActions.resetFetched());
    },
    registerUserWithInvite: (payload, props) => {
        dispatch(AuthenticationActions.registerUserWithInvite(payload, props));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)((Registration));
