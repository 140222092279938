import React, { useEffect, useState, createContext } from "react";
import app from "../../../config";
import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";

const AuthContext = createContext({ signOut: () => {} });
export default AuthContext;

export const AuthProvider = ({ children }) => {
    let auth = getAuth();
    // let token='',emailId='',userId=''
    const [emailId, setEmailId] = useState("");
    const [token, setToken] = useState("");
    const [userId, setUserId] = useState("");
   

    useEffect(() => {
        sessionStorage.getItem("logout") != "false"
            ? (setToken(null),
              setEmailId(null),
              setUserId(null))
             
            : "";
    }, []);


    useEffect(() => {
        let value = firebase.apps.length;
        if (!value) {
            firebase.initializeApp(app);
        } else {
            firebase.app();
        }
        auth.onAuthStateChanged(async(user) => {
          let tokentemp = await user.accessToken
            setToken(user.accessToken);
            setEmailId(user.email);
            setUserId(user.uid);
            sessionStorage.setItem("email", user.emailVerified);
            sessionStorage.setItem("anonymous", user.isAnonymous);
            sessionStorage.setItem("Name", user.displayName);
            sessionStorage.setItem("logout", "false");
            sessionStorage.setItem("photoUrl",user.photoURL)
        });
        
    }, []);


    const mapping=(t,e,u)=>{
        setToken(t);
        setEmailId(e);
        setUserId(u);
    }

    useEffect(()=>{
        const interval = setInterval(() => {
             if (sessionStorage.getItem("logout") == "false") {
                auth.onAuthStateChanged((user) => {

                    mapping(user.accessToken,user.email,user.uid)
                });
             }
        }, 4000);
        return () => clearInterval(interval);
    },[])

    const signOut = () => {
        if (sessionStorage.getItem("logout") != "false") {
            setToken(null);
            setEmailId(null);
            setUserId(null);
            sessionStorage.clear()
        }
    };

    return (
        <AuthContext.Provider
            value={{
                token,
                emailId,
                userId,
                signOut,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
