import React from "react";
// import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import ProfileMenuContainer from "../components/profileMenu/ProfileMenuContainer";

import "./profileLayout.scss";
import Footer from "./Footer";

const ProfileLayout = ({ leftColumn, rightColumn, selectedMenuItem }) => {
  return <div  className='profile-container-row' style={{backgroundColor:sessionStorage.getItem("theme")=="dark"?'#282828':'transparent', marginTop:'-4em'}}>
    {/* <div className='profile-menu-column'>
      <ProfileMenuContainer selectedMenuItem={selectedMenuItem}/>
    </div> */}
    <div className='profile-content-row'>
      <div className="profile-layout-right-content">
        <div className="profile-layout-right-content-column">
          {leftColumn}
        </div>
      </div>
    </div>
    {/* <div className="profile-layout-mobile-footer-row">
      <Footer />
    </div> */}
  </div>
};

ProfileLayout.propTypes = {
  validateCredentials: PropTypes.func.isRequired,
  postCredentials: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  authentication: state.authentication
});

const mapDispatchToProps = dispatch => ({
  validateCredentials: () => {
    dispatch(AuthenticationActions.validateCredentials());
  },
  postCredentials: credentials => {
    dispatch(AuthenticationActions.postCredentials(credentials));
  }
});

ProfileLayout.styles = {
  
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((ProfileLayout));
