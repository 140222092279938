import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    ButtonGroup,
    Dialog,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { AddSharp, DeleteSharp } from "@mui/icons-material";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import React, { useEffect, useState } from "react";
// import  {AlertConfigData, Auth} from "../../lib/url";
import { useDispatch } from "react-redux";
import { updateConfig } from "./alertsSlice";
// import { truncate } from "lodash";
// import { alertConfigDataSelector, initialiseConfig } from "./alertsSlice";
// import { makeStyles, createStyles } from "@material-ui/styles";
import { withStyles } from "@material-ui/core/styles";
const theme=sessionStorage.getItem('theme')

const textFieldColor = '#DCDCDC'
const textFieldSX = {
    input: {
        "-webkit-text-fill-color": `${textFieldColor} !important`,
        color: `${textFieldColor} !important`,

    },
    "& label": {
       color:theme=="dark"?'#DCDCDC':'#000',
      },
};
const CssTextField = withStyles({
    root: {
      
        "& label.Mui-disabled": {
            color: "#DCDCDC",
            
        },
    },
})(TextField);




function EditKey({ disabled, alertKey, onChange }) {
    return (
        <>
            <CssTextField
                size={"small"}
                fullWidth
                sx={textFieldSX}
                // style={{}}
                disabled={disabled}
                label={"key"}
              
                value={alertKey}
                onChange={(event) => {
                    onChange(event.target.value);
                }}
            ></CssTextField>
        </>
    );
}

function AlertKeys({ disabled, keys, onKeysChanged, onKeyAdded, onKeyRemoved }) {
    const [key, setCurrentKey] = useState("");
    return (
        <>
            <Box sx={{ border: 1 }} p={1} m={1}>
                {keys.map((value, index) => {
                    return (
                        <Box key={index} p={1}>
                            <Grid container spacing={2}>
                                <Grid item xs={11} >
                                    <EditKey
                                        disabled={disabled}
                                        alertKey={value}
                                        onChange={(change) => {
                                            onKeysChanged(index, change);
                                        }}
                                    ></EditKey>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        size={"small"}
                                        onClick={() => {
                                            onKeyRemoved(index, value);
                                        }}
                                    >
                                        <DeleteSharpIcon  style={{color:theme=="dark"?'#DCDCDC':'#000'}}/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    );
                })}

                <>
                    <Box m={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={11}>
                                <CssTextField
                                    size={"small"}
                                    label={key==''?"Label Example: __app_id__":"Key"}
                                    placeholder={"Label"}
                                    fullWidth
                                    style={{height:'40px',width:'93%',border:theme=="dark"?'1px solid #DCDCDC':''}}
                                    value={key}
                                    sx={textFieldSX}
                                    onChange={(event) => {
                                        setCurrentKey(event.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button
                                style={{fontSize:11,marginLeft:'-3.5em',border:'1px solid #DD0C4E',backgroundColor:theme=="dark"?'#DCDCDC':'#FFF'}}
                                    size={"small"}
                                    //  startIcon={<AddSharpIcon style={{fontSize:11}} />}
                                    onClick={() => {
                                        onKeyAdded(key);
                                        setCurrentKey("");
                                    }}
                                >
                                    Save key
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            </Box>
        </>
    );
}

function AlertKvPairs({
    disabled,
    kvKeys,
    kvValues,
    onKvPairChange,
    onKvPairAdded,
    onKvPairRemoved,
}) {
    const [key, setCurrentKey] = useState("");
    const [value, setCurrentValue] = useState("");
    return (
        <>
            <Box sx={{ border: 1 }} p={1} m={1}>
                <List>
                    {kvKeys.map((key, index) => {
                        let value = kvValues[index];
                        return (
                            <ListItem
                                key={index}
                                secondaryAction={
                                    <IconButton
                                        onClick={() => {
                                            onKvPairRemoved(key, value, index);
                                        }}
                                    >
                                        <DeleteSharpIcon style={{color:theme=="dark"?'#DCDCDC':'#000'}} />
                                    </IconButton>
                                }
                            >
                                <CssTextField
                                    fullWidth
                                    size={"small"}
                                    disabled={disabled}
                                    margin={"normal"}
                                   
                                    sx={textFieldSX} 
                                    value={key}
                                    onChange={(event) => {
                                        onKvPairChange(index, event.target.value, value);
                                    }}
                                ></CssTextField>
                                <CssTextField
                                    fullWidth
                                    size={"small"}
                                    disabled={disabled}
                                    sx={textFieldSX} 
                                    margin={"normal"}
                                    value={value}
                                    onChange={(event) => {
                                        onKvPairChange(index, key, event.target.value);
                                    }}
                                ></CssTextField>
                            </ListItem>
                        );
                    })}
                </List>
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={5.5}>
                            <CssTextField
                                label={key==''?"Label":"Key"}
                                size={"small"}
                                style={{width:'90%', border:theme=="dark"?'1px solid #DCDCDC':''}}
                                fullWidth
                                sx={textFieldSX} 
                                value={key}
                                onChange={(event) => {
                                    setCurrentKey(event.target.value);
                                    // onKvPairAdded(event.target.value, value);
                                }}
                            ></CssTextField>
                        </Grid>
                        <Grid item xs={5.5}>
                            <CssTextField
                                label={"Value"}
                                size={"small"}
                                style={{width:'90%',marginLeft:'-1.5em', border:theme=="dark"?'1px solid #DCDCDC':''}}
                                fullWidth
                                value={value}
                                sx={textFieldSX} 
                                onChange={(event) => {
                                    setCurrentValue(event.target.value);
                                    // onKvPairAdded(key,event.target.value);
                                }}
                            ></CssTextField>
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                            style={{border:'1px solid #DD0C4E',fontSize:11,marginLeft:'-4em', backgroundColor:theme=="dark"?'#DCDCDC':'#FFF'}}
                                onClick={() => {
                                    onKvPairAdded(key, value);
                                    setCurrentKey("");
                                    setCurrentValue("");
                                    
                                }}
                            >
                                Save KV
                            </Button>
                        </Grid>
                    </Grid>
                </>
            </Box>
        </>
    );
}

function Rule({
    isWindowForCreate,
    userProfile,
    alertRuleId,
    alertRule,
    onSave,
    onClose,
    onRemove,
    index,
    clusters = [],
}) {
    const [disabled, isDisabled] = useState(!isWindowForCreate);
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [query, setQuery] = useState("");
    const [keys, setKeys] = useState([]);
    const [kvKeys, setkvKeys] = useState([]);
    const [kvValues, setkvValues] = useState([]);
    const [alertOnCount, setAlertOnCount] = useState(5);
    const [alertDuration, setAlertDuration] = useState("");
    const [summary, setSummary] = useState("");
    const [description, setDescription] = useState("");
    const [clusterId, setClusterId] = useState(clusters.length === 0 ? -1 : clusters[0].id);
   
    useEffect(() => {
        if (alertRule !== undefined) {
            setId(alertRule.id);
            setName(alertRule.name);
            setQuery(alertRule.query);
            setKeys(alertRule.keys);
            setkvKeys(alertRule.kvKeys);
            setkvValues(alertRule.kvValues);
            setAlertDuration(alertRule.alertDuration);
            setAlertOnCount(alertRule.alertOnCount);
            setSummary(alertRule.summary);
            setDescription(alertRule.description);
        }
        if (alertRuleId !== undefined) {
            setId(alertRuleId);
        }
        if (isWindowForCreate && clusters.length > 0) {
            setClusterId(clusters[0]?.id);
        }
    }, [alertRule]);

    function setValue(event) {
        switch (event.target.name) {
            case "query":
                setQuery(event.target.value);
                break;
            case "summary":
                setSummary(event.target.value);
                break;
            case "description":
                setDescription(event.target.value);
                break;
            case "alertOnCount":
                setAlertOnCount(event.target.value);
                break;
            case "name":
                setName(event.target.value);
                break;
            case "alertDuration":
                if (
                     
                    event.target.value.startsWith("1") ||
                    event.target.value.startsWith("2") ||
                    event.target.value.startsWith("3") ||
                    event.target.value.startsWith("4") ||
                    event.target.value.startsWith("5") ||
                    event.target.value.startsWith("6") ||
                    event.target.value.startsWith("7") ||
                    event.target.value.startsWith("8") ||
                    event.target.value.startsWith("9") 
                    &&
                        (event.target.value.endsWith("s") ||
                            event.target.value.endsWith("m") ||
                            event.target.value.endsWith("h"))
                )
                    setAlertDuration(event.target.value);
                else if(event.target.value=="")
                {
                    setAlertDuration("");
                }
                break;
            default:
                return;
        }
    }

    return (
        <form
     className="form-rules"
            onChange={(event) => setValue(event)}
            onSubmit={(event) => {
                event.preventDefault();
            }}
        >
            
            {isWindowForCreate && (
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" sx={{color:theme=="dark"?'#FFF':'#000'}}>Cluster</InputLabel>
                    <Select
                        fullWidth
                        sx={{color:theme=="dark"?'#FFF':'#000'}}
                        size={"small"}
                        name={"clusterId"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={clusterId}
                        label="Cluster"
                        onChange={(event) => {
                            setClusterId(event.target.value);
                        }}
                    >
                        {clusters.length === 0 ? (
                            <MenuItem value={-1} disabled>
                                No Cluster
                            </MenuItem>
                        ) : (
                            clusters.map((cluster, index) => {
                                return (
                                    <MenuItem key={index} value={cluster.id}>
                                        {cluster.cluster_name}
                                    </MenuItem>
                                );
                            })
                        )}
                    </Select>
                </FormControl>
            )}
            <CssTextField
                margin="dense"
                size={"small"}
                required={true}
                // sx={{color:'yellow',zIndex:26,position:'relative'}}
                fullWidth
                disabled={disabled}
                value={name}
                label={"Name"}
                name={"name"}
                sx={textFieldSX} 
                
            //    style={{zIndex:20,position:'relative'}}
            ></CssTextField>
            <CssTextField
            sx={textFieldSX} 
                margin="dense"
                required={true}
                size={"small"}
                fullWidth
                disabled={disabled}
                value={query}
                label={"query"}
                name={"query"}
            ></CssTextField>
            <CssTextField
                margin="dense"
                size={"small"}
                required={true}
                fullWidth
                disabled={disabled}
                sx={textFieldSX} 
                value={summary}
                label={"summary"}
                name={"summary"}
            ></CssTextField>
            <CssTextField
                margin="dense"
                size={"small"}
                required={true}
                fullWidth
                disabled={disabled}
                sx={textFieldSX} 
                value={description}
                label={"description"}
                name={"description"}
            ></CssTextField>
            <CssTextField
                margin="dense"
                size={"small"}
                type={"number"}
                required={true}
                sx={textFieldSX} 
                fullWidth
                disabled={disabled}
                value={alertOnCount}
                label={"alertOnCount"}
                name={"alertOnCount"}
            ></CssTextField>
            <CssTextField
                margin="dense"
                size={"small"}
                required={true}
                fullWidth
                disabled={disabled}
                value={alertDuration}
                sx={textFieldSX} 
                label={"alertDuration"}
                name={"alertDuration"}
            ></CssTextField>

            <Accordion  style={{backgroundColor:theme=="dark"?"#555353": "#FFF",  zIndex:20}}>
                <AccordionSummary style={{color:theme=="dark"?'#FFF':'#000'}} expandIcon={<ExpandMoreIcon style={{color:theme=="dark"?'#FFF':'#000'}} />} >Keys</AccordionSummary>
                <AccordionDetails >
                    <AlertKeys
                        keys={keys}
                        onKeysChanged={(index, key) => {
                            let updatedKeyList = [...keys];
                            updatedKeyList[index] = key;
                            setKeys(updatedKeyList);
                            console.log(updatedKeyList);
                        }}
                        onKeyAdded={(key) => {
                            let updatedKeyList = [...keys];
                            updatedKeyList.push(key);
                            setKeys(updatedKeyList);
                            console.log(updatedKeyList);
                        }}
                        onKeyRemoved={(index, key) => {
                            let updatedKeyList = keys.filter((value) => {
                                return value !== key;
                            });
                            console.log(updatedKeyList);
                            setKeys(updatedKeyList);
                        }}
                        disabled={disabled}
                    ></AlertKeys>
                </AccordionDetails>
            </Accordion>

            <Accordion  style={{backgroundColor:theme=="dark"?"#555353": "#FFF",  zIndex:20}}>
                <AccordionSummary style={{color:theme=="dark"?'#FFF':'#000'}} expandIcon={<ExpandMoreIcon style={{color:theme=="dark"?'#FFF':'#000'}} />}>KV Pairs</AccordionSummary>
                <AccordionDetails>
                    <AlertKvPairs
                        kvValues={kvValues}
                        kvKeys={kvKeys}
                        disabled={disabled}
                        onKvPairChange={(index, key, value) => {
                            let updatedKvKeys = [...kvKeys];
                            let updatedKvValues = [...kvValues];
                            updatedKvKeys[index] = key;
                            updatedKvValues[index] = value;
                            setkvValues(updatedKvValues);
                            setkvKeys(updatedKvKeys);
                        }}
                        onKvPairAdded={(key, value) => {
                            let updatedKvKeys = [...kvKeys];
                            let updatedKvValues = [...kvValues];
                            updatedKvKeys.push(key);
                            updatedKvValues.push(value);
                            setkvValues(updatedKvValues);
                            setkvKeys(updatedKvKeys);
                        }}
                        onKvPairRemoved={(key, value, index) => {
                            let updatedKvKeys = kvKeys.filter((kvKey) => {
                                return kvKey !== key;
                            });
                            let updatedKvValues = kvValues.filter((kvValue) => {
                                return kvValue !== value;
                            });
                            setkvKeys(updatedKvKeys);
                            setkvValues(updatedKvValues);
                        }}
                    ></AlertKvPairs>
                </AccordionDetails>
            </Accordion>

            <Box p={1} display={"flex"} justifyContent={"flex-end"}>
                {/* <ButtonGroup> */}
                {!disabled && (
                   <><Button
                        sx={{ color: "#000", border: "#DD0C4E 1px solid", width: "100px" }}
                        onClick={() => {
                            let newConfig = {
                                name: name,
                                query: query,
                                keys: keys,
                                kvKeys: kvKeys,
                                kvValues: kvValues,
                                alertOnCount: alertOnCount,
                                alertDuration: alertDuration,
                                summary: summary,
                                description: description,
                            };
                            if (isWindowForCreate) newConfig.clusterId = clusterId;

                            onSave(newConfig);
                            sessionStorage.getItem("alert")!="false"?
                            (isDisabled(true),sessionStorage.removeItem("alert")):sessionStorage.removeItem("alert")
                        }}
                    >
                        Save
                    </Button>
                  { isWindowForCreate && <Button  sx={{ color: "#000", border: "#DD0C4E 1px solid", width: "100px" }} onClick={()=>onClose()}>Cancel</Button>}</> 
                )}
                {disabled && index!=0 &&  (
                    <Button
                        sx={{
                            color: "#000",
                            border: "#DD0C4E 1px solid",
                            width: "100px",
                            marginRight: "1.5rem",
                            backgroundColor:theme=="dark"?'#DCDCDC':'#FFF'
                        }}
                        onClick={() => {
                            isDisabled(false);
                        }}
                    >
                        Edit
                    </Button>
                )}
                
                {!isWindowForCreate && index!=0 && (
                    <Button
                        sx={{ color: "#000", border: "#DD0C4E 1px solid", width: "100px", backgroundColor:theme=="dark"?'#DCDCDC':'#FFF' }}
                        color={"warning"}
                        startIcon={<DeleteSharpIcon />}
                        onClick={() => {
                            const rule = {
                                id: id,
                                name: name,
                                query: query,
                                keys: keys,
                                kvKeys: kvKeys,
                                kvValues: kvValues,
                                alertOnCount: alertOnCount,
                                alertDuration: alertDuration,
                                summary: summary,
                                description: description,
                            };
                            onRemove(rule);
                        }}
                    >
                        Delete
                    </Button>
                )}
                {/* </ButtonGroup> */}
            </Box>
        </form>
    );
}

export function CreateAlertDialog({ userProfile, clusters = [], ruleId, open, onClose, onSave }) {
    return (
        <div >
            {/* .css-1t1j96h-MuiPaper-root-MuiDialog-paper */}
            <Dialog 
            style={{maxWidth:'900px',marginLeft:'15em',width:'950px'}}
                open={open}
                onClose={() => {
                    onClose();
                }}
            >
                <DialogTitle sx={{backgroundColor:theme=="dark"?'#555353':'#FFF', color:theme=="dark"?'#FFF':'#000'}}>Add new Alert Rule </DialogTitle>
                <Box p={1} sx={{backgroundColor:theme=="dark"?'#555353':'#FFF', color:theme=="dark"?'#FFF':'#000'}}  >
                    <Rule
                        clusters={clusters}
                        userProfile={userProfile}
                        onSave={(rule) => {
                            onSave(rule);
                            // onClose();
                        }}
                        onClose={onClose}
                        alertRuleId={ruleId}
                        isWindowForCreate={true}
                    ></Rule>
                </Box>
            </Dialog>
        </div>
    );
}

export default function AlertsDashboard({ alertConfig, auth }) {
    const [expanded,setExpanded] = useState(0)
    const dispatch = useDispatch();
    // console.log(alertConfig);
    // auth={{
    //     uid: userId,
    //     name: name,
    //     email: emailId,
    //     "auth-token": token,
    // }}

    // useEffect( () => {
    //     dispatch(initialiseConfig({ "auth-token":auth['auth-token'],email: auth.email, uid:auth.uid  }));
    // });
    return (
        <Box>
            {alertConfig !== undefined && alertConfig.rules !== undefined && (
                <>
                    {alertConfig.rules.map((value, index) => {
                        return (
                            <Accordion  style={{backgroundColor:theme=="dark"?"#555353": "#FFF", color:theme=="dark"?'#FFF':'#000',zIndex:20}} expanded={index==expanded} key={index}>
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon style={{color:theme=="dark"?'#FFF':'#000'}} onClick={()=>(expanded==index?setExpanded(alertConfig.rules.length):setExpanded(index))} />
                                    }
                                >
                                    {value.name}
                                </AccordionSummary>
                                <AccordionDetails style={{color:'#FFF',zIndex:20}} >
                                    {console.log(value.name=="sample")}
                                    <Rule
                                        alertRule={value}
                                        index={value.name=="sample"?0:1}
                                        onSave={(rule) => {
                                            let updatedConfig = { ...alertConfig };
                                            let rules = [...updatedConfig.rules];
                                            console.log(rules)
                                            rules[index] = rule;
                                            updatedConfig.rules = rules;
                                            dispatch(
                                                updateConfig({
                                                    auth: auth,
                                                    alertConfig: updatedConfig,
                                                }),
                                            );
                                        }}
                                        isWindowForCreate={false}
                                        alertRuleIndex={index}
                                        onRemove={(rule) => {
                                            let updatedConfig = { ...alertConfig };
                                            updatedConfig.rules = updatedConfig.rules.filter(
                                                (value, idx) => {
                                                    return idx !== index;
                                                },
                                            );
                                            dispatch(
                                                updateConfig({
                                                    auth: auth,
                                                    alertConfig: updatedConfig,
                                                }),
                                            );
                                        }}
                                    ></Rule>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </>
            )}
        </Box>
    );
}
